import { Spinner } from "@blueprintjs/core"
import { ButtonSmall } from "@src/Components/Button"
import Card from "@src/Components/Card"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import Dialog from "@src/Components/Dialog"
import Icon from "@src/Components/Icon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { hasTrialPlanExpiredSelector } from "@src/Selectors/utils"
import { colors } from "@src/theme"
import * as T from "@src/types"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import { InviteUserForm } from "../InviteUserForm"
import { EditUserForm } from "./EditUserForm"
import {
  AddUserIcon,
  CardAdmin,
  CardContainer,
  CardsContainer,
  CardTextHead,
  CardTextSub,
  Container,
  FaIcon,
  HeadContainer,
  Title,
  UserIcon,
} from "./styles"
const ManageAdmins = () => {
  const { isDesktop } = usePortalSize()
  const history = useHistory()
  const userList = useSelector((store: tState) => store.portal.userList)
  const currentUser = useSelector((state: tState) => state.portal.cognitoId)
  const hasTrialExpired = useSelector((state: tState) =>
    hasTrialPlanExpiredSelector(state),
  )
  const useListFiltered = userList?.filter(
    (user) => user.cognitoId !== currentUser,
  )
  const [userToEdit, setUserToEdit] = React.useState<T.tUserProfile>()
  const [isModalInviteOpen, setIsModalInviteOpen] = React.useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = React.useState(false)
  const [isModalRemoveOpen, setIsModalRemoveOpen] = React.useState(false)
  const [modalRemoveLoading, setModalRemoveLoading] = React.useState(false)
  const getMenuItems = (userToEdit: T.tUserProfile) => {
    const cognitoId = userToEdit.cognitoId || ""
    return [
      {
        iconClass: "fas fa-edit",
        caption: "Edit",
        action: () => {
          setUserToEdit(userToEdit)
          setIsModalEditOpen(true)
        },
      },
      ...(cognitoId && currentUser !== cognitoId
        ? [
            {
              iconClass: "fas fa-trash-alt",
              color: colors.error,
              caption: "Remove",
              action: () => {
                setUserToEdit(userToEdit)
                setIsModalRemoveOpen(true)
              },
            },
          ]
        : []),
    ]
  }
  const getCardContent = React.useMemo(() => {
    let cardContent
    if (useListFiltered && useListFiltered.length > 0) {
      cardContent = (
        <div>
          {useListFiltered.map((user: T.tUserProfile, index: number) =>
            user.cognitoId && currentUser !== user.cognitoId ? (
              <Card
                content={
                  <CardContainer id="settings-user-card">
                    <UserIcon className="fas fa-user-circle" />
                    <div>
                      <CardTextHead>{`${user.firstName || ""} ${
                        user.lastName || ""
                      }`}</CardTextHead>
                      <CardTextSub>{user.email}</CardTextSub>
                      <CardTextSub>{user.jobTitle}</CardTextSub>
                    </div>
                  </CardContainer>
                }
                menuItems={getMenuItems(user)}
                key={index}
              />
            ) : (
              <CardAdmin key={index}>
                <CardContainer id="settings-user-card">
                  <UserIcon className="fas fa-user-circle" />
                  <div>
                    <CardTextHead>{`${user.firstName || ""} ${
                      user.lastName || ""
                    }`}</CardTextHead>
                    <CardTextSub>{user.email}</CardTextSub>
                    <CardTextSub>{user.jobTitle}</CardTextSub>
                  </div>
                </CardContainer>
                <Icon
                  data-tip
                  data-for="admin-card-tooltip"
                  onClick={() => {
                    setUserToEdit(user)
                    setIsModalEditOpen(true)
                  }}
                >
                  <i className="fas fa-edit" />
                </Icon>
                <ReactTooltip
                  className="tooltip-content-custom"
                  id="admin-card-tooltip"
                  aria-haspopup="true"
                  effect="solid"
                  place="top"
                  border={true}
                >
                  <p>Edit</p>
                </ReactTooltip>
              </CardAdmin>
            ),
          )}
          {useListFiltered.length < 4 ? (
            <ButtonSmall
              width={240}
              type="button"
              id="settings-add-user"
              onClick={() => setIsModalInviteOpen(true)}
              disabled={hasTrialExpired}
            >
              <span data-tip data-for="manage-admins-button-tooltip">
                <AddUserIcon className="fas fa-user-plus" />
                Invite Another Admin
              </span>
            </ButtonSmall>
          ) : null}
        </div>
      )
    } else {
      cardContent =
        userList === null ? (
          <Card content={<Spinner size={25} />} menuItems={[]} />
        ) : (
          <ButtonSmall
            width={240}
            type="button"
            id="fas fa-user-plus"
            onClick={() => setIsModalInviteOpen(true)}
            disabled={hasTrialExpired}
          >
            <span data-tip data-for="manage-admins-button-tooltip">
              <AddUserIcon className="fas fa-user-plus" />
              Invite Another Admin
            </span>
          </ButtonSmall>
        )
    }
    return (
      <>
        {cardContent}
        {hasTrialExpired ? (
          <Tooltip
            id="manage-admins-button-tooltip"
            clickable
            content={
              <Section fontSize="16px" padding="4">
                <Link
                  onClick={() => {
                    history.push("/plan")
                  }}
                  isUnderline
                >
                  Subscribe to use.
                </Link>
              </Section>
            }
          />
        ) : null}
      </>
    )
  }, [userList])
  const onRemoveUser = () => {
    if (!userToEdit?.cognitoId) {
      return
    }
    setModalRemoveLoading(true)
    C.Portal.removeUser(userToEdit.cognitoId, () => {
      setModalRemoveLoading(false)
      setIsModalRemoveOpen(false)
    })
  }
  const onCancelRemoveUser = () => {
    setIsModalRemoveOpen(false)
  }
  return (
    <div>
      <Title>
        {!isDesktop && <FaIcon className="fas fa-users" />}
        Manage Cyber Safety Admins
      </Title>
      <Container>
        {isDesktop && <FaIcon large className="fas fa-users" />}
        <CardsContainer>
          <HeadContainer>
            <span>
              Admins have full access to Cyber Safety.
              {!useListFiltered?.length && " You are currently the only admin."}
            </span>
          </HeadContainer>
          {getCardContent}
        </CardsContainer>
      </Container>
      <Dialog
        title="Invite Admin"
        isOpen={isModalInviteOpen}
        onClose={() => setIsModalInviteOpen(false)}
        content={<InviteUserForm onClose={() => setIsModalInviteOpen(false)} />}
      />
      <Dialog
        title="Edit Admin"
        isOpen={isModalEditOpen}
        onClose={() => setIsModalEditOpen(false)}
        content={
          <EditUserForm
            onClose={() => setIsModalEditOpen(false)}
            userData={userToEdit}
          />
        }
      />
      <Dialog
        title="Remove Admin?"
        text={`Are you sure you want to permanently remove ${
          userToEdit?.email || "admin"
        }?`}
        actionText="Remove"
        className="zeg-danger"
        isOpen={isModalRemoveOpen}
        onClose={() => onCancelRemoveUser()}
        onAction={() => onRemoveUser()}
        content={
          modalRemoveLoading ? (
            <Section padding="10">
              <Spinner size={50} />
            </Section>
          ) : undefined
        }
      />
    </div>
  )
}

export default ManageAdmins
