import {
  actions as MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import Header from "@src/Components/Header/index"
import C from "@src/Controller"
import GlobalModals from "@src/GlobalModals"
import { tFeatures, tState } from "@src/Model/Model"
import MenuContainer from "@src/Portal/MenuContainer"
import MessageContainer from "@src/Portal/MessageContainer"
import SubscriptionBanner from "@src/Portal/SubscriptionBanner"
import { VIEW_PATHS } from "@src/Routes/constants"
import {
  triggerCybersecurityRoadBlockModalWatcher,
  updateCustomerPortalData,
} from "@src/Sagas"
import { ViewTypes } from "@src/types"
import * as _ from "lodash"
import * as moment from "moment"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { PageContent, ZeguroSessionBanner } from "./styles"

interface IPortalContainer {
  children: React.ReactNode
  authenticated: boolean
  isMaintenance: boolean
}

const PortalContainer = ({
  children,
  authenticated,
  isMaintenance,
}: IPortalContainer) => {
  const dispatch = useDispatch()
  const {
    clientHeight,
    clientWidth,
    isDesktop,
    isMenuHidden,
    onBoarding,
    isFirstTimeLogin,
  } = usePortalSize()
  const contentRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const plans = useSelector((state: tState) => state.plans)

  const subscriptionId = useSelector((state: tState) => state.subscriptionId)

  const { companyProfile, isZeguroServiceSession } = useSelector(
    (state: tState) => state.portal,
  )

  const customerPortalData = useSelector(
    (store: tState) => store.customerPortalData.data,
  )
  const introViewed = customerPortalData?.introViewed

  const hasSeenReleaseNotes =
    customerPortalData?.cybermaturity?.hasSeenReleaseNotes

  const uiRules = plans.channelObject.uiRules || subscriptionId.uiRules
  const history = useHistory()
  const { pathname } = useLocation()
  const onSubscriptionPage = pathname.includes(VIEW_PATHS.subscription)
  const NAV_BAR_HEIGHT =
    !isDesktop && !onBoarding && authenticated && !isFirstTimeLogin ? 70 : 0
  const MENU_WIDTH = isMenuHidden || !isDesktop ? 0 : 250
  const hasTrialExpired =
    moment(Date.now())
      .startOf("day")
      .isAfter(Date.parse(plans.expirationDate), "day") &&
    plans.subscriptionStatus === "INTRIAL"

  const cyberSecurityMonth =
    moment(Date.now())
      .startOf("day")
      .isBefore(Date.parse("01 Nov 2020"), "day") &&
    plans.subscriptionStatus === "INTRIAL"

  const ZEGURO_SESSION_BANNER =
    isZeguroServiceSession && authenticated && isDesktop ? 48 : 0
  const TRIAL_BANNER_HEIGHT = !hasTrialExpired ? 0 : !isDesktop ? 80 : 50
  const CYBER_MONTH_BANNER = !cyberSecurityMonth ? 0 : !isDesktop ? 120 : 70
  let BANNER_HEIGHT = 0
  hasTrialExpired ? (BANNER_HEIGHT += TRIAL_BANNER_HEIGHT) : null
  cyberSecurityMonth ? (BANNER_HEIGHT += CYBER_MONTH_BANNER) : null

  const contentWidth = clientWidth - MENU_WIDTH
  const contentHeight =
    clientHeight -
    NAV_BAR_HEIGHT -
    (hasTrialExpired || cyberSecurityMonth ? BANNER_HEIGHT : 0)

  React.useEffect(() => {
    if (
      authenticated &&
      customerPortalData &&
      !hasTrialExpired &&
      plans.modules.cybermaturity === "ACTIVE"
    ) {
      if (
        !introViewed?.dashboard &&
        !hasSeenReleaseNotes &&
        !isZeguroServiceSession
      ) {
        dispatch(
          updateCustomerPortalData({
            cybermaturity: { hasSeenReleaseNotes: true },
          }),
        )
      } else if (!hasSeenReleaseNotes) {
        dispatch(
          MODALS.toggleGlobalModal(
            GLOBAL_MODALS_KEYS.cybersecurityReleaseNotes,
          ),
        )
      } else if (hasSeenReleaseNotes) {
        dispatch(triggerCybersecurityRoadBlockModalWatcher())
      }
    }
  }, [authenticated, hasSeenReleaseNotes])

  React.useEffect(() => {
    if (plans.status == "FAILED" && !_.isEmpty(companyProfile)) {
      C.toggleSubscribeModal(true)
    } else {
      C.toggleSubscribeModal(false)
    }
    const module = _.first(
      [
        "monitoring",
        "training",
        "compliance",
        "dashboard",
        "ratings",
        "cybermaturity",
        "cyberconcierge",
      ].filter((e) => {
        return _.startsWith(pathname, VIEW_PATHS[e])
      }),
    )
    if (
      module &&
      authenticated &&
      customerPortalData &&
      !introViewed?.[module as keyof tFeatures]
    ) {
      dispatch(GLOBAL_MODALS.changeIntroModal(module))
    }

    contentRef?.current?.scrollIntoView({
      block: "start",
    })
  }, [pathname, plans, authenticated, introViewed, customerPortalData])

  React.useEffect(() => {
    setMenuIsOpen(false)
  }, [pathname])

  return (
    <>
      {!onBoarding && !isFirstTimeLogin ? (
        <SubscriptionBanner
          OnSubscriptionPage={onSubscriptionPage}
          trialBannerheight={TRIAL_BANNER_HEIGHT}
          cyberSecurityMonthBannerHeight={CYBER_MONTH_BANNER}
          width={clientWidth}
          offsetLeft={0}
          hasTrialExpired={hasTrialExpired}
          cyberSecurityMonth={cyberSecurityMonth}
          goToSubscription={() => history.push(ViewTypes.plan)}
        />
      ) : null}
      <MenuContainer
        hidden={isMenuHidden}
        height={clientHeight}
        width={MENU_WIDTH}
        offset={(!onBoarding && TRIAL_BANNER_HEIGHT) || 0}
        isOpen={menuIsOpen}
        uiRules={uiRules}
        onClose={() => setMenuIsOpen(false)}
      />
      <MessageContainer />
      {!isMaintenance && (
        <Header
          height={NAV_BAR_HEIGHT}
          offsetTop={BANNER_HEIGHT}
          uiRules={uiRules}
          onBoarding={onBoarding}
          isFirstTimeLogin={isFirstTimeLogin}
          authenticated={authenticated}
          onOpenMenu={() => setMenuIsOpen(true)}
        />
      )}
      {isZeguroServiceSession && authenticated && isDesktop && (
        <ZeguroSessionBanner offset={MENU_WIDTH}>
          Attention! Zeguro service session is in progress!
        </ZeguroSessionBanner>
      )}
      {customerPortalData && !isFirstTimeLogin && <GlobalModals />}
      <PageContent
        ref={contentRef}
        height={contentHeight}
        width={contentWidth}
        paddingTop={NAV_BAR_HEIGHT + BANNER_HEIGHT || ZEGURO_SESSION_BANNER}
        marginLeft={MENU_WIDTH}
      >
        {children}
      </PageContent>
    </>
  )
}
export default PortalContainer
