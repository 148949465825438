import { ButtonSmall } from "@src/Components/Button"
import {
  Content,
  FormElement,
  Heading,
  LinkWrapper,
  Panel,
  PanelContainer,
} from "@src/Components/Common"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import { StyledSpinner } from "@src/Components/StyledSinner"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import MessageContainer from "@src/Portal/MessageContainer"
import { colors } from "@src/theme"
import { tStatus } from "@src/types"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"

export type tOnSubmit = (data: any) => void

interface IMagicLinkSignUp {
  email: string
  height: number
  recaptchaRef: React.RefObject<ReCAPTCHA>
  onMagicLinkSignUp: (password: string) => void
  redirectToLogin: () => void
  executeCaptcha: () => void
  verifyToken: (token: any, callback: any) => void
  resetCaptcha: () => void
  signupStatus: tStatus
  signupError: any
  isLoading?: boolean
}

const MagicLinkSignUp = ({
  email,
  height,
  recaptchaRef,
  onMagicLinkSignUp,
  redirectToLogin,
  executeCaptcha,
  verifyToken,
  resetCaptcha,
  signupStatus,
  signupError,
  isLoading,
}: IMagicLinkSignUp) => {
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [newPasswordValid, setNewPasswordValid] = React.useState(false)
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(false)

  const pContent = {
    height,
  }

  const NAV_BAR_HEIGHT = 76
  const COMPONENT_WIDTH = 472

  const clearState = () => {
    setPassword("")
    setConfirmPassword("")
  }

  const submitSignUp = () => {
    if (password.trim() !== confirmPassword.trim()) {
      C.toastNotification(
        "New password does not match the confirm password.",
        "danger",
      )
      resetCaptcha()
    } else if (password.trim().length > 0) {
      onMagicLinkSignUp(password.trim())
    } else {
      C.toastNotification(
        "Form Incomplete. Please fill empty fields.",
        "danger",
      )
      resetCaptcha()
    }
  }

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value)
    setConfirmPassword("")
    setConfirmPasswordValid(false)
  }

  const onConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
  }

  const onChange = (value: any) => {
    if (value !== null) {
      verifyToken(value, (response: any) =>
        response.success
          ? submitSignUp()
          : C.toastNotification("You smell like a robot.", "danger"),
      )
    } else {
      console.log("CAPTCHA ERROR")
    }
  }

  React.useEffect(() => {
    if (signupStatus === "FAILED" && signupError) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        signupError.hasOwnProperty("code") &&
        signupError.code === "UsernameExistsException"
      ) {
        C.toastNotification(
          <div>
            Looks like you already have an account.{" "}
            <span
              style={{
                color: "#fffff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={redirectToLogin}
            >
              Sign in now
            </span>
          </div>,
          "danger",
          20000,
        )
      } else if (["502", "503", "504"].includes(String(signupError.code))) {
        C.toastNotification(
          "Server Error. Please try again in some time.",
          "danger",
        )
      } else {
        if (signupError.message) {
          C.toastNotification(signupError.message, "danger")
        }
      }
      resetCaptcha()
      clearState()
    }
  }, [])

  return (
    <Content {...pContent}>
      <MessageContainer />
      <PanelContainer height={height - NAV_BAR_HEIGHT}>
        <Panel>
          <Section flex="center center column">
            <Heading margin="20px 0px">Welcome to Cyber Safety</Heading>
          </Section>
          <Section flex="center center column">
            <Section
              fontSize="21px"
              fontColor={colors.darkBlue}
              fontWeight="400"
              margin="0 0 10 0"
            >
              Create a password and get started.
            </Section>
            <FormElement>
              <Section fontSize={20} w={COMPONENT_WIDTH}>
                <FloatingLabelInput
                  id="signup-email"
                  label="Your work email address"
                  type="email"
                  value={email}
                  onChange={() => {}}
                  disabled
                />
              </Section>
              <Section fontSize={20} w={COMPONENT_WIDTH}>
                <PasswordInput
                  id="signup-password"
                  label="Create password"
                  value={password}
                  onChange={onPasswordChange}
                  isValid={(valid: boolean) => {
                    setNewPasswordValid(valid)
                  }}
                />
              </Section>
              <Section fontSize={20} w={COMPONENT_WIDTH}>
                <PasswordInput
                  id="signup-confirm-password"
                  label="Confirm password"
                  passwordToMatch={password}
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  isValid={(valid: boolean) => {
                    setConfirmPasswordValid(valid)
                  }}
                />
              </Section>
              <Section flex="center center column">
                <ReCAPTCHA
                  ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                  sitekey="6Lfi57UUAAAAACcMby1mAPrHTNZVGG_B2t_7KDDN"
                  size="invisible"
                  onChange={(value) => onChange(value)}
                />
              </Section>
              <Section w={COMPONENT_WIDTH} margin="30 0 0 0">
                <ButtonSmall
                  onClick={() => {
                    executeCaptcha()
                  }}
                  width={472}
                  id="CyberSafety_Trial_Lead_Button"
                  disabled={
                    !(
                      newPasswordValid &&
                      confirmPasswordValid &&
                      password &&
                      confirmPassword
                    ) || isLoading
                  }
                >
                  {isLoading ? (
                    <StyledSpinner size={24} color={colors.gray2} />
                  ) : (
                    "Set Password"
                  )}
                </ButtonSmall>
              </Section>
              <LinkWrapper fontWeight="400" column>
                <p>
                  By continuing, you agree to Cyber Safety&nbsp;
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open("https://www.zeguro.com/terms-of-service")
                    }}
                  >
                    Terms&nbsp;of&nbsp;Service
                  </Link>
                  &nbsp;and
                  <br />
                  <Link
                    isUnderline
                    onClick={() => {
                      window.open("https://www.zeguro.com/privacy-policy")
                    }}
                  >
                    Privacy&nbsp;Policy
                  </Link>
                  .
                </p>
                <p>
                  Need help? Contact&nbsp;
                  <Link href="mailto:support@zeguro.com">
                    support@zeguro.com
                  </Link>
                  .
                </p>
              </LinkWrapper>
            </FormElement>
          </Section>
        </Panel>
      </PanelContainer>
    </Content>
  )
}

export default MagicLinkSignUp
