import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { Link } from "@src/Components/Link"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { Section } from "@src/Portal/Training/Styles"
import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import Dialog from "../Components/Dialog"
import * as T from "../types"

interface IReduxProps {
  planName: string
  plans: T.tPlansState
  companyProfile: T.tCompanyProfile
}

interface IState {
  isTimeout: boolean
  expiresIn: number
  isUpgradeModal: boolean
  isSubscribeModal: boolean
  isSupportModal: boolean
}

class MessageContainer extends React.Component<IReduxProps, IState> {
  public state: IState = {
    isTimeout: false,
    expiresIn: 0,
    isUpgradeModal: false,
    isSubscribeModal: false,
    isSupportModal: false,
  }

  timeoutNotification(expires: number) {
    this.setState({ isTimeout: true, expiresIn: expires })
    const interval = setInterval(() => {
      if (this.state.expiresIn > 0) {
        this.setState({ expiresIn: this.state.expiresIn - 1 })
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }

  showUpgradeModal() {
    this.setState({ isUpgradeModal: true })
  }

  toggleSubscribeModal(value: boolean) {
    this.setState({ isSubscribeModal: value })
  }

  showSupportModal() {
    this.setState({ isSupportModal: true })
  }

  continueSession() {
    C.Portal.touchSession(() => {
      this.setState({ isTimeout: false })
    })
  }

  closeUpgradeModal = () => {
    this.setState({ isUpgradeModal: false })
  }

  closeSubscribeModal() {
    C.Portal.logout()
  }

  closeSupportModal = () => {
    this.setState({ isSupportModal: false })
  }

  render() {
    const continueSession = this.continueSession.bind(this)
    const logout = C.Portal.logout
    const expires = Math.floor(this.state.expiresIn)
    const min = Math.floor(expires / 60)
    const sec = expires % 60

    return (
      <React.Fragment>
        <Dialog
          title="Session Timeout"
          className="zeg-alert"
          isOpen={this.state.isTimeout}
          onClose={expires > 0 ? continueSession : logout}
          closeText="Sign Out"
          closeButtonId="session-timeout-logout"
          actionText="Continue Working"
          onAction={continueSession}
        >
          <TimeoutForm>
            <Section h={70} padding="20">
              Your session is about to expire due to inactivity
              {min > 0 && sec > 0 ? " in" : ""}
              {min > 0 ? ` ${min} minute${min > 1 ? "s" : ""}` : ""}
              {sec > 0
                ? ` ${min > 0 ? "and " : ""}${sec} second${sec > 1 ? "s" : ""}`
                : ""}
              .
            </Section>
            <Section css="text-align: right" padding="10 20 0 20">
              <ButtonSecondary onClick={logout} margin="0 10 0 0" width={100}>
                Logout
              </ButtonSecondary>
              <ButtonSmall
                width={180}
                onClick={continueSession}
                disabled={expires <= 0}
              >
                Continue Working
              </ButtonSmall>
            </Section>
          </TimeoutForm>
        </Dialog>
        <Dialog
          title="Subscribe or Upgrade"
          isOpen={this.state.isUpgradeModal}
          onClose={this.closeUpgradeModal}
          onAction={this.closeUpgradeModal}
          actionText="Got It"
          showOnlyPrimaryButton
          style={{ width: "unset" }}
          body={
            <div>
              {this.props.planName ? (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#212294",
                    fontFamily: "proxima-nova, sans-serif",
                  }}
                >
                  Your Current Plan: {this.props.planName} (Free Trial)
                </span>
              ) : null}
              <br />
              <br />
              To subscribe or upgrade, contact our customer support team at
              <br />
              <Link href="mailto:support@zeguro.com" isUnderline>
                support@zeguro.com
              </Link>
              &nbsp; or &nbsp;
              <Link href="tel:18559800660">(855) 980-0660</Link>
              .
              <br />
              <br />
              <img src="/images/pricing_grid.png" style={{ width: "800px" }} />
            </div>
          }
        />
        <Dialog
          title="Something Went Wrong"
          isOpen={this.state.isSubscribeModal}
          onAction={() => {
            C.Plans.getFeatureUsageProfile()
          }}
          onClose={this.closeSubscribeModal}
          actionText="Retry"
          closeText="Sign Out"
          closeButtonId="no-subscription-logout"
          className="zeg-danger"
          body={
            <div>
              We were not able to load your plan details. Try refreshing the
              page, or contact us at &nbsp;
              <Link href="mailto:support@zeguro.com" isUnderline>
                support@zeguro.com
              </Link>
              &nbsp;or
              <br />
              <Link href="tel:18009809212">(800) 980-9212</Link>.
            </div>
          }
        />
        <Dialog
          isOpen={this.state.isSupportModal}
          onClose={this.closeSupportModal}
          content={
            <React.Fragment>
              <div className="bp%-dialog-body">
                <ModalBody>
                  <ModalImg src="images/graphic_lifering.svg" />
                  <Heading>We’re here to help.</Heading>
                  <div>
                    Whenever you need them, Cyber Safety support team is
                    available to help you succeed. <br />
                    Email us at&nbsp;
                    <Link href="mailto:support@zeguro.com">
                      support@zeguro.com
                    </Link>
                    &nbsp;24 hours a day, 7 days a week, 365 days a year.
                  </div>
                </ModalBody>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
                className="bp5-dialog-footer"
              >
                <ButtonSmall width={100} onClick={this.closeSupportModal}>
                  Got it
                </ButtonSmall>
              </div>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }

  componentDidMount() {
    C.window.timeoutNotification = this.timeoutNotification.bind(this)
    C.window.showUpgradeModal = this.showUpgradeModal.bind(this)
    C.window.toggleSubscribeModal = this.toggleSubscribeModal.bind(this)
    C.window.showSupportModal = this.showSupportModal.bind(this)
  }
}

const TimeoutForm = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
`

const ModalImg = styled.img`
  height: 80px;
  margin: 15px;
`

const ModalBody = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const Heading = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #212294;
  margin-top: 20px;
  margin-bottom: 20px;
`

const mapStateToProps = (store: tState): IReduxProps => {
  return {
    planName: store.plans.subscribedPlan.planName,
    plans: store.plans,
    companyProfile: store.portal.companyProfile,
  }
}

export default connect(mapStateToProps)(MessageContainer)
