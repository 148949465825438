import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSecondary } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { Heading } from "@src/Components/Heading"
import { Section } from "@src/Components/StyledUtils"
import { Content, ContentArea, Panel } from "@src/Components/View"
import Waiting from "@src/Components/Waiting"
import { tState } from "@src/Model/Model"
import { TextStyled } from "@src/Portal/CyberConcierge/styles"
import { getCyberConciergeAlertWatcher } from "@src/Sagas"
import { colors } from "@src/theme"
import { ViewTypes } from "@src/types"
import { format, isSameDay } from "date-fns"
import { DeltaStatic } from "quill"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import { Badge } from "../AlertsList/styles"
import {
  AlertInfoTable,
  DocumentHeading,
  HeaderSection,
  Img,
  NoteSection,
  ReactQuillStyled,
  StyledBreadcrumbs,
} from "./styles"

const AlertDetails = () => {
  const history = useHistory()
  const match = useRouteMatch<{ conciergeMessageId: string }>()
  const dispatch = useDispatch()
  const { clientWidth } = usePortalSize()
  const conciergeMessageId = match.params.conciergeMessageId
  const widthBreakpoint = 1100
  const { data, isLoading } = useSelector(
    (store: tState) => store.cyberConcierge?.getCyberConciergeAlert,
  )
  const BREADCRUMBS = [
    {
      text: "Cyber Concierge",
      onClick: () => history.push(`${ViewTypes.cyberConcierge}/alerts`),
    },
    { text: "Security Alert Details" || "Partner" },
  ]

  const publishDate = data?.messageDate && new Date(data.messageDate)
  const updatedDate = data?.updatedOn && new Date(data.updatedOn)
  const formattedPublishDate = publishDate && format(publishDate, "MMM d, yyyy")
  const formattedUpdatedDate = updatedDate && format(updatedDate, "MMM d, yyyy")
  const messageSeverity = data?.messageSeverity ? data?.messageSeverity : "Info"
  const messageStatus = data?.messageStatus ? data?.messageStatus : "New"
  const downloadPdf = () => {
    if (!data) return
    const formattedDate = new Date(data.messageDate).toISOString().split("T")[0]
    const reportName = `ZeguroAlert_${formattedDate}_${conciergeMessageId}.pdf`
    window.open(
      `/api/cyberConcierge/cyberConciergeAlertPdf/${conciergeMessageId}/${reportName}`,
    )
  }

  React.useEffect(() => {
    dispatch(getCyberConciergeAlertWatcher(conciergeMessageId))
  }, [])

  if (isLoading) {
    return <Waiting text="Loading..." />
  }

  return (
    <ContentArea>
      <Panel>
        <Content>
          <HeaderSection flex="space-between center">
            <StyledBreadcrumbs items={BREADCRUMBS} />
            {clientWidth >= widthBreakpoint && (
              <Section flex="center center">
                <ButtonSecondary
                  width={150}
                  onClick={() =>
                    dispatch(
                      GLOBAL_MODALS.toggleGlobalModal(
                        GLOBAL_MODALS_KEYS.isContactCyberConcierge,
                      ),
                    )
                  }
                >
                  <Section flex="center center">
                    <FaIcon className="fas fa-hand-paper" margin="0 6px 0 0" />I
                    need help
                  </Section>
                </ButtonSecondary>
                <ButtonSecondary
                  margin="0 0 0 20"
                  width={180}
                  onClick={downloadPdf}
                >
                  <Section flex="center center">
                    <Img
                      src="/images/cyberconcierge/filePdf.svg"
                      alt="file pdf"
                      margin="0 6px 0 0"
                    />{" "}
                    Download PDF
                  </Section>
                </ButtonSecondary>
              </Section>
            )}
          </HeaderSection>
          {clientWidth < widthBreakpoint && (
            <NoteSection margin="24 0 0 0">
              <FaIcon
                className="far fa-exclamation-triangle"
                color={colors.red}
                margin="0 12px 0 0"
              />
              This document may contain sensitive information. Be careful who
              you share it with.
            </NoteSection>
          )}
          {clientWidth < widthBreakpoint && (
            <Section margin="20 0 0 0" flex="flex-start center">
              <ButtonSecondary
                width={150}
                onClick={() =>
                  dispatch(
                    GLOBAL_MODALS.toggleGlobalModal(
                      GLOBAL_MODALS_KEYS.isContactCyberConcierge,
                    ),
                  )
                }
              >
                <Section flex="center center">
                  <FaIcon className="fas fa-hand-paper" margin="0 6px 0 0" />I
                  need help
                </Section>
              </ButtonSecondary>
              <ButtonSecondary
                margin="0 0 0 20"
                width={180}
                onClick={downloadPdf}
              >
                <Section flex="center center">
                  <Img
                    src="/images/cyberconcierge/filePdf.svg"
                    alt="file pdf"
                    margin="0 6px 0 0"
                  />{" "}
                  Download PDF
                </Section>
              </ButtonSecondary>
            </Section>
          )}
          {clientWidth >= widthBreakpoint && (
            <NoteSection margin="24 0 0 0">
              <FaIcon
                className="far fa-exclamation-triangle"
                color={colors.red}
                margin="0 12px 0 0"
              />
              This document may contain sensitive information. Be careful who
              you share it with.
            </NoteSection>
          )}
          <DocumentHeading>
            <div>
              <Heading margin="20px 0 10px 0" color={colors.black50}>
                {data?.messageTitle}
              </Heading>
            </div>
            <div>
              <Badge color={messageSeverity} margin="0 0 0 10px">
                {messageSeverity}
              </Badge>
            </div>
          </DocumentHeading>

          {clientWidth >= 500 ? (
            <AlertInfoTable>
              <div>
                <TextStyled
                  fontWeight="700"
                  fontSize="18px"
                  margin="0 20px 0 0"
                >
                  Status: {messageStatus}
                </TextStyled>
              </div>
              <div>
                <TextStyled color={colors.black50} margin="0 0 5px 0">
                  <strong>Alert date:</strong> {formattedPublishDate}
                </TextStyled>
                {publishDate &&
                  updatedDate &&
                  !isSameDay(publishDate, updatedDate) && (
                    <Section>
                      <TextStyled color={colors.black50} margin="0 0 5px 0">
                        <strong>Updated date:</strong> {formattedUpdatedDate}
                      </TextStyled>
                    </Section>
                  )}
              </div>
            </AlertInfoTable>
          ) : (
            <>
              <TextStyled fontWeight="700" fontSize="18px" margin="0 20px 0 0">
                Status: {messageStatus}
              </TextStyled>
              <TextStyled color={colors.black50} margin="0 0 5px 0">
                <strong>Alert date:</strong> {formattedPublishDate}
              </TextStyled>
              {publishDate &&
                updatedDate &&
                !isSameDay(publishDate, updatedDate) && (
                  <TextStyled color={colors.black50} margin="0 0 5px 0">
                    <strong>Updated date:</strong> {formattedUpdatedDate}
                  </TextStyled>
                )}
            </>
          )}

          <Section
            css={`
              border-bottom: 1px solid ${colors.gray5};
            `}
            padding="0 0 20 0"
          >
            <TextStyled color={colors.black50} margin="0 0 15px 0">
              {data?.messageDescription}
            </TextStyled>
          </Section>
          <ReactQuillStyled
            readOnly
            theme="bubble"
            value={data?.messageText as DeltaStatic}
          />
        </Content>
      </Panel>
    </ContentArea>
  )
}
export default AlertDetails
