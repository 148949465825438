import {
  actions as MONITORING,
  MONITORING_MODALS_KEYS,
} from "@src/Actions/Reducers/MonitoringReducer"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import Pagination from "@src/Components/Pagination"
import { Section } from "@src/Components/StyledUtils"
import ThreeDotMenu from "@src/Components/ThreeDotMenu"
import { tState } from "@src/Model/Model"
import { ScanReportCard } from "@src/Portal/Monitoring/ScanTables/ScanReportCard"
import { cancelScanWatcher } from "@src/Sagas"
import { colors } from "@src/theme"
import * as moment from "moment"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import * as T from "../../../types"
import { BoxBody } from "./styles"
import {
  Box,
  ContactUs,
  PageBreak,
  PageHolder,
  ScheduleRow,
  ScheduleRowElement,
  SectionHeading,
  TargetDetail,
  TargetDetailsWrapper,
} from "./styles"
interface IScanTables {
  target: T.IProbelyTarget
  scanMap: { [key: string]: T.IProbelyScan[] }
  scheduleMap: { [key: string]: T.IProbelySchedule[] }
  getScanReport: (
    targetName: string,
    targetIds: string,
    scanId: string,
    reportType: string,
  ) => void
  scansLoaded: boolean
}

const ScanTables = (props: IScanTables) => {
  const { clientWidth, isDesktop } = usePortalSize()
  const dispatch = useDispatch()
  const isCancelScanConfirmModal = useSelector(
    (state: tState) => state.monitoring.toggleModal.isWebScanCancelConfirm,
  )
  const isCancelScanLoading = useSelector(
    (state: tState) => state.monitoring.cancelScan.isLoading,
  )
  const [allScans, setAllScans] = React.useState<T.IProbelyScan[]>([])
  const [scanStarted, setScanStarted] = React.useState<boolean>(false)
  const [scanFailed, setScanFailed] = React.useState<Date | null>(null)
  const [pageOfItems, setPageOfItems] = React.useState<T.IProbelyScan[]>([])
  const [pageNumber, setPageNumber] = React.useState<number>(1)

  const scanLevelMap: { [key: string]: string } = {
    safe: "Safe",
    lightning: "Lightning",
    normal: "Normal",
    full: "Full",
    log4shell: "Log4j Only",
    sandbox_lightning: "Sandbox Lightning",
    sandbox_normal: "Sandbox Normal",
  }

  const schedule =
    props.scheduleMap &&
    props.target &&
    props.target.targetId &&
    props.scheduleMap[props.target.targetId] &&
    props.scheduleMap[props.target.targetId][0]

  const nextScan =
    schedule && moment(schedule.dateTime).format(`MMM D, YYYY h:mm A`)

  const getRecurrenceString = (recurrence: string) => {
    const recurrenceMap: { [key: string]: string } = {
      d: "Daily",
      w: "Weekly",
      q: "Quarterly",
      m: "Monthly",
    }
    return recurrenceMap[recurrence]
  }

  const toggleModal = (modal: string) => dispatch(MONITORING.toggleModal(modal))
  const cancelScan = () => {
    const targetId = props.target.targetId
    const targetScans = props.scanMap[props.target.targetId]
    const scanInProgress = targetScans[targetScans.length - 1]
    const payload = {
      zeguroTargetId: targetId,
      zeguroScanId: scanInProgress.scanId,
      nextScan,
    }
    dispatch(cancelScanWatcher(payload))
  }

  const getScanInitiatedCard = () => {
    return (
      <div>
        <Box hasBorder id="scan-initiated-box">
          <img src="images/icon_waiting_report.svg" alt="waiting report" />
          &nbsp; Scan initiated, check back in a few minutes &nbsp;
          <FaIcon
            className="fas fa-spinner-third fa-spin"
            color={colors.splashingWave}
          />
        </Box>
        <PageBreak />
      </div>
    )
  }

  const loadScans = (props: IScanTables) => {
    const completedScans: T.IProbelyScan[] = []
    const allScans: T.IProbelyScan[] = []
    const failedScans: T.IProbelyScan[] = []
    let scanStarted: boolean = false
    let scanFailed: Date | null = null
    props &&
      props.target &&
      props.target.targetId &&
      props.scanMap &&
      props.scanMap[props.target.targetId] &&
      props.scanMap[props.target.targetId].map((scan: T.IProbelyScan) => {
        allScans.push(scan)
        if (scan.status === "started") {
          scanStarted = true
        }
        if (scan.status === "completed") {
          completedScans.push(scan)
        }
        if (scan.status === "failed") {
          failedScans.push(scan)
        }
      })
    completedScans.sort((scanA: T.IProbelyScan, scanB: T.IProbelyScan) => {
      return +new Date(scanB.completeTime) - +new Date(scanA.completeTime)
    })
    failedScans.sort((scanA: T.IProbelyScan, scanB: T.IProbelyScan) => {
      return +new Date(scanB.completeTime) - +new Date(scanA.completeTime)
    })
    if (
      failedScans.length > 0 &&
      ((completedScans.length > 0 &&
        new Date(completedScans[0].completeTime) <
          new Date(failedScans[0].completeTime)) ||
        completedScans.length <= 0)
    ) {
      scanFailed = new Date(failedScans[0].completeTime)
    }
    setScanStarted(scanStarted)
    setScanFailed(scanFailed)
    setAllScans(allScans)
  }

  const getMenuItems = () => {
    if (scanStarted) {
      return [
        {
          id: "stop-web-scan",
          iconClass: "fas fa-hand-paper",
          color: colors.error,
          caption: "Cancel currently running scan",
          action: () =>
            toggleModal(MONITORING_MODALS_KEYS.isWebScanCancelConfirm),
        },
      ]
    } else {
      return [
        {
          id: "edit-web-app-name",
          iconClass: "fas fa-pencil-alt",
          caption: "Edit Website Name",
          action: () => toggleModal(MONITORING_MODALS_KEYS.isEditAppModal),
        },
        {
          id: "edit-scan-schedule",
          iconClass: "fas fa-clock",
          caption: schedule ? "Edit Scan Schedule" : "Schedule Scans",
          action: () => toggleModal(MONITORING_MODALS_KEYS.isEditScheduleModal),
        },
        {
          id: "remove-web-app",
          iconClass: "fas fa-trash-alt",
          caption: "Remove Website",
          color: colors.error,
          action: () => toggleModal(MONITORING_MODALS_KEYS.isDeleteAppConfirm),
        },
      ]
    }
  }

  const onChangePage = (pageOfItems: T.IProbelyScan[], pageNumber: number) => {
    setPageOfItems(pageOfItems)
    setPageNumber(pageNumber)
  }

  React.useEffect(() => {
    loadScans(props)
  }, [props])

  return (
    <PageHolder align="left">
      <PageBreak />
      <ScheduleRow>
        <TargetDetail>
          <h3>
            <FaIcon
              className="fas fa-spider-web"
              color={colors.darkBlue}
              margin="0 13px 0 0"
            />
            {props.target?.name}
          </h3>
          <span>{props.target?.url}</span>
        </TargetDetail>
        <ThreeDotMenu
          menuItems={getMenuItems()}
          buttonContent={
            isDesktop ? (
              <ButtonSecondary id="scan-action" width={100}>
                <FaIcon className="far fa-cog" />
                &nbsp;Actions
              </ButtonSecondary>
            ) : (
              <FaIcon
                className="fas fa-cog"
                fontSize="24px"
                cursor="pointer"
                color={colors.darkBlue}
              />
            )
          }
        />
      </ScheduleRow>
      <PageBreak />
      <Box
        backgroundColor="rgba(0, 98, 217, 0.07)"
        hasBorder
        noTopThickBorder
        color={colors.darkBlue}
        padding={clientWidth <= 414 ? "16px" : "27px 24px"}
      >
        <BoxBody>
          {schedule ? (
            <div>
              <Section
                flex="start center row"
                fontWeight="bold"
                padding="0 0 15 0"
                fontSize="18px"
                fontFamily="montserrat"
              >
                <FaIcon
                  className="fas fa-clock"
                  color={colors.darkBlue}
                  fontSize="24px"
                  padding="0 9px 0 0"
                />
                SCAN SCHEDULE
              </Section>
              <TargetDetailsWrapper>
                <ScheduleRowElement>
                  <b>Next Scan:</b>
                  &nbsp;
                  <Link onClick={() => toggleModal("isEditScheduleModal")}>
                    {nextScan}
                  </Link>
                </ScheduleRowElement>
                <ScheduleRowElement>
                  <b>Recurrence:</b> &nbsp;
                  <Link onClick={() => toggleModal("isEditScheduleModal")}>
                    {getRecurrenceString(schedule.recurrence)}
                  </Link>
                </ScheduleRowElement>
                <ScheduleRowElement>
                  <b>Scan Level:</b> &nbsp;
                  <Link onClick={() => toggleModal("isEditScheduleModal")}>
                    {scanLevelMap[props.target.scan_profile]}
                  </Link>
                </ScheduleRowElement>
              </TargetDetailsWrapper>
            </div>
          ) : (
            <div>
              <Section
                flex="start center row"
                fontWeight="bold"
                padding="0 0 15 0"
                fontSize="18px"
              >
                <FaIcon
                  className="fas fa-clock"
                  color={colors.darkBlue}
                  fontSize="45px"
                />
                <Section padding="0 0 0 15">
                  <Section>RECURRING SCANS</Section>
                  <Section fontWeight="600" fontSize="18px" fontColor="#26249E">
                    Schedule recurring scans for ongoing protection and savings.
                  </Section>
                </Section>
              </Section>
              <Section flex="start center row">
                <ButtonSmall
                  width={150}
                  onClick={() => toggleModal("isEditScheduleModal")}
                >
                  Schedule Scans
                </ButtonSmall>
                <ExternalLink
                  id={"learn-more-scan-schedule"}
                  url={
                    "https://zeguro.zendesk.com/hc/en-us/articles/360033542954-Monitoring-Web-App-Scanning-Overview"
                  }
                  text="More"
                />
              </Section>
            </div>
          )}
        </BoxBody>
      </Box>
      <PageBreak />

      <SectionHeading color={colors.violet} padding="10px 26px 15px 0">
        <FaIcon
          className="fas fa-clipboard-check"
          color={colors.violet}
          fontSize="24px"
          padding="0 15px 0 0"
        />
        SCAN REPORTS
      </SectionHeading>
      {scanStarted && allScans.length ? getScanInitiatedCard() : null}
      {allScans.length ? (
        <BoxBody>
          {pageOfItems.map((scan) =>
            scan.completeTime ? (
              <React.Fragment key={scan.scanId}>
                <ScanReportCard
                  clientWidth={clientWidth}
                  scan={scan}
                  targetName={props.target?.name}
                  getScanReport={props.getScanReport}
                />
              </React.Fragment>
            ) : null,
          )}
          <Pagination
            items={allScans}
            onChangePage={onChangePage}
            initialPage={pageNumber}
            pageSize={5}
          />
          <Section flexGrow="1" />
        </BoxBody>
      ) : scanFailed && !scanStarted ? null : (
        <p>No scan reports yet.</p>
      )}
      <Section flexGrow="1" />
      <ContactUs>
        Having trouble? Contact us at{" "}
        <Link href="mailto:support@zeguro.com" isUnderline>
          support@zeguro.com
        </Link>
        .
      </ContactUs>

      <ConfirmationModal
        isOpen={isCancelScanConfirmModal}
        title="Cancel Scan?"
        type="danger"
        isLoading={isCancelScanLoading}
        confirmButtonWidth={135}
        confirmButton="Cancel Scan"
        onClose={() =>
          toggleModal(MONITORING_MODALS_KEYS.isWebScanCancelConfirm)
        }
        onConfirm={cancelScan}
      >
        <span>
          Are you sure you want to cancel the scan currently running on{" "}
          <strong>{props.target?.url}</strong>
        </span>
      </ConfirmationModal>
    </PageHolder>
  )
}

export default React.memo(ScanTables)
