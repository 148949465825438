import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import Dialog from "@src/Components/Dialog"
import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import Shield from "@src/Components/Shield"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { Content, ContentArea, Panel } from "@src/Components/View"
import { ISecurityPolicy } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as T from "@src/types"
import { effectiveDateUsa } from "@src/Utils"
import * as _ from "lodash"
import * as React from "react"
import * as ReactQuill from "react-quill"
import { useHistory } from "react-router-dom"

import CustomDialog from "../CustomDialog"
import {
  ActionLinkContent,
  ActionLinkText,
  ApprovalDate,
  ButtonRow,
  ButtonsPanel,
  Info,
  LinkPanel,
  NonCompleted,
  PolicyHeader,
  PolicyPane,
  PolicyText,
  PopoverLink,
  PopUpContent,
  StatusBadge,
  TitleInput,
  TitleText,
  UserDirective,
  Version,
} from "./styles"

export interface IPolicy {
  policy: ISecurityPolicy
  hasDraft: boolean
  storageList: string[]
  policyText: string
  customDialog: { id: string; offset: number } | null
  numParamsEmpty: number
  initialCustomValues: any
  historyOpen: boolean
  outOfBoxPolicyIds: { [key: string]: string }
  isLastActiveVersion: boolean
  onBackToList: () => void
  onDelete: (policy: ISecurityPolicy) => void
  onApprove: (policy: any) => void
  onSaveDraft: (policy: any) => void
  onCustomParam: (
    policy: ISecurityPolicy,
    params: any,
    condition: string,
  ) => void
  onCancelCustomForm: () => void
  onDownload: (policy: ISecurityPolicy) => void
}

const Policy = ({
  policy,
  hasDraft,
  storageList,
  policyText,
  customDialog,
  numParamsEmpty,
  initialCustomValues,
  historyOpen,
  outOfBoxPolicyIds,
  isLastActiveVersion,
  onBackToList,
  onDelete,
  onApprove,
  onSaveDraft,
  onCustomParam,
  onCancelCustomForm,
  onDownload,
}: IPolicy) => {
  const history = useHistory()
  const { isDesktop } = usePortalSize()
  const [policyName, setPolicyName] = React.useState<string>("")
  const [customPolicyText, setCustomPolicyText] = React.useState<string | null>(
    null,
  )
  const [initialCustomPolicyText, setInitialCustomPolicyText] = React.useState<
    string | null
  >(null)
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  const [editMode, setEditMode] = React.useState(false)

  React.useEffect(() => {
    setPolicyName(policy.name)
    if (
      policy &&
      policy.status === "Draft" &&
      policy.custom &&
      policy.saved === false
    ) {
      setEditMode(true)
      setCustomPolicyText("")
    }
  }, [policy])

  const [quillRef, setQuillRef] = React.useState<any>(null)

  const page = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const reactQuillRef = React.useRef() as React.RefObject<ReactQuill>

  const modules = {
    toolbar: [
      //[{ font: [] }],
      //[{ size: ["small", false, "large", "huge"] }], // custom dropdown
      ["bold", "italic", "underline", "strike"], // toggled buttons
      //["blockquote", "code-block"],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      //[{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"], // remove formatting button
    ],
  }

  const formats = [
    //"font",
    //"size",
    "bold",
    "italic",
    "underline",
    "strike",
    //"blockquote",
    //"code-block",
    "header",
    //"script",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "font",
    "indent",
    "direction",
  ]

  const attachQuillRefs = () => {
    if (!reactQuillRef) {
      return
    }
    if (typeof reactQuillRef?.current?.getEditor !== "function") {
      return
    }
    setQuillRef(reactQuillRef.current.getEditor())
  }

  React.useEffect(() => {
    attachQuillRefs()
  }, [])

  React.useEffect(() => {
    if (
      !customPolicyText &&
      policy.custom &&
      typeof policy.customPolicy === "string"
    ) {
      setCustomPolicyText(policy.customPolicy)
    }
    if (initialCustomPolicyText === null) {
      setInitialCustomPolicyText(customPolicyText)
    }
  }, [customPolicyText, policy])

  const info1 = (
    <p>
      Replace an out-of-box policy template with your own policy text.&nbsp;
      <ExternalLink
        text="More"
        url="https://help.zeguro.com/hc/en-us/articles/360039397974"
        margin="0px 0px"
      />
    </p>
  )
  const policyStatus = editMode ? "Draft" : policy.status
  const isCustomPolicy = policy.custom
  const isCustomDialog = customDialog
  const status = policy.status
  const version = policy.version
  const approvalDate = policy.approvalDate

  const customDialogOffset = 0
  const customId = customDialog ? customDialog.id : ""

  const isHistory = historyOpen
  const isDraft = policy.status === "Draft"
  const isOutOfBox = outOfBoxPolicyIds[policy.id]

  const draftChanged = () => {
    if (initialCustomPolicyText === null) {
      return false
    }

    return !_.isEqual(customPolicyText, initialCustomPolicyText)
  }

  const outOfBoxPolicyChanged = !_.isEqual(
    initialCustomValues,
    policy.customValues,
  )

  const saveDraftDisabled =
    !quillRef?.getText().trim().length ||
    !policyName ||
    !(policyName !== policy.name || draftChanged())

  const draftIsEmpty = editMode
    ? !(quillRef && quillRef.getText().trim().length > 0)
    : customPolicyText
    ? customPolicyText.length === 0
    : true

  const approvalDisabled =
    (!isCustomPolicy &&
      (numParamsEmpty > 0 ||
        (policy.status === "Active" &&
          (!outOfBoxPolicyChanged || policyName !== policy.name)))) ||
    (isCustomPolicy &&
      ((policy.status !== "Draft" && saveDraftDisabled) ||
        draftIsEmpty ||
        !policyName))

  const NotInitializedWarning = () =>
    numParamsEmpty > 0 && !isCustomPolicy ? (
      <NonCompleted>{`${numParamsEmpty} required field${
        numParamsEmpty.toString().slice(-1) !== "1" ? "s" : ""
      } below`}</NonCompleted>
    ) : null

  const scrollTop = () => {
    if (page.current?.parentElement) {
      page.current.parentElement.scrollTop = 0
    }
  }

  const statusText = (status: string) => {
    if (status === "Active") {
      if (isLastActiveVersion) return "In Effect"
      return "Superseded"
    }
    return status
  }

  const openDiscardDialog = (show: boolean) => {
    setDiscardDialogOpen(show)
  }

  const onBackToListClick = () => {
    onBackToList()
    scrollTop()
  }

  const onCancelDiscard = () => {
    setDiscardDialogOpen(false)
  }

  const onApproveClick = () => {
    onApprove({
      ...policy,
      name: policyName,
      customPolicy: customPolicyText,
    })
    scrollTop()
  }

  const onSaveDraftClick = () => {
    const customPolicy = customPolicyText || ""
    onSaveDraft({
      ...policy,
      name: policyName,
      customPolicy,
    })
    setEditMode(false)
    setCustomPolicyText("")
  }

  let cd = null
  let sh = null

  if (customDialog) {
    cd = (
      <CustomDialog
        policy={policy}
        customId={customId}
        offset={customDialogOffset}
        hidden={!isCustomDialog}
        storageList={storageList}
        onCustomParam={onCustomParam.bind(policy)}
        onCancelCustomForm={onCancelCustomForm}
      />
    )
  }

  if (customDialog) {
    sh = <Shield />
  }

  const statusColor = () => {
    switch (policyStatus) {
      case "Active":
        return "#1c7f30"
      case "Draft":
        return "#5e0081"
      default:
        return "#858585"
    }
  }

  const versionStr = editMode
    ? status === "Active"
      ? version + 1
      : version
    : version

  const onDiscardConfirm = () => {
    if (policy.custom) {
      discardCustomPolicy()
    }
    setEditMode(false)
    openDiscardDialog(false)
  }

  const discardCustomPolicy = () => {
    if (policy.saved) {
      setEditMode(false)
      setCustomPolicyText(initialCustomPolicyText)
    } else {
      if (isOutOfBox) {
        history.push(
          `${T.ViewTypes.compliance}/${policy.id}/${policy.version - 1}`,
        )
      } else {
        history.push(T.ViewTypes.compliance)
      }
    }
  }

  const onDiscard = () => {
    if (draftChanged()) {
      openDiscardDialog(true)
    } else {
      discardCustomPolicy()
    }
  }

  const onDeleteConfirm = () => {
    onDelete(policy)
  }

  const onCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const ActionLink = (p: any) => {
    if (p.hidden) {
      return null
    }
    return (
      <ActionLinkContent>
        <ActionLinkText onClick={p.onClick} textColor={p.textColor}>
          <FaIcon padding="0 4px 0 0" className={p.iconClass} /> {p.text}
        </ActionLinkText>
        <Tooltip
          clickable
          place="top"
          id={`policy-action-tooltip-${p.text}`}
          content={<PopUpContent>{p.info}</PopUpContent>}
        />
        <PopoverLink data-tip data-for={`policy-action-tooltip-${p.text}`}>
          {p.info && (
            <FaIcon
              className="fas fa-info-circle"
              color={colors.gray40}
              padding="0 0 0 4px"
              fontSize="13px"
            />
          )}
        </PopoverLink>
      </ActionLinkContent>
    )
  }

  const onDeleteClick = () => {
    setDeleteDialogOpen(true)
  }

  const ActiveWithDraft = hasDraft && isLastActiveVersion
  const isInactive = policy.status === "Inactive"
  const isApprovable =
    (isLastActiveVersion && !hasDraft) ||
    (!isLastActiveVersion && isDraft) ||
    isInactive

  return (
    <>
      <Dialog
        title="Discard Changes?"
        isOpen={discardDialogOpen}
        onAction={onDiscardConfirm}
        onClose={onCancelDiscard}
        actionText="Discard"
        closeText="Cancel"
        closeButtonId="no-subscription-logout"
        className="zeg-danger"
        body={
          <div>
            Are you sure you want to discard your changes to this security
            policy?
          </div>
        }
      />

      <Dialog
        title="Delete Draft?"
        isOpen={deleteDialogOpen}
        onAction={onDeleteConfirm}
        onClose={onCancelDelete}
        actionText="Delete"
        closeText="Cancel"
        closeButtonId="no-subscription-logout"
        className="zeg-danger"
        body={<div>Are you sure you want to delete draft?</div>}
      />

      <ContentArea ref={page} id="policyText">
        <Panel>
          <Content padding="24px 60px">
            <PolicyPane>
              <div>
                <Section padding="8 0">
                  <Link
                    fontSize={isDesktop ? "24px" : undefined}
                    onClick={onBackToListClick}
                  >
                    Security Policies
                  </Link>
                  &nbsp;
                  <FaIcon
                    className="fas fa-chevron-right"
                    color={colors.gray40}
                    fontSize={isDesktop ? "18px" : "13px"}
                    lineHeight="1.5"
                  />
                  &nbsp;
                  {!isOutOfBox && editMode ? (
                    <TitleInput
                      value={policyName}
                      onChange={(e) => setPolicyName(e.target.value)}
                    />
                  ) : (
                    <TitleText>{policy.name}</TitleText>
                  )}
                </Section>
                <Section flex="space-between" flexWrap="wrap" gap="8px">
                  <Section>
                    <Version hidden={isInactive}>Version {versionStr}</Version>
                    <StatusBadge hidden={!isCustomPolicy} color="#0062d8">
                      Custom
                    </StatusBadge>
                    <StatusBadge hidden={isHistory} color={statusColor()}>
                      {statusText(policyStatus)}
                    </StatusBadge>
                  </Section>
                  {policyStatus === "Active" && !editMode && approvalDate && (
                    <ApprovalDate>
                      Approved&nbsp;
                      {effectiveDateUsa(approvalDate).format("MMM D, YYYY")}
                    </ApprovalDate>
                  )}
                </Section>
              </div>

              <PolicyHeader>
                <UserDirective hidden={!isApprovable || policy.custom}>
                  {!!numParamsEmpty && (
                    <Info>
                      <FaIcon
                        color={colors.navyBlue}
                        className="fas fa-info-circle"
                        padding="0 6px 0 0"
                      />
                      Customize <NotInitializedWarning />, then click Approve.
                    </Info>
                  )}
                </UserDirective>

                <ButtonRow>
                  <ButtonsPanel>
                    <ButtonSecondary
                      hidden={!editMode}
                      height={32}
                      width={isDesktop ? 90 : 200}
                      onClick={onDiscard}
                    >
                      Cancel
                    </ButtonSecondary>
                    <ButtonSecondary
                      id="save-draft"
                      disabled={saveDraftDisabled}
                      hidden={!editMode}
                      data-policy-name={`${policy.name}`}
                      width={isDesktop ? 140 : 200}
                      display="inline-block"
                      onClick={onSaveDraftClick}
                    >
                      Save Draft
                    </ButtonSecondary>
                    <ButtonSmall
                      hidden={!isApprovable || isHistory}
                      id="approve-policy"
                      data-policy-name={`${policy.name}`}
                      width={isDesktop ? 140 : 200}
                      display="inline-block"
                      onClick={onApproveClick}
                      disabled={approvalDisabled}
                    >
                      Approve
                    </ButtonSmall>
                  </ButtonsPanel>
                  {!editMode && (
                    <LinkPanel
                      paddingTop={
                        ActiveWithDraft && (!isCustomPolicy || !isHistory)
                          ? "20px"
                          : "0px"
                      }
                    >
                      <ActionLink
                        iconClass="fas fa-file-pdf"
                        text="Download"
                        hidden={editMode || isInactive}
                        onClick={() => {
                          onDownload(policy)
                        }}
                      />
                      <ActionLink
                        iconClass="fas fa-file-plus"
                        text="Use your own policy"
                        info={info1}
                        hidden={
                          (!isLastActiveVersion && !isInactive) ||
                          hasDraft ||
                          isCustomPolicy ||
                          isHistory
                        }
                        onClick={() => {
                          history.push(
                            `${T.ViewTypes.compliance}/${policy.id}/${
                              policy.version + 1
                            }`,
                          )
                        }}
                      />

                      <ActionLink
                        hidden={!ActiveWithDraft}
                        iconClass="far fa-edit"
                        text={`Open pending draft (Version ${
                          policy.version + 1
                        })`}
                        onClick={() => {
                          history.push(
                            `${T.ViewTypes.compliance}/${policy.id}/${
                              policy.version + 1
                            }`,
                          )
                        }}
                      />

                      <ActionLink
                        hidden={
                          !isApprovable ||
                          !isCustomPolicy ||
                          editMode ||
                          isHistory
                        }
                        iconClass="far fa-edit"
                        text="Edit"
                        onClick={() => {
                          setEditMode(true)
                        }}
                      />

                      <ActionLink
                        hidden={!(isDraft && !editMode)}
                        iconClass="fas fa-trash-alt"
                        text="Delete"
                        textColor={colors.error}
                        onClick={onDeleteClick}
                      />
                    </LinkPanel>
                  )}
                </ButtonRow>
              </PolicyHeader>

              {cd}
              {sh}
              {!editMode ? (
                <PolicyText
                  className={
                    policy.customPolicy ? "ql-editor" : "zeguro-policy"
                  }
                  hidden={false}
                  dangerouslySetInnerHTML={{
                    __html:
                      policy.custom && typeof policy.customPolicy === "string"
                        ? policy.customPolicy
                        : policyText,
                  }}
                />
              ) : null}
              <div style={{ display: editMode ? "block" : "none" }}>
                <ReactQuill
                  ref={reactQuillRef}
                  placeholder="Paste your policy text here, or start writing."
                  value={customPolicyText || ""}
                  style={{ height: 370, marginBottom: 10 }}
                  formats={formats}
                  modules={modules}
                  onChange={(value: string) => {
                    setCustomPolicyText(value)
                  }}
                />
              </div>
            </PolicyPane>
          </Content>
        </Panel>
      </ContentArea>
    </>
  )
}

export default Policy
