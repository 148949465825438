import { Popover, Position } from "@blueprintjs/core"
import ExternalLink from "@src/Components/ExternalLink"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { TimePicker } from "@src/Components/TimePicker"
import Tooltip from "@src/Components/Tooltip"
import { colors } from "@src/theme"
import { FormikProps } from "formik"
import * as moment from "moment"
import * as React from "react"

import { IAddWebAppFormFields } from "../../.."

interface ISetTimeTooltip {
  formik: FormikProps<Partial<IAddWebAppFormFields>>
  setTime: (time: Date) => void
}

const SetTimeTooltip = ({ formik, setTime }: ISetTimeTooltip) => (
  <>
    <Popover
      content={
        <Section flex="center" padding="22 28">
          <TimePicker onChange={setTime} value={formik.values.scheduleTime} />
        </Section>
      }
      position={Position.TOP}
      usePortal={false}
    >
      <Section
        data-tip
        data-for="set-time-tooltip"
        fontColor={colors.link}
        margin="9 0"
      >
        <Link>{moment(formik.values.scheduleTime).format("h:mm A")}</Link>
      </Section>
    </Popover>
    &nbsp;
    <Section lineHeight="36px" fontColor={colors.gray40}>
      PT&nbsp;
      <i
        data-tip
        data-for="local-time-tooltip"
        className="fas fa-question-circle"
      />
      <Tooltip
        clickable
        id="local-time-tooltip"
        content={
          <p>
            Local time. Slight discrepancies may occur.
            <ExternalLink
              text="Learn more"
              url={"https://help.zeguro.com/hc/en-us/articles/360024578174"}
            />
          </p>
        }
      />
    </Section>
  </>
)

export default SetTimeTooltip
