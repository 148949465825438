import * as moment from "moment-timezone"

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const effectiveDateUsa = (d: string | number | Date) => {
  return moment(d).tz("America/New_York")
}

export const getDateIso = (d: string | number | Date) => {
  return moment(d).format("YYYY-MM-DD")
}

export const getDateStr = (d: Date) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }
  return d.toLocaleDateString("en-US", dateOptions)
}

export const getQuoteFormUrl = (environmentName?: string) => {
  if (
    environmentName &&
    ["dev", "demo", "staging", "test", "test2", "qa", "preflight"].includes(
      environmentName,
    )
  ) {
    return `https://zeguro-stage.webflow.io/quote?env=${environmentName}`
  } else {
    return `https://www.zeguro.com/quote`
  }
}

export const getPrivateAsset = (privateUrl: string) => {
  try {
    const parts = privateUrl.split("/")
    const filename = parts[parts.length - 1]
    const url = `/api/resources/asset?fileName=${filename}`
    window.open(url, "_blank")
  } catch (e) {
    console.error(e)
  }
}

export const calculatePercentage = (
  currentValues: number,
  allValues: number,
) => {
  if (currentValues === 0 || allValues === 0) return 0

  return Math.round((currentValues / allValues) * 100)
}
