import history from "@src/browserHistory"
import { supportMassage } from "@src/Components/Common/Toast"
import C from "@src/Controller"
import * as Cookies from "js-cookie"
import * as _ from "lodash"
import { Store } from "redux"

import * as PLANS from "../Actions/PlansActions"
import * as PORTAL from "../Actions/PortalActions"
import * as T from "../types"
import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Portal {
  private store: Store
  public window: T.tExtWindow & Window = window

  constructor(store: Store) {
    this.store = store
  }

  getUserList(callback?: any) {
    Middletier.fetchJson(`/profile/getUserList`, (success, data) => {
      if (success) {
        this.store.dispatch(PORTAL.setUserList(data.data))
        callback && callback({ success: true })
      } else {
        callback &&
          callback({ success: false, error: "Cannot fetch user list" })
        serverLog({
          level: "error",
          message: "Cannot fetch user list",
          filename: __filename,
        })
      }
    })
  }

  removeUser(cognitoId: string, callback?: any) {
    const state = this.store.getState()
    const userCurrent = state.portal.cognitoId

    if (userCurrent === cognitoId) {
      serverLog({
        level: "error",
        message: "Cannot remove signed in user",
        filename: __filename,
      })
      return
    }

    Middletier.xhrPost(
      `/profile/removeUser`,
      JSON.stringify({ cognitoId }),
      (response: any) => {
        if (response.success) {
          this.getUserList((res: any) => {
            if (res.success) {
              callback && callback({ success: true, data: response.data })
              C.toastNotification(
                "Admin successfully removed.",
                "success",
                5000,
              )
            } else {
              callback &&
                callback({ success: false, error: "Remove admin failed" })
              C.toastNotification(
                supportMassage("Admin could not be removed."),
                "danger",
                5000,
              )
            }
          })
        } else {
          callback({ success: false, error: "Remove admin failed" })
          C.toastNotification(
            supportMassage("Admin could not be removed."),
            "danger",
            5000,
          )
          serverLog({
            level: "error",
            message: "Remove admin failed",
            endpoint: "/profile/removeUser",
            filename: __filename,
            payload: response.error,
          })
        }
      },
      (xhrObject: any) => {
        callback({ success: false, error: "Remove admin failed" })
        C.toastNotification(
          supportMassage("Admin could not be removed."),
          "danger",
          5000,
        )
        if (xhrObject.status === 400) {
          serverLog({
            level: "error",
            message: "Remove Admin Failed",
            endpoint: "/profile/removeUser",
            filename: __filename,
            payload: xhrObject,
          })
        }
      },
    )
  }

  editUser(
    cognitoId: string,
    userProfile: T.tUserProfile,
    callback?: any,
    doNotShowToast?: boolean,
  ) {
    const state = this.store.getState()
    const userCurrent = state.portal.cognitoId
    Middletier.xhrPost(
      `/profile/editUser`,
      JSON.stringify({ cognitoId, userProfile }),
      (response: any) => {
        if (response.success) {
          if (userCurrent === cognitoId) {
            this.store.dispatch(
              PORTAL.setUserProfile(_.omit(userProfile, "orgId", "cognitoId")),
            )
          }
          this.getUserList((res: any) => {
            if (res.success) {
              if (!doNotShowToast) {
                C.toastNotification(
                  "Details successfully updated.",
                  "success",
                  5000,
                )
              }
              callback && callback({ success: true, data: response.data })
            } else {
              if (!doNotShowToast) {
                C.toastNotification(
                  supportMassage("Details could not be updated."),
                  "danger",
                  5000,
                )
              }
              callback &&
                callback({ success: false, error: "Edit user failed" })
            }
          })
        } else {
          callback && callback({ success: false, error: "Edit user failed" })
          serverLog({
            level: "error",
            message: "Editing User Failed",
            endpoint: "/profile/editUser",
            filename: __filename,
            payload: response.error,
          })
        }
      },
      (xhrObject: any) => {
        if (xhrObject.status === 400) {
          serverLog({
            level: "error",
            message: "Editing User Failed",
            endpoint: "/profile/editUser",
            filename: __filename,
            payload: xhrObject,
          })
        }
      },
    )
  }

  async getPublicIp() {
    try {
      const response = await fetch("https://api.ipify.org?format=json")
      const json = await response.json()
      const ip = json.ip
      return ip || null
    } catch (err) {
      serverLog({
        level: "error",
        message: "Failed to fetch IP Address",
        endpoint: "/marketing/analytics",
        filename: __filename,
        stack: err,
      })
      return null
    }
  }

  saveOnBoardingForm(
    data: any,
    callback?: (success: boolean, orgId?: string) => void,
  ) {
    // TODO: Specify type for onboarding data
    this.store.dispatch(PORTAL.setCompanyProfile({}, "", "LOADING"))
    Middletier.xhrPost(
      `/profile/profileSave`,
      JSON.stringify(data),
      (response: any) => {
        if (response.success) {
          const companyProfile = data.companyProfile || {}
          const adminProfile = data.adminProfile || {}
          const company = companyProfile.companyName || ""
          const numemployees = companyProfile.numberOfEmployees || ""
          const firstname = adminProfile.firstName || ""
          const lastname = adminProfile.lastName || ""
          const email = response.data.email || ""
          const phone = companyProfile.phone
          const security_subscription_status = response.data.subscriptionStatus
          const security_form_completed = true
          const marketingPayload = {
            email,
            company,
            numemployees,
            firstname,
            lastname,
            phone,
            security_form_completed,
            security_subscription_status,
          }
          this.sendMarketingAnalytics(
            marketingPayload,
            window.location.href,
            "Cybersafty Trial Onboarding",
            undefined,
            () => {
              this.loadCompanyProfile(() => {
                callback && callback(true, response.data.orgId)
              })
            },
          )
        } else {
          callback && callback(false)
          serverLog({
            level: "error",
            message: "Saving Onboarding Form Failed",
            endpoint: "/profile/profileSave",
            filename: __filename,
            payload: response,
          })
        }
      },
    )
  }

  async sendMarketingAnalytics(
    fields: { [key: string]: any },
    pageURI: string,
    pageName: string,
    leadStatus?: string,
    callback?: () => void,
  ) {
    fields["lead_form"] = "CyberSafety Trial v1"
    fields["record_type_id"] = "0120b0000006fCeAAI"
    fields["leadsource"] = "Website"
    fields["subscribe_to_promotions_news_updates"] = "true"
    const marketingCookie = JSON.parse(Cookies.get("zeguro-main") || "{}")
    if (marketingCookie && marketingCookie.fields) {
      const fieldsFromCookie: { name: string; value: string }[] =
        marketingCookie.fields
      fieldsFromCookie.map((field) => {
        fields[field.name] = field.value
        return
      })
    } else {
      serverLog({
        level: "error",
        message: `Marketing Cookie not found.`,
        endpoint: "",
        filename: __filename,
        payload: marketingCookie,
      })
    }

    const hutk = fields.hutk || ""

    const ipAddress = await this.getPublicIp()

    const data = {
      fields,
      pageURI,
      hutk,
      pageName,
      leadStatus,
      ...(ipAddress && { ipAddress }),
    }

    Middletier.xhrPost(
      `/marketing/analytics`,
      JSON.stringify(data),
      (response: any) => {
        callback && callback()
        if (response.success) {
          //TODO: Maybe give some user feedback later.
        } else {
          serverLog({
            level: "error",
            message: "Saving Onboarding Form Failed",
            endpoint: "/marketing/analytics",
            filename: __filename,
            payload: response,
          })
        }
      },
    )
  }

  loadCompanyProfile(callback?: () => void) {
    const state = this.store.getState()
    const cognitoId = state.portal.cognitoId
    this.store.dispatch(PORTAL.setCompanyProfile({}, "", "LOADING"))
    Middletier.xhrPost(
      `/profile/profileCheck`,
      JSON.stringify({ cognitoId }),
      (response: any) => {
        setTimeout(() => {
          const companyProfile: T.tCompanyProfile = response.companyData
          const userProfile: T.tUserProfile = response.userData
          const signupData: T.ISignupData = response.signupData
          const orgId: string = response.orgId
          const orgName: string = companyProfile && companyProfile.companyName
          callback && callback()
          if (response.success) {
            userProfile &&
              userProfile.email &&
              C.heapIdentify(userProfile.email, orgId, orgName)
            C.Plans.getFeatureUsageProfile((success: boolean) => {
              if (!success) {
                C.Plans.isValidSubscriptionId(undefined, (state, uiRules) => {
                  this.store.dispatch(PLANS.setUiRules(uiRules))
                  this.setCompanyState(
                    companyProfile,
                    orgId,
                    userProfile,
                    response.config,
                    signupData,
                  )
                })
              } else {
                this.setCompanyState(
                  companyProfile,
                  orgId,
                  userProfile,
                  response.config,
                  signupData,
                )
              }
            })
          } else {
            serverLog({
              level: "error",
              message: "Loading Company Profile Failed",
              endpoint: "/profile/profileCheck",
              filename: __filename,
              payload: response,
            })
            C.heapResetIdentity()
          }
        }, 500)
      },
    )
  }

  setCompanyState(
    companyProfile: T.tCompanyProfile,
    orgId: string,
    userProfile: T.tUserProfile,
    config: T.tConfig,
    signupData: T.ISignupData,
  ) {
    const redirectURL = Cookies.get("redirectURL")
    if (companyProfile === null) {
      this.loadDisclaimer()
    }

    this.store.dispatch(
      PORTAL.setCompanyProfile(companyProfile, orgId, "SUCCESS"),
    )

    userProfile && this.store.dispatch(PORTAL.setUserProfile(userProfile))

    signupData && this.store.dispatch(PORTAL.setSignupData(signupData))

    if (config) {
      this.window.securityPolicyConfig = config.securityPolicyConfig
      this.store.dispatch(PORTAL.setConfig(config))
    }

    C.logRocketIdentify()
    C.Chargebee.loadScript()
    if (redirectURL) {
      Cookies.remove("redirectURL")
      if (redirectURL.includes("/api/")) {
        window.location.replace(redirectURL)
        return
      } else {
        const routing = new URL(redirectURL).pathname
        history.push(routing)
      }
    }
  }

  updateCompany(
    data: any,
    orgId: string,
    callback?: (success: boolean, data?: any) => void,
  ) {
    this.store.dispatch(PORTAL.setCompanyProfile({}, orgId, "LOADING"))
    Middletier.xhrPost(
      `/profile/companyUpdate`,
      JSON.stringify(data),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(
            PORTAL.setCompanyProfile(response.data, orgId, "SUCCESS"),
          )
          callback && callback(false, response.data)
          C.toastNotification("Details successfully updated.", "success", 5000)
        } else {
          this.store.dispatch(PORTAL.setCompanyProfile({}, orgId, "FAILED"))
          callback && callback(true)
          C.toastNotification(
            supportMassage("Details could not be updated."),
            "danger",
            5000,
          )
          serverLog({
            level: "error",
            message: "Company Update Failed",
            endpoint: "/profile/companyUpdate",
            filename: __filename,
            payload: response,
          })
        }
      },
      (err: any) => {
        callback && callback(true)
        C.toastNotification(
          supportMassage("Details could not be updated."),
          "danger",
          5000,
        )
        serverLog({
          level: "error",
          message: "Company Update Failed",
          endpoint: "/profile/companyUpdate",
          filename: __filename,
          payload: err?.message,
        })
      },
    )
  }

  createSessionObject(token: string, callback?: () => void) {
    Middletier.xhrPost(
      `/accounts/session`,
      JSON.stringify(token),
      (response: any) => {
        callback && callback()
        this.store.dispatch(
          this.store.dispatch(
            PORTAL.setAuthenticated(
              true,
              response.username,
              response.newUser,
              response.zeguroCustomerService || false,
            ),
          ),
        )
      },
      (xhrObject: any) => {
        if (xhrObject.status === 400) {
          serverLog({
            level: "error",
            message: "Authentication Failed",
            endpoint: "/accounts/session",
            filename: __filename,
            payload: xhrObject,
          })
        }
      },
    )
  }

  /////////////////////////////////////////////

  inviteUser(
    email: string,
    firstName: string,
    lastName: string,
    jobTitle: string,
    callback?: any,
  ) {
    Middletier.xhrPost(
      `/accounts/invite_user`,
      JSON.stringify({ email, firstName, lastName, jobTitle }),
      (response: any) => {
        if (response.success) {
          this.getUserList((res: any) => {
            if (res.success) {
              callback && callback({ success: true, data: response.data })
            } else {
              callback &&
                callback({
                  success: false,
                  error: "Invite user failed. Please try again.",
                })
            }
          })
        } else {
          callback &&
            callback({
              success: false,
              error: response.error || "Invite user failed. Please try again.",
            })
          serverLog({
            level: "error",
            message: "Invite User Failed",
            endpoint: "/accounts/invite_user",
            filename: __filename,
            payload: response.error,
          })
        }
      },
      (xhrObject: any) => {
        if (xhrObject.status === 400) {
          serverLog({
            level: "error",
            message: "Invite User Failed",
            endpoint: "/accounts/invite_user",
            filename: __filename,
            payload: xhrObject,
          })
        }
      },
    )
  }

  logout() {
    document.cookie = "connect.sid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    Middletier.xhrPost(`/accounts/logout`, "{}", () => {
      if (document.location) {
        document.location.replace("/")
      }
    })
    C.heapResetIdentity()
  }

  loadDisclaimer() {
    Middletier.fetchData("/disclaimer.html", (success, data: any) => {
      if (success) {
        this.store.dispatch(PORTAL.setLegalDisclaimer(data))
      }
    })
  }

  periodicSessionCheck(callback: any, errorCallback: any) {
    Middletier.xhrPost("/accounts/check", "{}", callback, errorCallback)
  }

  touchSession(callback: any) {
    Middletier.xhrPost(
      "/accounts/check",
      JSON.stringify({ touch: true }),
      callback,
    )
  }

  checkSession(reason: string) {
    Middletier.getCSRFToken().then(() =>
      Middletier.xhrPost(
        "/accounts/check",
        JSON.stringify({ bc: new URL(location.href).searchParams.get("bc") }),
        (response: any) => {
          const authenticated = response.authenticated
          const cognitoId = response.username
          const isProduction: boolean = response.isProduction
          const productionMode: string = response.productionMode
          const newUser: boolean = response.newUser
          const isZeguroServiceSession: boolean =
            response.isZeguroServiceSession || false
          const heapKey = response.heapKey
          const email = response.email

          this.store.dispatch(PORTAL.setPortalEnvironment(response))
          this.store.dispatch(
            PORTAL.setProduction(isProduction, productionMode),
          )

          isProduction && C.logRocketInit()

          if (heapKey && this.window.heap && this.window.heap.load) {
            this.window.heap.load(heapKey, {
              forceSSL: true,
              secureCookie: true,
            })
          }

          if (email) {
            this.store.dispatch(PORTAL.setUserEmail(email))
          }
          if (authenticated) {
            this.store.dispatch(
              PORTAL.setAuthenticated(
                authenticated,
                cognitoId,
                newUser,
                isZeguroServiceSession,
              ),
            )
            this.loadCompanyProfile()
          } else {
            this.store.dispatch(
              PORTAL.setAuthenticated(false, null, null, false),
            )
          }
        },
      ),
    )
  }

  magicLinkSignup(linkId: string, password: string, secOptIn: boolean) {
    this.store.dispatch(
      PORTAL.setMagicLinkSignup({
        signupStatus: "LOADING",
        signupError: new Error(""),
      }),
    )
    Middletier.xhrPost(
      `/accounts/magicLinkSignup`,
      JSON.stringify({ linkId, password, secOptIn }),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              signupStatus: "SUCCESS",
              signupError: new Error(""),
            }),
          )
          if (document.location) {
            document.location.replace("/")
          }
        } else {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              signupStatus: "FAILED",
              signupError: response.error,
            }),
          )
          serverLog({
            level: "error",
            message: "Signing Up Via Magic Link Failed",
            endpoint: "/accounts/magicLinkSignup",
            filename: __filename,
            payload: response,
          })
        }
      },
    )
  }

  magicLinkSignupUpdate(
    linkId: string,
    status?: T.tMagicLinkSignupFetchResponse["status"],
    eventType?: T.tMagicLinkSignupEventType,
    eventDateTime?: string,
  ) {
    this.store.dispatch(
      PORTAL.setMagicLinkSignup({
        updateStatus: "LOADING",
        updateError: "",
      }),
    )
    Middletier.xhrPost(
      `/accounts/magicLinkSignupUpdate`,
      JSON.stringify({ linkId, status, eventType, eventDateTime }),
      (response: any) => {
        if (response.success) {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              status,
              updateStatus: "SUCCESS",
              updateError: "",
            }),
          )
        } else {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              updateStatus: "FAILED",
              updateError: response.error.message,
            }),
          )
          serverLog({
            level: "error",
            message: "Updating Magic Link Failed",
            endpoint: "/accounts/magicLinkUpdate",
            filename: __filename,
            payload: response,
          })
        }
      },
    )
  }

  magicLinkSignupFetch(linkId: string) {
    this.store.dispatch(
      PORTAL.setMagicLinkSignup({
        createdFor: "",
        expirationDate: "",
        fetchStatus: "LOADING",
        fetchError: "",
      }),
    )
    Middletier.fetchJson(
      `/accounts/magicLinkSignupFetch/${linkId}`,
      (success, response) => {
        if (success) {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              status: response.data.status,
              createdFor: response.data.createdFor,
              fetchStatus: "SUCCESS",
              fetchError: "",
            }),
          )
        } else {
          this.store.dispatch(
            PORTAL.setMagicLinkSignup({
              createdFor: "",
              fetchStatus: "FAILED",
              fetchError: "Unable to fetch Magic Link",
            }),
          )
          serverLog({
            level: "error",
            message: "Fetching Signup Magic Link Failed",
            endpoint: "/accounts/magicLinkSignupFetch",
            filename: __filename,
            payload: response.data,
          })
        }
      },
    )
  }

  loadModuleActivity() {
    const state = this.store.getState()
    const { compliance, insurance, training } = state.portal.dashboard
    !compliance &&
      !insurance &&
      !training &&
      this.store.dispatch(PORTAL.setDashboardActivity({ status: "LOADING" }))
    Middletier.fetchJson(`/dashboard/getModuleActivity`, (success, data) => {
      if (success) {
        const isEqualData = _.isEqual(
          { compliance, insurance, training },
          data.data,
        )
        !isEqualData &&
          this.store.dispatch(
            PORTAL.setDashboardActivity({ ...data.data, status: "SUCCESS" }),
          )
      } else {
        this.store.dispatch(PORTAL.setDashboardActivity({ status: "FAILED" }))
        serverLog({
          level: "error",
          message: "Fetching Dashboard Activity Data failed",
          endpoint: "/dashboard/getModuleActivity",
          filename: __filename,
          payload: data,
        })
      }
    })
  }
  loadDashboardMonitoring() {
    const state = this.store.getState()
    const monitoringData = state.portal.dashboard?.monitoring
    !monitoringData &&
      this.store.dispatch(PORTAL.setDashboardMonitoring({ status: "LOADING" }))
    Middletier.fetchJson(`/dashboard/monitoring`, (success, data) => {
      if (success) {
        const isEqualData = _.isEqual(monitoringData, data.data)
        !isEqualData &&
          this.store.dispatch(
            PORTAL.setDashboardMonitoring({
              ...data,
              status: "SUCCESS",
            }),
          )
      } else {
        this.store.dispatch(PORTAL.setDashboardMonitoring({ status: "FAILED" }))
        serverLog({
          level: "error",
          message: "Fetching Dashboard Monitoring Data failed",
          endpoint: "/dashboard/monitoring",
          filename: __filename,
          payload: data,
        })
      }
    })
  }
}
export default Portal
