interface ICategoryIcons {
  name: string
  icon: string
  colorIcon?: string
}
export const CATEGORY_ICONS: ICategoryIcons[] = [
  { name: "People", icon: "user-friends.svg", colorIcon: "icon_people.svg" },
  { name: "Process", icon: "cogs.svg", colorIcon: "icon_process.svg" },
  { name: "Business Process", icon: "people-arrows.svg" },
  { name: "Technical Process", icon: "laptop.svg" },
  {
    name: "Technology",
    icon: "technology.svg",
    colorIcon: "icon_technology.svg",
  },
]

export const COMPLIMENTARY_SUPPORT_URL =
  "https://concierge-scheduling.zeguro.com"

export const HELP_CENTER_URL =
  "https://zeguro.zendesk.com/hc/en-us/articles/7793335742487"
