import { ButtonSmall } from "@src/Components/Button"
import * as React from "react"
import styled from "styled-components"

export const PageLoadFailure = () => {
  const contactText = `Please try again. If the problem persists please contact us at <a href="mailto:support@zeguro.com" target="_blank">support@zeguro.com</a>.`
  return (
    <ErrorMessage>
      <Message>
        <i className="far fa-frown" aria-hidden="true" />
        oops, something went wrong.
      </Message>
      <ContactUs dangerouslySetInnerHTML={{ __html: contactText }} />

      <ButtonSmall
        width={100}
        onClick={() => {
          window.location.replace("/")
        }}
      >
        Continue
      </ButtonSmall>
    </ErrorMessage>
  )
}

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Message = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`

const ContactUs = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
`
