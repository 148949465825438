import { ButtonSecondary } from "@src/Components/Button"
import {
  Content,
  Heading,
  Panel,
  PanelContainer,
  SubHeading,
} from "@src/Components/Common"
import { Link } from "@src/Components/Link"
import C from "@src/Controller"
import { media } from "@src/theme"
import * as React from "react"
import styled from "styled-components"

import MessageContainer from "../MessageContainer"

export type tOnSubmit = (data: any) => void

interface IVerifyEmail {
  height: number
  width: number
  onVerifyEmail: (username: string) => void
  showSupportModal: () => void
  redirectToLogin: () => void
  redirectToSignup: () => void
  emailToVerify: string
  marketingLogoPrimary?: string
  marketingLogoSecondary?: string
  marketingLogoPrimaryHeight?: string
  marketingLogoSecondaryHeight?: string
}

interface IVerifyEmailState {
  username: string
}

class VerifyEmail extends React.Component<IVerifyEmail, IVerifyEmailState> {
  public readonly state: IVerifyEmailState = {
    username: "",
  }

  componentDidMount() {
    C.gaDataLayerPush({
      event: "pageview",
      page: {
        path: "/verify",
        title: "Verify Email Page",
      },
    })
  }

  render() {
    const pContent = {
      height: this.props.height,
    }

    const goToSignupScreen = () => {
      this.props.redirectToSignup()
    }

    const resendVerificationCode = () => {
      this.props.onVerifyEmail(this.props.emailToVerify)
    }

    return (
      <Content {...pContent}>
        <MessageContainer />
        <PanelContainer height={this.props.height}>
          <Panel>
            <Row>
              <Heading align="center" margin="20px 0px">
                Verify your email.
              </Heading>
            </Row>
            <Row>
              <SubHeading>
                Check your email
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.emailToVerify &&
                      this.props.emailToVerify !== ""
                        ? ` at <b>${this.props.emailToVerify}</b>`
                        : "",
                  }}
                />
                , and click the link to activate your account.
              </SubHeading>
            </Row>
            <Row>
              <Icon src="images/icon_email_verified.svg" />
            </Row>
            <br />
            <Row>
              <HeadingGray>Didn&apos;t receive an email?</HeadingGray>
            </Row>
            <br />
            <Row>
              <ButtonContainer>
                <ButtonSecondary
                  onClick={resendVerificationCode}
                  id="resend-email-button"
                  width={170}
                  margin={10}
                >
                  Resend Email
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={() => {
                    window.open(
                      "https://help.zeguro.com/hc/en-us/articles/360033781774",
                      "_blank",
                    )
                  }}
                  id="help-center-button"
                  width={170}
                  margin={10}
                >
                  Help Center
                  <ExternalIcon className="far fa-external-link-alt" />
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={this.props.showSupportModal}
                  id="contact-support-button"
                  width={170}
                  margin={10}
                >
                  Contact Support
                </ButtonSecondary>
              </ButtonContainer>
            </Row>
            <Row>
              <FooterText>
                Or&nbsp;
                <Link onClick={goToSignupScreen}>
                  use another email address instead.
                </Link>
              </FooterText>
            </Row>
          </Panel>
        </PanelContainer>
      </Content>
    )
  }
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.img`
  width: 110px;
`

const FooterText = styled.p`
  margin-top: 20px;
  font-size: 15px;
  line-height: 21px;
`

const ExternalIcon = styled.i`
  font-size: 13px;
  margin-left: 5px;
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media ${media.medium} {
    flex-direction: column};
  }
`

const HeadingGray = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: #6f7379;
  text-align: center;
  width: 100%;
  font-weight: bold;
`
export default VerifyEmail
