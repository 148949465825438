import {
  actions as MONITORING,
  MONITORING_MODALS_KEYS,
} from "@src/Actions/Reducers/MonitoringReducer"
import Dialog from "@src/Components/Dialog"
import ExternalLink from "@src/Components/ExternalLink"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import { Content, ContentArea, Panel } from "@src/Components/View"
import Waiting from "@src/Components/Waiting"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { colors } from "@src/theme"
import * as T from "@src/types"
import {
  ProbelyCreateTargetRequest,
  ProbelyEditScheduledScanRequest,
  ProbelyEditTargetRequest,
  ProbelyScheduleScanRequest,
} from "@zeguro/schema-validator/dist/types/coco/probely/combined"
import * as _ from "lodash"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"

import AddWebAppForm from "../AddWebAppForm"
import { SCAN_LEVELS } from "../constants"
import EditAppForm from "../EditAppForm"
import EditScheduleForm from "../EditScheduleForm"
import ScansOnboarding from "../ScansOnboarding"
import ScansPage from "../ScansPage"
import { StyledDialog } from "./styles"

type IEditSchedule = Omit<
  ProbelyEditScheduledScanRequest & ProbelyEditTargetRequest,
  "orgId"
>

interface IMonitoring {
  showUpgradeButton: boolean
  targetList: T.IProbelyTarget[]
  scanMap: { [targetId: string]: T.IProbelyScan[] }
  scheduleMap: { [targetId: string]: T.IProbelySchedule[] }
  numberOfTargets: T.tPlanCount
  scansLoaded: boolean
  scanProfile: T.tProbelyScanProfile[] | null
  isHighestPlan?: boolean
  addWebApp: (values: ProbelyCreateTargetRequest) => void
  editTarget: (values: ProbelyEditTargetRequest) => void
  deleteTarget: (targetId: string, targetName: string) => void
  getScanReport: (
    targetName: string,
    targetId: string,
    scanId: string,
    reportType: string,
  ) => void
  editSchedule: (values: IEditSchedule) => void
  createSchedule: (values: ProbelyScheduleScanRequest) => void
  showSubscriptionPage: () => void
  onIntro: () => void
  updateActiveTarget: () => void
  syncScanAndSchedule: (targetId: string) => void
}

interface IMonitoringParams {
  id?: string
}

const Monitoring = ({
  targetList,
  scanProfile,
  scheduleMap,
  scanMap,
  numberOfTargets,
  scansLoaded,
  isHighestPlan,
  getScanReport,
  editTarget,
  showSubscriptionPage,
  onIntro,
  updateActiveTarget,
  syncScanAndSchedule,
  deleteTarget,
  editSchedule,
  createSchedule,
  addWebApp,
}: IMonitoring) => {
  const interval: React.MutableRefObject<NodeJS.Timer | null> =
    React.useRef(null)
  const match = useRouteMatch<IMonitoringParams>()
  const dispatch = useDispatch()
  const [modalError] = React.useState("")
  const [activeTargetId, setActiveTargetId] = React.useState("")
  const [activeTargetName, setActiveTargetName] = React.useState("")
  const [activeScheduledScanId, setActiveScheduledScanId] = React.useState("")
  const [activeScheduleRecurrence, setActiveScheduleRecurrence] =
    React.useState("q")
  const [activeScanLevel, setActiveScanLevel] = React.useState("")
  const [activeScheduledDayOfWeek, setActiveScheduledDayOfWeek] =
    React.useState(0)
  const [activeWeekIndex, setActiveWeekIndex] = React.useState("")
  const [activeRunOnDayOfWeek, setActiveRunOnDayOfWeek] = React.useState(false)
  const [isScanInProgress, setIsScanInProgress] = React.useState(false)
  const [grayModalBackground, setGrayModalBackground] = React.useState(false)

  const {
    isAddAppModal,
    isCloseAddAppConfirm,
    isEditAppModal,
    isEditScheduleModal,
    isDeleteAppConfirm,
  } = useSelector((state: tState) => state.monitoring.toggleModal)
  const isRemoveModalLoading = useSelector(
    (state: tState) => state.monitoring.deleteTarget.isLoading,
  )

  const startUpdatingActivetarget = (frequency: number, targetId: string) => {
    if (!interval.current && targetId) {
      updateActiveTarget()
      interval.current = setInterval(() => {
        updateActiveTarget()
      }, frequency)
    }
  }

  const stopUpdatingActiveTarget = () => {
    interval.current && clearInterval(interval.current)
    interval.current = null
  }

  const setInitialTargetAndScheduleByUrl = (
    sync: boolean | undefined,
    findTargetFromPath: T.IProbelyTarget,
  ) => {
    const { targetId, name, scan_profile } = findTargetFromPath
    const hasValidSchedule =
      targetId &&
      scheduleMap &&
      scheduleMap[targetId] &&
      scheduleMap[targetId][0]
    const schedule = hasValidSchedule && targetId && scheduleMap[targetId][0]
    const scheduledScanId =
      (hasValidSchedule && schedule && schedule.scheduledScanId) || undefined
    const recurrence =
      (hasValidSchedule && schedule && schedule.recurrence) || undefined
    const scheduledDayOfWeek =
      (hasValidSchedule && schedule && schedule.scheduledDayOfWeek) || undefined
    const weekIndex =
      (hasValidSchedule && schedule && schedule.weekIndex) || undefined
    const runOnDayOfWeek =
      (hasValidSchedule && schedule && schedule.runOnDayOfWeek) || undefined

    setActiveTargetAndSchedule(
      name,
      targetId,
      scheduledScanId,
      recurrence,
      scan_profile,
      scheduledDayOfWeek,
      weekIndex,
      runOnDayOfWeek,
    )
    targetId && sync && syncScanAndSchedule(targetId)
  }

  const setInitialTargetAndSchedule = (sync?: boolean) => {
    const targetMap = getTargetMap()

    const convertTargetsToArray = Object.keys(targetMap).map((key) => {
      return targetMap[key]
    })
    const findTargetFromPath = convertTargetsToArray.find(
      (t) => t.name === match.params?.id,
    )

    if (findTargetFromPath) {
      setInitialTargetAndScheduleByUrl(sync, findTargetFromPath)
      return
    }

    const hasValidTarget = targetList && targetList.length > 0
    const target = hasValidTarget && targetList[0]
    const targetId = (target && target.targetId) || undefined
    const targetName = (target && target.name) || undefined
    const hasValidSchedule =
      hasValidTarget &&
      targetId &&
      scheduleMap &&
      scheduleMap[targetId] &&
      scheduleMap[targetId][0]
    const schedule = hasValidSchedule && targetId && scheduleMap[targetId][0]
    const scheduledScanId =
      (hasValidSchedule && schedule && schedule.scheduledScanId) || undefined
    const recurrence =
      (hasValidSchedule && schedule && schedule.recurrence) || undefined
    const scheduledDayOfWeek =
      (hasValidSchedule && schedule && schedule.scheduledDayOfWeek) || undefined
    const weekIndex =
      (hasValidSchedule && schedule && schedule.weekIndex) || undefined
    const runOnDayOfWeek =
      (hasValidSchedule && schedule && schedule.runOnDayOfWeek) || undefined
    const scanLevel = (target && target.scan_profile) || undefined

    setActiveTargetAndSchedule(
      targetName,
      targetId,
      scheduledScanId,
      recurrence,
      scanLevel,
      scheduledDayOfWeek,
      weekIndex,
      runOnDayOfWeek,
    )
    targetId && sync && syncScanAndSchedule(targetId)
  }

  const setScanInfo = () => {
    const targetIdArr = (scanMap && Object.keys(scanMap)) || []
    let targetIsScanInProgress = false
    targetIdArr.forEach((targetId) => {
      const scans = scanMap[targetId] || []
      targetIsScanInProgress =
        (scans && scans[0] && scans[0].status !== "completed") ||
        isScanInProgress
    })
    setIsScanInProgress(targetIsScanInProgress)
  }

  const getTargetMap = () => {
    const targetMap: { [targetId: string]: T.IProbelyTarget } = {}
    targetList.map((target) => {
      targetMap[target.targetId] = target
    })
    return targetMap
  }

  const getScanLevels = () =>
    SCAN_LEVELS.filter((e) => _.includes(scanProfile || [], _.toUpper(e[1])))
  const toggleModal = (modal: string) => dispatch(MONITORING.toggleModal(modal))

  const setActiveTargetAndSchedule = (
    name?: string | undefined,
    id?: string | undefined,
    scheduledScanId?: string | undefined,
    recurrence?: string | undefined,
    level?: string | undefined,
    scheduledDayOfWeek?: number | undefined,
    weekIndex?: string | undefined,
    runOnDayOfWeek?: boolean | undefined,
  ) => {
    setActiveTargetId(id || activeTargetId)
    setActiveTargetName(name || activeTargetName)
    setActiveScheduledScanId(scheduledScanId || activeScheduledScanId)
    setActiveScheduleRecurrence(recurrence || activeScheduleRecurrence)
    setActiveScanLevel(level || activeScanLevel)
    setActiveScheduledDayOfWeek(scheduledDayOfWeek || activeScheduledDayOfWeek)
    setActiveWeekIndex(weekIndex || activeWeekIndex)
    setActiveRunOnDayOfWeek(runOnDayOfWeek || activeRunOnDayOfWeek)
    startUpdatingActivetarget(15000, id || activeTargetId)
  }

  const getAddWebAppModal = () => {
    return (
      <StyledDialog
        title="Add a Website to Scan"
        isOpen={isAddAppModal || isCloseAddAppConfirm}
        onClose={() => toggleModal(MONITORING_MODALS_KEYS.isCloseAddAppConfirm)}
        canOutsideClickClose={false}
        grayBackground={grayModalBackground}
        content={
          <AddWebAppForm
            onClose={() =>
              toggleModal(MONITORING_MODALS_KEYS.isCloseAddAppConfirm)
            }
            addWebApp={addWebApp}
            scanLevels={getScanLevels()}
            targetMap={targetList}
            setGrayModalBackground={setGrayModalBackground}
            grayBackground={grayModalBackground}
          />
        }
      />
    )
  }

  const getConfirmationCloseModal = () => {
    return (
      <Dialog
        title="Close without adding Website?"
        text="Are you sure you want to close without scheduling?"
        actionText="Close"
        isOpen={isCloseAddAppConfirm}
        onClose={() => toggleModal(MONITORING_MODALS_KEYS.isCloseAddAppConfirm)}
        className="zeg-danger"
        onAction={() => {
          toggleModal(MONITORING_MODALS_KEYS.isCloseAddAppConfirm)
          toggleModal(MONITORING_MODALS_KEYS.isAddAppModal)
        }}
      />
    )
  }

  const toggleDeleteAppModal = () =>
    dispatch(MONITORING.toggleModal(MONITORING_MODALS_KEYS.isDeleteAppConfirm))
  const getDeleteAppModal = () => {
    return (
      <Dialog
        title="Remove Website?"
        text="Are you sure you want to remove this website? Your scan reports will also be removed."
        actionText="Remove"
        isOpen={isDeleteAppConfirm}
        onClose={() => toggleDeleteAppModal()}
        error={modalError}
        canOutsideClickClose={false}
        isCloseButtonShown={!isRemoveModalLoading}
        canEscapeKeyClose={!isRemoveModalLoading}
        className="zeg-danger"
        onAction={() => deleteTarget(activeTargetId, activeTargetName)}
        content={
          isRemoveModalLoading ? (
            <div style={{ padding: "10px" }}>
              <Waiting height="100%" text="Removing Website." padding={25} />
            </div>
          ) : undefined
        }
      />
    )
  }

  const getEditAppModal = () => {
    return (
      <Dialog
        title="Edit Website Name"
        isOpen={isEditAppModal}
        onClose={() => toggleModal(MONITORING_MODALS_KEYS.isEditAppModal)}
        content={
          <EditAppForm
            onClose={() => toggleModal(MONITORING_MODALS_KEYS.isEditAppModal)}
            editTarget={editTarget}
            appName={activeTargetName}
            targetId={activeTargetId}
            targetMap={targetList}
          />
        }
      />
    )
  }

  const getEditSchedule = () => {
    const schedule = Boolean(
      scheduleMap &&
        activeTargetId &&
        scheduleMap[activeTargetId] &&
        scheduleMap[activeTargetId][0],
    )

    return (
      <StyledDialog
        title={schedule ? "Edit Scan Schedule" : "Add a Website to Scan"}
        isOpen={isEditScheduleModal}
        grayBackground
        onClose={() => toggleModal(MONITORING_MODALS_KEYS.isEditScheduleModal)}
        content={
          <EditScheduleForm
            onClose={() =>
              toggleModal(MONITORING_MODALS_KEYS.isEditScheduleModal)
            }
            editSchedule={editSchedule}
            scheduledScanId={activeScheduledScanId}
            targetId={activeTargetId}
            createSchedule={createSchedule}
            scanLevels={getScanLevels()}
            hasSchedule={schedule}
          />
        }
      />
    )
  }

  React.useEffect(() => {
    if (activeTargetId) {
      syncScanAndSchedule(activeTargetId)
    }
  }, [activeTargetId, activeScheduledScanId])

  React.useEffect(() => {
    setInitialTargetAndSchedule()
  }, [JSON.stringify(targetList), JSON.stringify(scheduleMap)])

  React.useEffect(() => {
    setScanInfo()
  }, [JSON.stringify(targetList), JSON.stringify(scanMap)])

  React.useEffect(() => {
    setInitialTargetAndSchedule(true)
    setScanInfo()
    C.heapTrack("Viewed Scanning Home")
    return () => stopUpdatingActiveTarget()
  }, [])

  return (
    <ContentArea>
      {getAddWebAppModal()}
      {getDeleteAppModal()}
      {getEditAppModal()}
      {getEditSchedule()}
      {getConfirmationCloseModal()}
      <PageHeaderCard
        heading="Website Security Scans"
        id="monitoring-heading"
        showViewIntro
        onClickViewIntro={onIntro}
        showHelpCenter
        helpCenterLinkUrl="https://help.zeguro.com/hc/en-us/articles/360033542954"
        cardIconUrl="/images/icon_scan_light_blue.svg"
        borderColor={colors.orange90}
        cardBody={
          <div>
            Websites are just like apps on your phone or laptop - they are
            programs that store, process, and retrieve information like an
            online banking site or Gmail. The only difference is that they run
            inside your web browser rather than on a specific device.&nbsp;
            <ExternalLink
              url={"https://help.zeguro.com/hc/en-us/articles/360033542954"}
              text="More"
              margin="0px 40px 0px 0px"
            />
          </div>
        }
      />
      <Panel borderColor={colors.orange90}>
        {targetList.length > 0 ? (
          <Content>
            <ScansPage
              targetMap={getTargetMap()}
              activeTargetId={activeTargetId}
              activeTargetName={activeTargetName}
              activeScanLevel={activeScanLevel}
              setActiveTargetAndSchedule={setActiveTargetAndSchedule}
              scanMap={scanMap}
              scheduleMap={scheduleMap}
              getScanReport={getScanReport}
              showSubscriptionPage={showSubscriptionPage}
              numberOfTargets={numberOfTargets}
              scansLoaded={scansLoaded}
              isHighestPlan={isHighestPlan}
            />
          </Content>
        ) : (
          <Content justify="space-between">
            <ScansOnboarding />
          </Content>
        )}
      </Panel>
    </ContentArea>
  )
}

export default Monitoring
