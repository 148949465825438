import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import Level from "@src/Portal/Zimm/Level"
import { IZimmDashboardData } from "@src/types"
import { format } from "date-fns"
import * as moment from "moment"
import * as React from "react"

import { SubHeading } from "../styles"
import { DateInfo, ElementTitle, TooltipArea } from "./styles"

interface IFilledState {
  zimmData?: IZimmDashboardData
  isFinished: boolean
}
export const FilledStateCard = ({ zimmData, isFinished }: IFilledState) => {
  const { isLargeScreen } = usePortalSize()

  return (
    <>
      {!isFinished && (
        <SubHeading>Analyze and strengthen your cybersecurity.</SubHeading>
      )}
      {isFinished && (
        <>
          <TooltipArea data-tip data-for="tooltip-zimm-empty-state">
            <Level level={zimmData?.overallLevel} large cursor="help" />
          </TooltipArea>
          <ElementTitle>{zimmData?.levelInfo?.levelName}</ElementTitle>
          <DateInfo margin={!isLargeScreen ? "42px 0 0" : "16px 0 0"}>
            Updated {moment(zimmData?.lastUpdated).format("MMM D, YYYY")}
          </DateInfo>
          <Tooltip
            offset={{ top: -18 }}
            id="tooltip-zimm-empty-state"
            clickable
            content={
              <Section fontSize="16px" padding="5 10">
                <Section margin="0 0 10 0" fontWeight="700">
                  Level {zimmData?.overallLevel}
                </Section>
                <div>
                  {zimmData?.levelInfo?.description}{" "}
                  <Link
                    href="https://zeguro.zendesk.com/hc/en-us/articles/7793335742487-Cyber-Maturity-Model-and-Levels"
                    withIcon
                    isUnderline
                  >
                    More
                  </Link>
                </div>
                <br />
                {zimmData?.lastUpdated && (
                  <i>
                    Assessment last updated{" "}
                    {format(new Date(zimmData?.lastUpdated), "MMM d, yyyy")}
                  </i>
                )}
              </Section>
            }
          />
        </>
      )}
    </>
  )
}
