import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import ExternalLink from "@src/Components/ExternalLink"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import * as React from "react"

import { ButtonRow, FormContent, Info } from "./styles"

interface IChoosePolicyForm {
  onClose: () => void
  onAction: (policyName: string | null) => void
}

const ChoosePolicyForm = ({ onClose, onAction }: IChoosePolicyForm) => {
  const [customPolicyName, setCustomPolicyName] = React.useState<string | null>(
    null,
  )

  const onPolicyNameChange = (event: any) => {
    const value = event.target.value
    setCustomPolicyName(value)
  }

  return (
    <FormContent>
      <Info>
        Name your security policy. Did you know you can also customize existing
        policies?{" "}
        <ExternalLink
          text="Learn More"
          margin="0px 40px 0px 10px"
          url="https://help.zeguro.com/hc/en-us/articles/360039397974-Customizing-Security-Policies-"
        />
      </Info>
      <FloatingLabelInput
        ref={(el: any) => {
          if (el) {
            el.setFocus()
          }
        }}
        width={"100%"}
        label="Policy Name"
        value={customPolicyName || ""}
        onChange={onPolicyNameChange}
      />
      <ButtonRow>
        <ButtonCancel width={100} onClick={onClose}>
          Cancel
        </ButtonCancel>
        <ButtonSmall
          onClick={() => {
            onClose()
            onAction(customPolicyName)
          }}
          disabled={(customPolicyName || "").length === 0}
          width={153}
        >
          Create Policy
        </ButtonSmall>
      </ButtonRow>
    </FormContent>
  )
}

export default ChoosePolicyForm
