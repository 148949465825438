import {
  actions as GLOBAL_MODALS,
  GLOBAL_MODALS_KEYS,
} from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { FaIcon } from "@src/Components/FaIcon"
import { colors } from "@src/theme"
import * as React from "react"
import { useDispatch } from "react-redux"

import PageHeaderCard from "./PageHeaderCard"
import {
  BodyText,
  CardBodyFooterStyled,
  CardBodyStyled,
  InfoTextStyled,
} from "./styles"

interface IHeaderCard {
  cardIconUrl: string
}
const HeaderCard = ({ cardIconUrl }: IHeaderCard) => {
  const { clientWidth } = usePortalSize()
  const dispatch = useDispatch()
  const learnMoreUrl =
    "https://help.zeguro.com/hc/en-us/articles/19379702177175-Cyber-Concierge-Overview"

  return (
    <PageHeaderCard
      heading="Cyber Concierge"
      infoText={
        clientWidth > 1115 ? (
          <InfoTextStyled>
            <FaIcon className="fas fa-tag" fontSize="16px" margin="0 5px 0 0" />
            Included with your subscription
          </InfoTextStyled>
        ) : (
          ""
        )
      }
      id="cyber-concierge-heading"
      showViewIntro
      onClickViewIntro={() =>
        dispatch(GLOBAL_MODALS.changeIntroModal("cyberconcierge"))
      }
      showHelpCenter={true}
      helpCenterLinkUrl={learnMoreUrl}
      buttonText="Upgrade"
      buttonType="secondary"
      cardIconUrl={cardIconUrl}
      borderColor={colors.violet}
      cardBody={
        <CardBodyStyled>
          <BodyText>
            Get one-on-one help from a cybersecurity expert for your security
            needs.
          </BodyText>
          <CardBodyFooterStyled flex="space-between">
            <ButtonSmall
              width={200}
              onClick={() =>
                dispatch(
                  GLOBAL_MODALS.toggleGlobalModal(
                    GLOBAL_MODALS_KEYS.isContactCyberConcierge,
                  ),
                )
              }
            >
              <FaIcon
                className="fas fa-user-headset"
                fontSize="16px"
                margin="0 10px 0 0"
              />
              Contact Concierge
            </ButtonSmall>
            {clientWidth < 1115 && (
              <InfoTextStyled>
                <FaIcon
                  className="fas fa-tag"
                  fontSize="16px"
                  margin="0 5px 0 0"
                />
                Included with your subscription
              </InfoTextStyled>
            )}
          </CardBodyFooterStyled>
        </CardBodyStyled>
      }
    />
  )
}

export default HeaderCard
