import {
  Content,
  FormElement,
  Heading,
  Panel,
  PanelContainer,
  SubHeading,
} from "@src/Components/Common"
import * as Cookies from "js-cookie"
import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { colors } from "@src/theme"
import * as _ from "lodash"
import * as React from "react"
import styled from "styled-components"

import { ButtonSecondary, ButtonSmall } from "../../Components/Button"
import { Dropdown } from "../../Components/Dropdown"
import FloatingLabelInput from "../../Components/FloatingLabelInput"
import C from "../../Controller"
import * as T from "../../types"
import { validateEmail, validateUrl } from "../../Validators"
import { industries, noOfEmployees } from "./OnBoardingData"

export type tOnSubmit = (data: any) => void

interface IOnboardingForm {
  height: number
  width: number
  signupData?: T.ISignupData
  onSubmit: (data: any, callback: any) => void
  inviteUser: (
    inviteUser: {
      email: string
      firstName: string
      lastName: string
      jobTitle: string
      hasSeenOrgGoals: boolean
    },
    callback?: any,
  ) => void
  goToIntro: () => void
}

interface IOnboardingFormState {
  firstName: string
  lastName: string
  jobTitle: string
  companyName: string
  primaryIndustry: string
  numberOfEmployees: string
  webAddress: string
  inviteUser: {
    email: string
    firstName: string
    lastName: string
    jobTitle: string
  }
  webAppUrlError: boolean
  inviteEmailError: boolean
  showWelcomePage: boolean
  loading: boolean
  pageView: number
}

type tCompanyProfile = {
  companyName: string
  industry: string
  numberOfEmployees: string
  companyUrl: string
  invitedBy?: {
    agencyUuid: string
    agencyName: string
    cognitoId: string
    lastName: string
    firstName: string
  }
  createdOn?: string
  isDummyOrg?: boolean
}

type tAdminProfile = {
  firstName: string
  lastName: string
  email: string
  jobTitle: string
  hasSeenOrgGoals: boolean
}

class OnBoardingForm extends React.Component<
  IOnboardingForm,
  IOnboardingFormState
> {
  public readonly state: IOnboardingFormState = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    primaryIndustry: "",
    numberOfEmployees: "",
    webAddress: "",
    inviteUser: {
      email: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
    },
    webAppUrlError: false,
    inviteEmailError: false,
    showWelcomePage: true,
    loading: false,
    pageView: 1,
  }

  componentDidMount() {
    C.heapTrack("Viewed Onboard Form")
  }

  componentDidUpdate(prevProps: IOnboardingForm) {
    if (this.props.signupData !== prevProps.signupData) {
      this.setState({
        companyName: this.props.signupData?.companyName || "",
        firstName: this.props.signupData?.firstName || "",
        lastName: this.props.signupData?.lastName || "",
      })
    }
  }

  clearState() {
    this.setState({
      firstName: "",
      lastName: "",
      jobTitle: "",
      companyName: "",
      primaryIndustry: "",
      numberOfEmployees: "",
      webAddress: "",
      inviteUser: {
        email: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
      },
      webAppUrlError: false,
      inviteEmailError: false,
      showWelcomePage: true,
    })
  }

  onFirstNameChange(event: any) {
    const value = event.target.value
    this.setState({ firstName: value })
  }

  onLastNameChange(event: any) {
    const value = event.target.value
    this.setState({ lastName: value })
  }

  onCompanyNameChange(event: any) {
    const value = event.target.value
    this.setState({ companyName: value })
  }

  onWebAddressChange(event: any) {
    const value = event.target.value
    this.setState({ webAddress: value })
  }

  onIndustrySelect(item: string[]) {
    this.setState({ primaryIndustry: item[1] || item[0] })
  }

  onNumberOfEmployeeSelect(item: string[]) {
    this.setState({ numberOfEmployees: item[1] || item[0] })
  }

  onInviteUserEmailChange(event: any) {
    const value = event.target.value
    this.setState({ inviteUser: { ...this.state.inviteUser, email: value } })
  }

  onInviteUserFirstNameChange(event: any) {
    const value = event.target.value
    this.setState({
      inviteUser: { ...this.state.inviteUser, firstName: value },
    })
  }

  onInviteUserLastNameChange(event: any) {
    const value = event.target.value
    this.setState({
      inviteUser: { ...this.state.inviteUser, lastName: value },
    })
  }

  onInviteUserJobTitleChange(event: any) {
    const value = event.target.value
    this.setState({
      inviteUser: { ...this.state.inviteUser, jobTitle: value },
    })
  }

  onHideWelcomePage() {
    this.setState({ showWelcomePage: false })
  }

  onJobTitleChange(event: any) {
    const value = event.target.value
    this.setState({ jobTitle: value })
  }

  getCompanyProfile() {
    const companyProfile: tCompanyProfile = {
      companyName: this.state.companyName.trim(),
      industry: this.state.primaryIndustry.trim(),
      numberOfEmployees: this.state.numberOfEmployees.trim(),
      companyUrl: this.state.webAddress.trim(),
    }
    return companyProfile
  }

  getAdminProfile() {
    const adminProfile: tAdminProfile = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      email: "",
      jobTitle: this.state.jobTitle.trim(),
      hasSeenOrgGoals: false,
    }
    return adminProfile
  }

  validateFormUrl = (url: string) => {
    url = _.isEmpty(url) ? "" : url?.trim()
    url = url.replace(new RegExp("^https?://"), "")
    if (!url.length || validateUrl(url)) {
      this.setState({ webAddress: url, webAppUrlError: false })
      return ""
    } else {
      this.setState({ webAddress: url, webAppUrlError: true })
      return "Please enter a valid URL."
    }
  }

  validateInviteEmail = (email: string) => {
    if (!email || validateEmail(email)) {
      this.setState({ inviteEmailError: false })
      return ""
    } else {
      this.setState({ inviteEmailError: true })
      return "Please enter a valid email."
    }
  }

  onSubmit(isInviteUser?: boolean) {
    this.setState({ loading: true })
    if (
      !(
        this.state.firstName.trim() &&
        this.state.lastName.trim() &&
        this.state.companyName.trim() &&
        this.state.primaryIndustry.trim() &&
        this.state.numberOfEmployees.trim()
      )
    ) {
      C.toastNotification(
        "Incomplete Form. Please complete and submit again.",
        "danger",
      )
    } else {
      this.props.onSubmit(
        {
          companyProfile: this.getCompanyProfile(),
          adminProfile: this.getAdminProfile(),
          linkId: this.props.signupData?.linkId,
        },
        (success: boolean) => {
          if (success) {
            this.setState({ loading: false })
            this.props.goToIntro()
          } else {
            this.setState({ loading: false, pageView: 1 })
          }
          if (isInviteUser) {
            const { email, firstName, lastName, jobTitle } =
              this.state.inviteUser
            this.props.inviteUser({
              email: email.trim(),
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              jobTitle: jobTitle.trim(),
              hasSeenOrgGoals: false,
            })
          }
        }, // TODO: HANDLE CALLBACK
      )
    }
  }
  onChangePage(pageNumber: number) {
    this.setState({ pageView: pageNumber })
  }

  getWelcomePage() {
    const pContent = {
      height: this.props.height,
    }
    return (
      <Content {...pContent}>
        <PanelContainer height={this.props.height}>
          <Panel>
            <ModalImg src="images/intro/icon_cloud_lock_shield.svg" />
            <Heading align="center">Welcome to Cyber Safety.</Heading>
            <Row fontSize="16px" marginTop="0px">
              Let’s get you set up. This will only take a minute.
            </Row>
            <ButtonRow>
              <ButtonSmall
                onClick={() => {
                  this.onHideWelcomePage()
                }}
                // width={368}
                height={21}
                id="onboarding-continue"
              >
                Continue
              </ButtonSmall>
            </ButtonRow>
          </Panel>
        </PanelContainer>
      </Content>
    )
  }

  getFirstPage() {
    return (
      <React.Fragment>
        <Heading align="center">Tell us about your organization.</Heading>
        <ModalImg src="images/onboarding/icon_building.svg" />
        <FormElement>
          <FloatingLabelInput
            label="Organization name"
            id="onboarding-company-name"
            type="text"
            value={this.state.companyName}
            onChange={this.onCompanyNameChange.bind(this)}
            onValidate={(url) =>
              url.length > 0 ? "" : "Please enter the organization name."
            }
          />
          <Row>
            <Dropdown
              items={industries}
              id="onboarding-primary-industry"
              placeholder={"Select your primary industry"}
              onSelectItem={this.onIndustrySelect.bind(this)}
              height={"37px"}
            />
          </Row>
          <Row>
            <Dropdown
              items={noOfEmployees}
              id="onboarding-number-of-team-members"
              placeholder={"Select your number of team members"}
              onSelectItem={this.onNumberOfEmployeeSelect.bind(this)}
              height={"37px"}
            />
            <SubText>
              Include all full-time and part-time employees, contractors,
              and&nbsp;consultants.
            </SubText>
          </Row>
          <FloatingLabelInput
            label="Primary website address"
            id="onboarding-primary-website-address"
            type="text"
            value={this.state.webAddress}
            onChange={this.onWebAddressChange.bind(this)}
            onValidate={(url) => this.validateFormUrl(url)}
          />

          <ButtonRow>
            <ButtonSmall
              onClick={() => {
                this.onChangePage(2)
              }}
              width={160}
              height={21}
              disabled={
                !(
                  this.state.companyName &&
                  this.state.numberOfEmployees &&
                  this.state.primaryIndustry &&
                  !this.state.webAppUrlError &&
                  !this.state.loading
                )
              }
              id="CyberSafety_Trial_OrgInfo_Button_page_1"
            >
              Next
            </ButtonSmall>
          </ButtonRow>
        </FormElement>
      </React.Fragment>
    )
  }

  getSecondPage() {
    return (
      <React.Fragment>
        <Heading align="center">Tell us a bit about yourself.</Heading>
        <ModalImg src="images/onboarding/icon_people.svg" />
        <FormElement>
          <FloatingLabelInput
            label="First name"
            id="onboarding-first-name"
            type="text"
            value={this.state.firstName}
            onChange={this.onFirstNameChange.bind(this)}
          />
          <FloatingLabelInput
            label="Last name"
            id="onboarding-last-name"
            type="text"
            value={this.state.lastName}
            onChange={this.onLastNameChange.bind(this)}
          />
          <FloatingLabelInput
            label="Job title (Optional)"
            id="onboarding-job-title"
            type="text"
            value={this.state.jobTitle}
            onChange={this.onJobTitleChange.bind(this)}
          />
          <ButtonRow>
            <ButtonSmall
              onClick={() => {
                this.onChangePage(3)
              }}
              width={160}
              height={21}
              disabled={
                !(
                  this.state.firstName &&
                  this.state.lastName &&
                  !this.state.loading
                )
              }
              id="CyberSafety_Trial_OrgInfo_Button_page_2"
            >
              Next
            </ButtonSmall>
          </ButtonRow>
        </FormElement>
      </React.Fragment>
    )
  }

  getThirdPage() {
    return (
      <React.Fragment>
        <Heading fontSize="24px" margin="0 0 8px">
          Add another account admin.
        </Heading>
        <SubHeading
          display="flex"
          fontWeight="700"
          color={colors.gray40}
          margin="0 0 16px"
        >
          Optional&nbsp;
          <FaIcon
            data-tip
            fontSize="13px"
            data-for="invite-user-popup"
            className="fas fa-question-circle"
            margin="4px 0 0 2px"
            color={colors.gray40}
          />
          <Tooltip
            id="invite-user-popup"
            width="300px"
            content={
              <p>
                Give access to another admin to help manage your cybersecurity
                needs.
              </p>
            }
          />
        </SubHeading>
        <Section flex="flex-start" gap="12px" margin="0 0 16">
          <ModalImg src="images/onboarding/icon_add_admin.svg" margin="0" />
          <Section fontSize="16px" align="start">
            <b>Invite</b> someone from your organization to help{" "}
            <b>manage Cyber Safety</b>. They will be able to{" "}
            <b>see and administer</b> the same <b>modules</b> as you, and can{" "}
            <b>manage other users</b>.&nbsp;
            <ExternalLink
              url="https://help.zeguro.com/hc/en-us/articles/360035250913-How-do-I-invite-another-user-or-administrator-to-Cyber-Safety-"
              text="Learn more"
              margin="0"
            />
          </Section>
        </Section>
        <FormElement>
          <FloatingLabelInput
            label="Work email address"
            id="onboarding-invite-user-email"
            type="email"
            value={this.state.inviteUser.email}
            onChange={this.onInviteUserEmailChange.bind(this)}
            onValidate={(email) => this.validateInviteEmail(email)}
          />
          <FloatingLabelInput
            name=""
            label="First Name"
            id="onboarding-invite-user-first-name"
            type="text"
            value={this.state.inviteUser.firstName}
            onChange={this.onInviteUserFirstNameChange.bind(this)}
          />
          <FloatingLabelInput
            label="Last Name"
            id="onboarding-invite-user-last-name"
            type="text"
            value={this.state.inviteUser.lastName}
            onChange={this.onInviteUserLastNameChange.bind(this)}
          />
          <FloatingLabelInput
            label="Job Title"
            id="onboarding-invite-user-job-title"
            type="text"
            value={this.state.inviteUser.jobTitle}
            onChange={this.onInviteUserJobTitleChange.bind(this)}
          />
          <ButtonsRow>
            <ButtonSecondary
              onClick={() => {
                this.onSubmit()
                Cookies.remove("redirectURL")
              }}
              width={100}
              height={21}
              disabled={this.state.loading}
              id="CyberSafety_Trial_OrgInfo_Button_1_page_3"
            >
              Skip
            </ButtonSecondary>
            <ButtonSmall
              onClick={() => {
                this.onSubmit(true)
              }}
              width={150}
              height={21}
              disabled={
                !this.state.inviteUser.email ||
                this.state.inviteEmailError ||
                this.state.loading
              }
              id="CyberSafety_Trial_OrgInfo_Button_2_page_3"
            >
              Invite Admin
            </ButtonSmall>
          </ButtonsRow>
        </FormElement>
      </React.Fragment>
    )
  }

  render() {
    const pContent = {
      height: this.props.height,
    }
    return this.state.showWelcomePage ? (
      this.getWelcomePage()
    ) : (
      <Content {...pContent}>
        <PanelContainer height={this.props.height}>
          <Panel marginTop="80" width="600px">
            {this.state.pageView === 1
              ? this.getFirstPage()
              : this.state.pageView === 2
              ? this.getSecondPage()
              : this.getThirdPage()}
          </Panel>
        </PanelContainer>
      </Content>
    )
  }
}

const SubText = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  color: #777777;
  padding: 4px 0px;
  text-align: left;
`

const Row = styled.div<any>`
  font-family: proxima-nova, sans-serif;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : "20px")};
  color: #212528;
  margin-top: ${(props: any) => (props.marginTop ? props.marginTop : "18px")};
  text-align: center;
  width: 100%;
`

const ButtonRow = styled.div<any>`
  display: ${(props: any) => (props.hidden ? "none" : "block")};
  text-align: right;
  margin-top: 30px;
  margin-bottom: 20px;
`

const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 22px;
  margin-top: 16px;
`

const ModalImg = styled.img<{ margin?: string }>`
  height: 80px;
  margin: ${({ margin }) => margin || "15px"};
`

export default OnBoardingForm
