import {
  CyberConciergeConfig as ICyberConciergeConfig,
  CyberConciergeMessageJson as ICyberConciergeMessageJson,
} from "@zeguro/schema-validator/dist/types/coco/cyberConcierge/combined"
import {
  CyberMaturityCatalog as ICyberMaturityCatalog,
  CyberMaturityOrgData as ICyberMaturityOrgData,
} from "@zeguro/schema-validator/dist/types/coco/cyberMaturity/combined"
import {
  ChannelObject,
  Coupon,
  EligiblePlan,
  ModuleStatus,
  SaasPlan,
  SubscriptionObject,
} from "@zeguro/schema-validator/dist/types/coco/saasPlan/combined"
import {
  CourseResults as ICourseResults,
  TraineeData as ITrainee,
} from "@zeguro/schema-validator/dist/types/coco/training/combined"
export type tDictAny = { [key: string]: any }
export type tDictBool = { [key: string]: boolean }
export type tDictStr = { [key: string]: string }
export type tVoidFun = () => void

export type tCompanyProfile = {
  address?: {
    city?: string
    state?: string
    zipCode?: string
    streetAddress?: string
  }
  industry?: string
  companyUrl: string
  companyName: string
  annualRevenue?: number
  hasPersonalData?: boolean
  hasMedicalRecords?: boolean
  numberOfEmployees?: string
  hasSdlcAndQaPolicy?: boolean
  complianceStandards?: string[]
  hasDataSecurityPolicy?: boolean
  securityPersonnelType?: string
  hasAcceptableUsePolicy?: boolean
  hasCreditDebitCardData?: boolean
  hasBackupRetentionPolicy?: boolean
  hasNetworkSecurityPolicy?: boolean
  hasIncidentResponsePolicy?: boolean
  hasThirdPartySecurityPolicy?: boolean
  numberOfPersonalDataRecords?: number
  hasRiskAssessmentManagementPolicy?: boolean
  cloudStoragePlatforms?: []
}

export type tUserProfile = {
  firstName?: string
  lastName?: string
  email?: string
  jobTitle?: string
  cognitoId?: string
  orgId?: string
  orgGoals?: any
  hasSeenOrgGoals?: boolean
}

export type tUserList = tUserProfile[]

export type tGlobalVar = any

interface ReCaptchaInstance {
  ready: (cb: () => any) => any
  execute: (sitekey: string, action: { action: string }) => Promise<string>
  render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: "invisible"
}

export enum ViewTypes {
  compliance = "/securitypolicy",
  monitoring = "/webappscan",
  attacksurface = "/attacksurface",
  zimm = "/cybermaturity",
  training = "/training",
  marketplace = "/marketplace",
  insurance = "/insurance",
  settings = "/settings",
  plan = "/plan",
  signin = "/signin",
  dashboard = "/dashboard",
  intro = "/intro",
  cyberConcierge = "/concierge",
}

export type tExtWindow = {
  captchaOnLoad?: () => void
  dataLayer?: tGlobalVar
  grecaptcha?: ReCaptchaInstance
  customize?: tGlobalVar
  heap?: tGlobalVar
  timeoutNotification?: tGlobalVar
  showUpgradeModal?: tGlobalVar
  toggleSubscribeModal?: tGlobalVar
  showSupportModal?: tGlobalVar
  securityPolicyConfig?: tGlobalVar
  LogRocket?: tGlobalVar
  Chargebee?: tGlobalVar
}

export type tUserPool = {
  ClientId: string
  UserPoolId: string
}

export type tConfig = {
  stripeApiKey: string
  environmentName: string
  sessionInactivityTimeout: number
  securityPolicyConfig: tDictAny
  chargebeeSite: string
}

export type tLog = {
  level: string
  message: string
  endpoint?: string
  filename?: string
  linenumber?: string
  stack?: any
  payload?: any
}

export interface IReport {
  header: string[]
  scores: string[]
}

export type tTrainingMode = "reportCard" | "manageTrainee"

export interface IEmployeeInfo {
  firstName: string
  lastName: string
  email: string
  userId: string
}

export type IProbelyTarget = {
  url: string
  targetId: string
  name: string
  scan_profile: string
}

export interface IProbelyScan {
  scanId: string
  targetId: string
  createTime: Date
  startTime: Date
  completeTime: Date
  lows: number
  mediums: number
  highs: number
  status: string
  probelyScanId: string
  scanProfile: string
}

export interface IProbelySchedule {
  targetId: string
  scheduledScanId: string
  recurrence: string
  dateTime: Date
  scheduledDayOfWeek: number
  weekIndex: string
  runOnDayOfWeek: boolean
}

export type tSubscriptionStatus = SubscriptionObject["subscriptionStatus"]
export type tProbelyScanFrequency = "DAILY" | "WEEKLY" | "MONTHLY" | "QUARTERLY"
export type tProbelyScanProfile =
  | "SAFE"
  | "NORMAL"
  | "FULL"
  | "LIGHTNING"
  | "LOG4SHELL"
  | "SANDBOX_LIGHTNING"
  | "SANDBOX_NORMAL"
export type tPlanCount = {
  maximumAllowed: number
  currentUsage: number
  remaining: number
} | null

export interface tPlansRequestState {
  subscribedPlan: SaasPlan
  training: ModuleStatus
  trainingNumberOfSeats: tPlanCount
  insurance: ModuleStatus
  securityPolicies: ModuleStatus
  securityPoliciesSignOff: ModuleStatus
  probely: ModuleStatus
  probelyNumberOfTargets: tPlanCount
  probelyScanFrequency: tProbelyScanFrequency[] | null
  probelyScanProfile: tProbelyScanProfile[] | null
  subscriptionStatus: tSubscriptionStatus
  subscriptionDate: string
  expirationDate: string
  channelObject: IChannelObject
  signUpDate: string
  modules: tModules
  isHighestPlan?: boolean
}

export interface tModules {
  cybermaturity: ModuleStatus
  compliance: ModuleStatus
  training: ModuleStatus
  monitoring: ModuleStatus
  insurance: ModuleStatus
  dashboard: ModuleStatus
  marketplace: ModuleStatus
  ratings: ModuleStatus
  settings?: ModuleStatus
  subscription?: ModuleStatus
  cyberconcierge: "ACTIVE"
}

export interface IChannelObject {
  uiRules?: tChannelUiRules
  eligiblePlans: IEligiblePlan[]
}

export type tChannelUiRules = ChannelObject["uiRules"]

export interface IEligiblePlan extends EligiblePlan {
  planDetail?: SaasPlan
  planPrice?: number
}

export interface tPlanPonter {
  appId: { planId: string }
  doctype: string[]
}

export interface IChargebeeCoupon extends Coupon {
  discountType?: string
  discountAmount?: number
  discountPercentage?: number
}

export interface tPlansState extends tPlansRequestState {
  status: tStatus
  eligiblePlansStatus: tStatus
  updatePlansStatus: tStatus
  chargebeeSubscriptionStatus: boolean
}

export interface tMarketplaceState {
  status: tStatus
  data: tDictAny
}

export interface IRequestStatus {
  target: string | null
  status: tStatus
  error?: string
}

export type tStatus = "READY" | "FAILED" | "SUCCESS" | "LOADING"

export type tMagicLinkSignupEventType =
  | "Unsuccessful Access"
  | "Successful Access"
  | "Login Created"

export interface tMagicLinkSignupFetchResponse {
  createdFor?: string
  expirationDate?: string
  status?: "accepted" | "pending" | "userExists"
  updateStatus?: tStatus
  fetchStatus?: tStatus
  signupStatus?: tStatus
  updateError?: string
  fetchError?: string
  signupError?: Error
}

export interface IGrade {
  name: string
  grade: string
  subject: string
  icon?: string
}

export interface ICourse {
  name: string
  grade?: string
  subject?: string
  icon?: string
  topics?: IGrade[]
  isFinished?: boolean
  isEnrolled?: boolean
  courseId: string
}

export interface IScoreBarChartData {
  score:
    | "0-10"
    | "11-20"
    | "21-30"
    | "31-40"
    | "41-50"
    | "51-60"
    | "61-70"
    | "71-80"
    | "81-90"
    | "91-100"
  courseResults: number
}

export interface IParticipationPieChartData {
  id: "Incomplete" | "Finished" | "Not Yet Started"
  label: "Incomplete" | "Finished" | "Not Yet Started"
  value: number
}

export interface ITraineeEditCourses
  extends Pick<ITrainee, "firstName" | "lastName" | "email" | "traineeId"> {
  enrollInGap?: boolean
  enrolledCourses: Array<string | undefined>
  unEnrolledCourses: Array<string | undefined>
  courseResults?: ICourseResults[]
}
export interface ITrainingEditData {
  inactive?: boolean
  email: string
  lastName: string
  firstName: string
  traineeId: string
}
export interface ISaveTraineeData {
  email: string
  firstName: string
  lastName: string
  noGapEnrollment?: boolean
}

export interface ITopic {
  name: string
  id: string
  subject: string
  difficulty: number
  category?: string
}
export interface ITopicCourseResults {
  name: string
  id: string
  subject: string
  difficulty: number
  category?: string
  enrollDate?: string
  finishDate?: string
  grade?: string
  score?: number | undefined | null
}
export interface ITraineeReport {
  startDate?: string
  enrollDate?: string
  finishDate?: string
  totalScore?: number
  averageScore?: number
  totalRetries?: number
  averageRetries?: number
  totalCourseResults?: number
  totalCoursesStarted?: number
  averageTimeFromStart?: string
  totalCoursesFinished?: number
  totalMinutesFromStart?: number
  averageTimeFromEnrollment?: string
  totalMinutesFromEnrollment?: number
}

export interface IGoToTraineeTab {
  name: string
  email: string
  traineeId: string
  courseName: string
}

export type TCollapse = {
  [key in string]: boolean
}

export interface IRatingsData {
  rating_details: any
}

export type ITabsContext = {
  setScrollTo: (arg: string) => void
  scrollTo: string
  selectedTab: "overview" | "details" | "facts"
  setSelectedTab: (key: "overview" | "details" | "facts") => void
}

export interface IIndustryGrade {
  industry: string
  sub_industry: string
  average_rating: number
  rating_date: string
  risk_vector_averages: IRiskVectorAverage[]
}

export interface IRiskVectorAverage {
  average_grade: string
  risk_vector: string
  risk_vector_id: string
}

export interface tDashboard {
  compliance?: IComplianceDashboardData
  training?: ITrainingDashboardData
  monitoring?: IMonitoringDashboardData
  zimm?: IZimmDashboardData
  status: tStatus
  monitoringStatus?: tStatus
  cyberConcierge?: ICyberConciergeData
}
export interface IDashboardMonitoring {
  data?: IMonitoringDashboardData
  status: tStatus
}

export interface IComplianceDashboardData {
  draft: number
  active: number
  inactive: number
  lastUpdated: Date | null
}
export interface ITrainingDashboardData {
  isFinished: number
  isNotFinished: number
  notEnrolled: number
  teamMembers: number
  lastUpdated: Date
}
export interface IMonitoringDashboardData {
  webAppsScanned: number
  scansScheduled: number
  isScanInProgress: boolean
  lastScanDate?: Date
  webAppsIssues: IWebAppsIssues
  targetList: ITargetList[]
}
export interface ICyberConciergeData {
  numberOfAlerts: number
}
export interface ILevelInfo {
  levelName: string
  levelDescriptor: string
  description: string
}
export interface IZimmDashboardData {
  levelInfo: ILevelInfo
  overallLevel: number
  assessmentStatus: string
  lastUpdated: Date
  nextQuestion?: string
}
export interface IWebAppsIssues {
  lows: number
  mediums: number
  highs: number
}
export interface ITargetList {
  url: string
  targetId: string
  name: string
  scan_profile: string
}

export interface IMaintenance {
  enabled: boolean
  title: string
  subHead: string
  content?: string
}

export interface ISignupData {
  linkId: string
  firstName: string
  lastName: string
  companyName: string
}

export interface IRatingsData {
  rating_details: any
}

export type tRatingsTabs = "overview" | "details" | "facts" | "reports"

export interface IFastRatings {
  data: IFastRatingsData
  pdf: string
  error: string
}

export interface IFastRatingsData {
  rating_details: IRatingDetailsData
  ratings: IRating[]
  industry_grade: IIndustryGrade
  quota_details: IQuotaDetails
  pdf_url: string
}

export interface IQuotaDetails {
  quota_purchased: number
  quota_used: number
  quota_available: number
}

export interface IIndustryGrade {
  industry: string
  sub_industry: string
  average_rating: number
  rating_date: string
  risk_vector_averages: IRiskVectorAverage[]
}

export interface IRiskVectorAverage {
  average_grade: string
  risk_vector: string
  risk_vector_id: string
}

export interface IRating {
  rating_date: string
  rating_color: string
  rating: number
  range: string
}

export interface IRatingDetailsData {
  [key: string]: IRatingDetails
}

export interface IRatingDetails {
  percentile?: string
  info?: boolean
  grade: string
  name: string
  rating: string
  category: string
  beta: boolean
  grade_color: string
  category_order: number
  order: number
  id?: string
}
export interface IBasicState {
  error: Error | undefined
  isLoading: boolean
  message?: string
  success?: boolean
}

export interface IGetAssessmentData extends IBasicState {
  data:
    | (ICyberMaturityOrgData & {
        levelInfo: { [key: string]: ILevelInfo }
        lastUpdated: string
      })
    | null
  additionalLoading: boolean
  nextQuestion: { elementPath: string; questionId: string } | null
}
export interface IGetConfig extends IBasicState {
  elements: ICyberMaturityCatalog["elements"]
  glossary: ICyberMaturityCatalog["glossary"]
  interstitials: ICyberMaturityCatalog["interstitials"]
}

export interface IGetCyberConciergeConfig extends IBasicState {
  cyberConciergePage: ICyberConciergeConfig["cyberConciergePage"]
}
export interface IGetCyberConciergeAlert extends IBasicState {
  data: ICyberConciergeMessageJson | null
}

export interface ICyberConciergeAlert
  extends Pick<
    ICyberConciergeMessageJson,
    | "messageTitle"
    | "messageDate"
    | "updatedOn"
    | "messageDescription"
    | "messageSeverity"
    | "messageStatus"
  > {
  conciergeMessageId: string
}

export interface IGetCyberConciergeAlerts extends IBasicState {
  alerts: ICyberConciergeAlert[]
}
