import { ProgressBar } from "@blueprintjs/core"
import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSmall } from "@src/Components/Button"
import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import { IconButton, IconGroup, Vr } from "@src/Components/PageHeader/styles"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { Panel } from "@src/Components/View"
import { tState } from "@src/Model/Model"
import { HELP_CENTER_URL } from "@src/Portal/Zimm/constants"
import { colors } from "@src/theme"
import { ILevelInfo } from "@src/types"
import { calculatePercentage } from "@src/Utils"
import * as moment from "moment"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import Level from "../../Level"
import {
  ButtonContainer,
  ProgressBarContainer,
  ProgressDetails,
  ProgressInfo,
  SubtleText,
  UpdatedText,
} from "../styles"
import {
  CompanyName,
  HeadLevelDescrText,
  IconsContainer,
  SectionStyled,
  TopSection,
} from "./styles"

interface IReportHeader {
  companyName: string
  assessmentLink: string
  overallLevel?: number
  levelInfo?: ILevelInfo | undefined
  assessmentStatus?: string
  lastUpdate?: string
}

const ReportHeader = ({
  companyName,
  assessmentLink,
  overallLevel,
  levelInfo,
  assessmentStatus,
  lastUpdate,
}: IReportHeader) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const answeredQuestions =
    useSelector((store: tState) => store.zimm.getAssessmentData.data)?.response
      .questions.length || 0
  const config = useSelector(
    (store: tState) => store.zimm.getConfig.elements,
  ).length
  const progress = calculatePercentage(answeredQuestions, config)

  return (
    <Panel margin="0px 0px 32px 0px" borderColor={colors.green30} isTopBorder>
      <SectionStyled flex="center center column">
        <TopSection>
          <CompanyName>
            <img height="40px" src="images/zimm/icon_cyber_maturity_lg.svg" />
            &nbsp;&nbsp;
            {companyName}’s Cyber Maturity Level So Far
          </CompanyName>
          <IconsContainer>
            <IconGroup>
              <Tooltip id="view-intro-tooltip" content={<p>View Intro</p>} />
              <IconButton
                data-tip
                data-for="view-intro-tooltip"
                src="images/icon_view_intro.svg"
                onClick={() =>
                  dispatch(GLOBAL_MODALS.changeIntroModal("cybermaturity"))
                }
              />
              <Vr />
              <Tooltip id="help-center-tooltip" content={<p>Help Center</p>} />
              <IconButton
                data-tip
                data-for="help-center-tooltip"
                src="images/icon_knowledge_base_info_help.svg"
                onClick={(e: any) => {
                  e.stopPropagation()
                  window.open(HELP_CENTER_URL)
                }}
              />
            </IconGroup>
          </IconsContainer>
        </TopSection>
        <Level
          level={overallLevel}
          large
          margin="0 0 16px"
          dataFor="head-level-tooltip"
        />
        <Section
          fontSize="21px"
          fontWeight="600"
          margin="0 0 16"
          align="center"
          fontFamily="montserrat"
        >
          {levelInfo?.levelName}
        </Section>
        {levelInfo?.description && (
          <Tooltip
            id="head-level-tooltip"
            width="405px"
            content={
              <>
                <Section margin="0 0 10 0" fontWeight="700" fontSize="21px">
                  Level {overallLevel}
                </Section>
                <HeadLevelDescrText>{levelInfo.description}</HeadLevelDescrText>
              </>
            }
          />
        )}
        {assessmentStatus === "notCompleted" ? (
          <>
            <ButtonContainer>
              <ButtonSmall
                fontSize="20px"
                onClick={() => history.push(assessmentLink)}
              >
                <FaIcon
                  className="fas fa-money-check-edit"
                  margin="0 6px 0 0"
                />
                Finish Assessment
              </ButtonSmall>
            </ButtonContainer>
            <ProgressDetails>
              <ProgressInfo>
                <SubtleText>Questions Answered</SubtleText>
                <UpdatedText>
                  <div>Last updated</div>
                  <div>{moment(lastUpdate).format("MMM D, YYYY")}</div>
                </UpdatedText>
                <SubtleText align="end">{`${progress}%`}</SubtleText>
              </ProgressInfo>
              <ProgressBarContainer>
                <ProgressBar
                  value={progress / 100}
                  className="bp5-intent-primary bp5-no-stripes"
                />
              </ProgressBarContainer>
            </ProgressDetails>
          </>
        ) : (
          <>
            <Section flex="center center column" fontSize="18px">
              <div>
                Elements with the <b>lowest levels</b> are your{" "}
                <b>weakest points</b>.
              </div>
              <div>
                <b>Address these</b> first to improve your overall{" "}
                <b>Cyber Maturity Level.</b>&nbsp;
                <ExternalLink
                  text="More"
                  margin="0px"
                  url="https://zeguro.zendesk.com/hc/en-us/articles/7793335742487-Cyber-Maturity-Model-and-Levels"
                />
              </div>
            </Section>
            <Section
              fontSize="16px"
              fontColor={colors.gray40}
              margin="16 0 0"
              align="center"
            >
              Updated {moment(lastUpdate).format("MMM D, YYYY")}
            </Section>
          </>
        )}
      </SectionStyled>
    </Panel>
  )
}

export default ReportHeader
