import { actions as GLOBAL_MODALS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import ExternalLink from "@src/Components/ExternalLink"
import PageHeaderCard from "@src/Components/PageHeaderCard"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { HELP_CENTER_URL } from "@src/Portal/Zimm/constants"
import { colors } from "@src/theme"
import { ILevelInfo, ViewTypes } from "@src/types"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { textToPath } from "../utils"
import ReportHeader from "./ReportHeader"
import {
  CardDescription,
  ElementInfoModalStyled,
  HeadingStatus,
  HeadingWrapper,
  LinkStyled,
  LinkText,
} from "./styles"

const Heading = () => (
  <HeadingWrapper>
    Cyber Maturity<HeadingStatus>Beta</HeadingStatus>
  </HeadingWrapper>
)

export const HeaderCard = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = history.location.pathname.split("/")
  const isIntro = params[2] === "assessment" && !params[3]
  const isReport = params[2] === "report"
  const { isDesktop } = usePortalSize()
  const [isElementsInfoModal, setIsElementsInfoModal] = React.useState(false)
  const assessmentData = useSelector(
    (store: tState) => store.zimm.getAssessmentData.data,
  )
  const portal = useSelector((store: tState) => store.portal)
  const assessmentStatus = assessmentData?.assessment.assessmentStatus
  const lastUpdate = assessmentData?.lastUpdated
  const assessmentElementPath = useSelector(
    (store: tState) => store.zimm.getAssessmentData.nextQuestion?.elementPath,
  )
  const assessmentLink = `${ViewTypes.zimm}/assessment/${textToPath(
    assessmentElementPath,
  )}`

  const getLevelInfo = (level?: number): ILevelInfo | undefined => {
    return String(level) ? assessmentData?.levelInfo[String(level)] : undefined
  }

  const renderCard = React.useMemo(() => {
    if (isIntro) {
      return (
        <PageHeaderCard
          heading={<Heading />}
          id="cyber-maturity-empty-state-heading"
          showViewIntro={isDesktop}
          showHelpCenter={isDesktop}
          onClickViewIntro={() =>
            dispatch(GLOBAL_MODALS.changeIntroModal("cybermaturity"))
          }
          helpCenterLinkUrl={HELP_CENTER_URL}
          cardIconUrl="images/zimm/icon_cyber_maturity_lg.svg"
          color={colors.darkBlue}
          maxHeight={100}
          bodyWidth="100%"
          contentWidth="100%"
          borderColor={colors.violet}
          cardBody={
            <Section flex="flex-start">
              <CardDescription>
                Cyber Maturity Level helps you <b>determine the strength</b> of
                your <b>organization’s cybersecurity</b>. Complete the{" "}
                <b>guided assessment</b> to see your levels for each{" "}
                <LinkStyled onClick={() => setIsElementsInfoModal(true)}>
                  Element
                </LinkStyled>
                . We’ll identify opportunities for <b>improvement</b>, and
                tactics to <b>remedy issues</b> we uncover.{" "}
                <ExternalLink
                  text="Learn more about Cyber Maturity"
                  margin="0px 40px 0px 0px"
                  url="https://zeguro.zendesk.com/hc/en-us/articles/7793335742487-Cyber-Maturity-Model-and-Levels"
                />
              </CardDescription>
            </Section>
          }
        />
      )
    }
    if (isReport) {
      return (
        <ReportHeader
          companyName={portal.companyProfile.companyName}
          overallLevel={assessmentData?.assessment.overallLevel}
          levelInfo={getLevelInfo(assessmentData?.assessment.overallLevel)}
          assessmentStatus={assessmentStatus}
          lastUpdate={lastUpdate}
          assessmentLink={assessmentLink}
        />
      )
    }
  }, [history.location.pathname, assessmentData, isDesktop])

  return (
    <>
      {isDesktop && renderCard}
      {isElementsInfoModal && (
        <ConfirmationModal
          isOpen={isElementsInfoModal}
          infoOnly
          title="Elements"
          confirmButton="Got It"
          contentMargin="0 0 30px"
          linkText={
            <LinkText>
              Learn more about Elements{" "}
              <i className="far fa-external-link-square" />
            </LinkText>
          }
          confirmButtonWidth={94}
          onClose={() => setIsElementsInfoModal(false)}
          onConfirm={() => setIsElementsInfoModal(false)}
        >
          <ElementInfoModalStyled>
            Your organization’s security stance can be broken down into the
            following&nbsp;
            <b>Security Areas:</b>
            <ul>
              <li>People</li>
              <li>Process</li>
              <li>Technology</li>
            </ul>
            Each Security Area has a number of touchpoints, or <b>Elements</b>
            ,&nbsp; that can be addressed to improve your security. For
            example,&nbsp;
            <b>Security Awareness & Training Program</b> is an <b>Element</b>
            &nbsp; that falls under the <b>People</b> Security Area.
          </ElementInfoModalStyled>
        </ConfirmationModal>
      )}
    </>
  )
}
