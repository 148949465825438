import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension"
import marketplaceReducer from "@src/Actions/Reducers/MarketplaceReducer"
import { reducer as monitoringReducer } from "@src/Actions/Reducers/MonitoringReducer"
import plansReducer from "@src/Actions/Reducers/PlansReducer"
import policyReducer from "@src/Actions/Reducers/PolicyReducer"
import portalReducer from "@src/Actions/Reducers/PortalReducer"
import ratingsReducer from "@src/Actions/Reducers/RatingsReducer"
import trainingReducer from "@src/Actions/Reducers/TrainingReducer"
import Chargebee from "@src/ControllerModules/Chargebee"
import Cognito from "@src/ControllerModules/Cognito"
import Marketplace from "@src/ControllerModules/Marketplace"
import Monitoring from "@src/ControllerModules/Monitoring"
import Plans from "@src/ControllerModules/Plans"
import Portal from "@src/ControllerModules/Portal"
import Ratings from "@src/ControllerModules/Ratings"
import Recaptcha from "@src/ControllerModules/Recaptcha"
import Training from "@src/ControllerModules/Training"
import initialState from "@src/Model/Model"
import * as T from "@src/types"
import * as _ from "lodash"
import { toast } from "react-toastify"
import { applyMiddleware, combineReducers, createStore } from "redux"
import createSagaMiddleware from "redux-saga"

import * as PORTAL from "./Actions/PortalActions"
import { reducer as CustomerPortalDataReducer } from "./Actions/Reducers/CustomerPortalDataReducer"
import { reducer as CyberConciergeReducer } from "./Actions/Reducers/CyberConciergeReducer"
import { reducer as GlobalModalsReducer } from "./Actions/Reducers/GlobalModalsReducer"
import SubscriptionIdReducer from "./Actions/Reducers/SubscriptionIdReducer"
import { reducer as ZimmReducer } from "./Actions/Reducers/ZimmReducer"
import { serverLog } from "./ControllerModules/Logging"
import * as Middletier from "./ControllerModules/Middletier"
import LogRocket = require("logrocket")

import AccountsReducer from "./Actions/Reducers/AccountsReducer"

const reducer = combineReducers({
  portal: portalReducer,
  policy: policyReducer,
  training: trainingReducer,
  ratings: ratingsReducer,
  plans: plansReducer,
  monitoring: monitoringReducer,
  marketplace: marketplaceReducer,
  subscriptionId: SubscriptionIdReducer,
  zimm: ZimmReducer,
  globalModals: GlobalModalsReducer,
  customerPortalData: CustomerPortalDataReducer,
  account: AccountsReducer,
  cyberConcierge: CyberConciergeReducer,
})

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware()

// mount it on the Store
export const store = createStore(
  reducer,
  initialState,
  composeWithDevToolsDevelopmentOnly(
    applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()),
  ),
)
//TODO check if composeWithDevTools working for production, should not

class Controller {
  public window: T.tExtWindow & Window = window

  public Monitoring = new Monitoring(store)
  public Plans = new Plans(store)
  public Marketplace = new Marketplace(store)
  public Recaptcha = new Recaptcha(store)
  public Training = new Training(store)
  public Ratings = new Ratings(store)
  public Portal = new Portal(store)
  public Middletier = Middletier
  public serverLog = serverLog
  public Cognito = new Cognito(store)
  public Chargebee = new Chargebee(store)

  heapIdentify(username: string, orgId?: string, orgName?: string) {
    if (this.window.heap) {
      this.window.heap.identify(username)
    }
    if (this.window.heap && orgId) {
      this.window.heap.addUserProperties({ "Org Id": orgId })
    }
    if (this.window.heap && orgName) {
      this.window.heap.addUserProperties({ "Org Name": orgName })
    }
  }

  partnerStackIdentify(username: string, callback?: any) {
    growsumo.data.customer_key = username
    growsumo.data.email = username
    console.log(growsumo.data)
    growsumo.createSignup(callback)
  }

  heapTrack(eventName: string, payload?: any) {
    if (this.window.heap) {
      this.window.heap.track(eventName, payload || {})
    }
  }

  gaDataLayerPush(payload: any) {
    this.window.dataLayer.push(payload)
  }

  heapResetIdentity() {
    this.window.heap.resetIdentity()
  }

  logRocketInit() {
    LogRocket.init("htyuup/zeguro-platform", {
      shouldCaptureIP: false,
      rootHostname: window.location.hostname,
    })
  }

  logRocketIdentify() {
    const state = store.getState()
    const email = state?.portal?.userProfile?.email
    const environmentName = state?.portal?.config?.environmentName
    console.log("Environment Name is " + environmentName)

    if (environmentName === "prod") {
      if (state.portal.orgId) {
        LogRocket.identify(state.portal.orgId, {
          companyName:
            state.portal.companyProfile &&
            state.portal.companyProfile.companyName,
          ...(email && { email }),
        })
      }
    }
  }

  getEnvParam(param: string) {
    const state = store.getState()
    //FIXIT remove everest related environment
    //FIXIT use orgID on the backend only
    if (param === "OrgID" && state.portal.orgId) {
      return state.portal.orgId
    }
    if (
      param === "OrgName" &&
      state.portal.companyProfile &&
      state.portal.companyProfile.companyName
    ) {
      return state.portal.companyProfile.companyName
    }

    if (param === "firstName" && state.portal.userProfile) {
      return state.portal.userProfile.firstName
    }

    if (param === "lastName" && state.portal.userProfile) {
      return state.portal.userProfile.lastName
    }

    return state.portal.environment[param]
  }

  //FIXIT: Remove this if not needed anymore.
  initEnvironment() {
    const url: URL = new URL(window.location.href)
    const envParameters = [
      "Channel",
      "AddressLine",
      "OrgName",
      "StateName",
      "OrgID",
      "SecurityProfessionalTitle",
      "CityName",
      "IsHIPAACompliant",
      "HaveCyberInsurance",
      "PlanToHireSecProfessional",
      "IsPCICompliant",
      "WebDomain",
      "HaveDedicatedITSecProfessional",
      "ZipCode",
      "userName",
      "firstName",
      "lastName",
      "primaryPhoneNumber",
      "userId",
      "channel",
      "id_token",
      "view",
    ]

    const env = envParameters.reduce(
      (env: { [key: string]: string }, param: string) => {
        const val = url.searchParams.get(param)
        if (val) {
          env[param] = val
        }
        return env
      },
      {},
    )
    store.dispatch(PORTAL.setEnvironment(env))
  }

  createSession(token: string, callback?: () => void) {
    this.Portal.createSessionObject(token, () => {
      this.Cognito.globalSignOut()
      this.Portal.checkSession("createSession")
      callback && callback()
    })
  }

  getEnvironment() {
    const state = store.getState()
    if (state.portal.companyProfile) {
      return {
        CompanyName: state.portal.companyProfile.companyName,
        DFS_IR_NotificationSection: false,
        /*state.portal.companyProfile.address.state === "NY"*/
      }
    }
  }

  getIsoDate(d: Date) {
    const pad = (number: number) => (number < 10 ? "0" : "") + number
    return (
      d.getUTCFullYear() +
      "-" +
      pad(d.getUTCMonth() + 1) +
      "-" +
      pad(d.getUTCDate())
    )
  }

  /////////////////////////////////////////////////////////

  reset(target: string) {
    const state = store.getState()
    const orgId = state.portal.orgId

    Middletier.xhrPost("/reset/" + target, JSON.stringify({ orgId }), () => {
      if (target === "onboarding") {
        document.location.reload()
      }
    })
  }

  ///////////////////////////////////////////////////

  toastNotification(
    message: string | JSX.Element,
    type: string,
    timeout?: number,
  ) {
    if (type === "success") {
      toast.success(message, {
        theme: "colored",
        autoClose: timeout || 5000,
      })
    }
    if (type === "danger") {
      toast.error(message, {
        theme: "colored",
        autoClose: timeout || false,
      })
    }
  }

  timeoutNotification(expires: number) {
    this.window.timeoutNotification(expires)
  }

  showUpgradeModal() {
    this.window.showUpgradeModal()
  }

  toggleSubscribeModal(value: boolean) {
    this.window.toggleSubscribeModal(value)
  }

  showSupportModal() {
    this.window.showSupportModal()
  }

  alert(message: any) {
    alert(JSON.stringify(message))
  }
}

const controller = new Controller()

export default controller
