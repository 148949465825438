import { ProgressBar } from "@blueprintjs/core"
import { ButtonSmall } from "@src/Components/Button"
import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import { Section } from "@src/Components/StyledUtils"
import Tooltip from "@src/Components/Tooltip"
import { Panel } from "@src/Components/View"
import { tState } from "@src/Model/Model"
import {
  HeadingStatus,
  HeadingWrapper,
} from "@src/Portal/Zimm/HeaderCard/styles"
import { ILevelInfo } from "@src/types"
import { calculatePercentage } from "@src/Utils"
import * as moment from "moment"
import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import Level from "../../Level"
import {
  ButtonContainer,
  ProgressBarContainer,
  ProgressDetails,
  ProgressInfo,
  SubtleText,
  UpdatedText,
} from "../styles"
import { CompanyName, SectionStyled, TopSection } from "./styles"

interface IReportHeaderMobile {
  assessmentLink: string
  overallLevel?: number
  levelInfo?: ILevelInfo | undefined
  assessmentStatus?: string
  lastUpdate?: string
}

export const ReportHeaderMobile = ({
  assessmentLink,
  overallLevel,
  levelInfo,
  assessmentStatus,
  lastUpdate,
}: IReportHeaderMobile) => {
  const history = useHistory()
  const answeredQuestions =
    useSelector((store: tState) => store.zimm.getAssessmentData.data)?.response
      .questions.length || 0
  const config = useSelector(
    (store: tState) => store.zimm.getConfig.elements,
  ).length
  const progress = calculatePercentage(answeredQuestions, config)
  return (
    <Panel margin="0px 0px 32px 0px">
      <HeadingWrapper margin="0 0 32px 0">
        <img
          height="44px"
          src="images/zimm/icon_cyber_maturity_lg.svg"
          alt="head"
        />
        Cyber Maturity<HeadingStatus>Beta</HeadingStatus>
      </HeadingWrapper>
      <SectionStyled flex="center center column">
        <TopSection>
          <CompanyName>
            Your Cyber Maturity Level{" "}
            {assessmentStatus !== "completed" && (
              <Section display="inline-block">So Far:</Section>
            )}
          </CompanyName>
        </TopSection>
        <Level
          level={overallLevel}
          large
          margin="0 0 16px"
          dataFor="head-level-tooltip"
        />
        <Section
          fontSize="21px"
          fontWeight="600"
          margin="0 0 16"
          align="center"
          fontFamily="montserrat"
        >
          {levelInfo?.levelName}
        </Section>
        {levelInfo?.levelName && (
          <Tooltip
            id="head-level-tooltip"
            width="405px"
            content={
              <>
                <Section margin="0 0 10 0" fontWeight="700" fontSize="21px">
                  Level {overallLevel}
                </Section>
                <Section fontSize="18px">
                  <span>{levelInfo.description}</span>
                  <br />
                  <br />
                  <span>
                    Assessment last updated&nbsp;
                    {moment(lastUpdate).format("MMM D, YYYY")}
                  </span>
                </Section>
              </>
            }
          />
        )}
        {assessmentStatus === "notCompleted" ? (
          <>
            <ButtonContainer>
              <ButtonSmall
                fontSize="20px"
                onClick={() => history.push(assessmentLink)}
              >
                <FaIcon
                  className="fas fa-money-check-edit"
                  margin="0 6px 0 0"
                />
                Finish Assessment
              </ButtonSmall>
            </ButtonContainer>
            <ProgressDetails>
              <ProgressInfo>
                <SubtleText>Questions Answered</SubtleText>
                <UpdatedText>
                  <div>Last updated</div>
                  <div>{moment(lastUpdate).format("MMM D, YYYY")}</div>
                </UpdatedText>
                <SubtleText align="end">{`${progress}%`}</SubtleText>
              </ProgressInfo>
              <ProgressBarContainer>
                <ProgressBar
                  value={progress / 100}
                  className="bp5-intent-primary bp5-no-stripes"
                />
              </ProgressBarContainer>
            </ProgressDetails>
          </>
        ) : (
          <Section flex="center center column" fontSize="18px">
            <div>
              Elements with the <b>lowest levels</b> are your{" "}
              <b>weakest points</b>.
            </div>
            <div>
              <b>Address these</b> first to improve your overall{" "}
              <b>Cyber Maturity Level.</b>&nbsp;
              <ExternalLink
                text="More"
                margin="0px"
                url="https://zeguro.zendesk.com/hc/en-us/articles/7793335742487-Cyber-Maturity-Model-and-Levels"
              />
            </div>
          </Section>
        )}
      </SectionStyled>
    </Panel>
  )
}
