import { actions as ACCOUNTS } from "@src/Actions/Reducers/AccountsReducer"
import history from "@src/browserHistory"
import C from "@src/Controller"
import {
  ICheckResetPasswordMagicLink,
  IResetPassword,
  ISendResetPasswordMagicLinkData,
} from "@src/Sagas/types"
import axios from "axios"
import { put, takeLeading } from "redux-saga/effects"

function* sendResetPasswordMagicLink(
  action: ISendResetPasswordMagicLinkData,
): any {
  try {
    yield put(ACCOUNTS.sendResetPasswordMagicLinkRequest())
    yield axios.post(
      `api/accounts/sendResetPasswordMagicLink`,
      JSON.stringify({
        ...action.payload,
        url: window.location.hostname,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    yield put(
      ACCOUNTS.sendResetPasswordMagicLinkSuccess({
        email: action.payload.email,
      }),
    )
  } catch (e) {
    C.toastNotification(
      "Something went wrong while verifying email. Try again, or email support@zeguro.com for help.",
      "danger",
    )
    yield put(ACCOUNTS.sendResetPasswordMagicLinkFailure(e))
  }
}

function* checkResetPasswordMagicLink(
  action: ICheckResetPasswordMagicLink,
): any {
  try {
    yield put(ACCOUNTS.checkResetPasswordMagicLinkRequest())
    const { data } = yield axios.post(
      `api/accounts/checkResetPasswordMagicLink`,
      JSON.stringify({
        ...action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      const isValidLink = data?.isValidLink
      if (!isValidLink) {
        history.push("/signin")
        C.toastNotification(
          "The link is invalid. Request another link, or email support@zeguro.com for help.",
          "danger",
        )
      }
      yield put(ACCOUNTS.checkResetPasswordMagicLinkSuccess({ isValidLink }))
    } else {
      throw Error("Unable to verify link")
    }
  } catch (e) {
    history.push("/signin")
    C.toastNotification(
      "Something went wrong while verifying the link. Try again, or email support@zeguro.com for help.",
      "danger",
    )
    yield put(ACCOUNTS.checkResetPasswordMagicLinkFailure(e))
  }
}

function* resetPassword(action: IResetPassword): any {
  try {
    yield put(ACCOUNTS.resetPasswordRequest())
    const { data } = yield axios.post(
      `api/accounts/resetPassword`,
      JSON.stringify({
        ...action.payload,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (data.success) {
      yield put(ACCOUNTS.resetPasswordSuccess())
      history.push(
        action?.payload?.subscriptionId
          ? `/signin/${action?.payload?.subscriptionId}`
          : "/signin",
      )
      C.toastNotification(
        "Your password was successfully reset. You will be redirected to the sign in page.",
        "success",
      )
    } else {
      throw Error("Unable to reset password")
    }
  } catch (e) {
    yield put(ACCOUNTS.resetPasswordFailure(e))
    C.toastNotification(
      "Something went wrong while resetting password. Try again, or email support@zeguro.com for help.",
      "danger",
    )
  }
}

export function* accountsWatcherSaga() {
  yield takeLeading(
    "SEND_RESET_PASSWORD_MAGIC_LINK_WATCHER",
    sendResetPasswordMagicLink,
  )
  yield takeLeading(
    "CHECK_RESET_PASSWORD_MAGIC_LINK_WATCHER",
    checkResetPasswordMagicLink,
  )
  yield takeLeading("RESET_PASSWORD_WATCHER", resetPassword)
}
