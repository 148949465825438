import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import { Select } from "@src/Components/Form/Select"
import TextField from "@src/Components/Form/TextField"
import { Link } from "@src/Components/Link"
import ProgressWizard from "@src/Components/ProgressWizard"
import { Section } from "@src/Components/StyledUtils"
import { tState } from "@src/Model/Model"
import { SpinnerStyled } from "@src/Portal/Settings/styles"
import { checkAppUrlWatcher } from "@src/Sagas"
import { colors } from "@src/theme"
import { FormikProps } from "formik"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

import { getUrl, IAddWebAppFormFields } from ".."
import { Row } from "../styles"

interface IAddAppStepOne {
  formik: FormikProps<IAddWebAppFormFields>
  protocols: string[][]
  onNext: () => void
  onClose: () => void
}

const AddAppStepOne = ({
  formik,
  protocols,
  onNext,
  onClose,
}: IAddAppStepOne) => {
  const dispatch = useDispatch()
  const [isUrlChecked, setIsUrlChecked] = React.useState(false)
  const [urlCheckError, setUrlCheckError] = React.useState<
    React.ReactNode | string
  >("")

  const checkAppUrl = useSelector(
    (store: tState) => store.monitoring.checkAppUrl,
  )
  const isLoading = checkAppUrl.isLoading
  const { usedInTargets, inAlexaTopSites } = checkAppUrl.data

  React.useEffect(() => {
    if (checkAppUrl.data !== undefined) {
      setIsUrlChecked(true)
    }
  }, [checkAppUrl.data])

  React.useEffect(() => {
    if (inAlexaTopSites) {
      setUrlCheckError(
        <>
          Scanning of this URL is restricted. If you own this website, please
          contact&nbsp;
          <Link href="mailto:support@zeguro.com" isUnderline>
            support@zeguro.com
          </Link>
          .
        </>,
      )
    }
    if (usedInTargets) {
      setUrlCheckError("This URL is already being used")
    }
    if (isUrlChecked && !inAlexaTopSites && !usedInTargets) {
      setIsUrlChecked(false)
      onNext()
    }
  }, [isUrlChecked])

  React.useEffect(() => {
    setIsUrlChecked(false)
  }, [formik.values.webAppUrl])

  const protocolsOptions = protocols.map((protocol) => ({
    value: protocol[1],
    label: protocol[0],
  }))

  const checkWebAppUrl = () => {
    setIsUrlChecked(false)
    dispatch(
      checkAppUrlWatcher({
        url: getUrl(formik.values.protocol, formik.values.webAppUrl),
      }),
    )
  }

  return (
    <div>
      <div className="bp5-dialog-body">
        <Row>
          <ProgressWizard
            steps={["Specify Website", "Confirm URL", "Schedule Scan"]}
            stepsCompleted={0}
          />
        </Row>
        <Row>
          <TextField
            label="Website Name"
            value={formik.values.webAppName}
            name={"webAppName"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.errors.webAppName && formik.touched.webAppName,
            )}
            errorMsg={formik.errors.webAppName}
            margin="0 0 18px 0"
          />
        </Row>
        <Section margin="0 0 42 0" fontColor={colors.gray40}>
          Give your website an intuitive name to help differentiate it. Example:
          Customer Portal
        </Section>
        <Row>
          <Select
            label="Website Protocol"
            name="protocol"
            placeholder={"Select HTTPS or HTTP"}
            options={protocolsOptions}
            onChange={formik.setFieldValue}
            value={protocolsOptions.find(
              (protocolsOption) =>
                protocolsOption.value === formik.values.protocol,
            )}
            isSearchable={false}
            errorMessage={
              (formik.touched.webAppUrl && formik.errors.protocol) || undefined
            }
            margin="0 0 18px 0"
          />
        </Row>
        <Section margin="0 0 20 0" fontColor={colors.gray40}>
          To see which protocol you use, open another browser tab, go to the
          website, and view the address bar.
        </Section>
        <Row>
          <TextField
            label="Website URL"
            value={formik.values.webAppUrl}
            name={"webAppUrl"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              (formik.errors.webAppUrl && formik.touched.webAppUrl) ||
                (isUrlChecked && urlCheckError),
            )}
            errorMsg={formik.errors.webAppUrl || urlCheckError}
            margin="0 0 18px 0"
          />
        </Row>
        <Section margin="0 0 32 0" fontColor={colors.gray40}>
          The website address. Example: www.company.com
        </Section>
        <Section>
          We’ll attempt to fill out any forms on your website to check for
          vulnerabilities, so you may get test emails as we scan the site.
        </Section>
      </div>
      <Section flex="flex-end cender" margin="0 20">
        <ButtonCancel width={100} onClick={onClose}>
          Cancel
        </ButtonCancel>
        <ButtonSmall
          id="add-web-app-next"
          type="button"
          width={100}
          onClick={checkWebAppUrl}
          disabled={
            isLoading ||
            !formik.dirty ||
            formik.errors.webAppName ||
            formik.errors.webAppUrl ||
            formik.errors.protocol
          }
        >
          {isLoading ? <SpinnerStyled size={25} /> : "Next"}
        </ButtonSmall>
      </Section>
    </div>
  )
}

export default AddAppStepOne
