import { Spinner } from "@blueprintjs/core"
import PageNotFound from "@src/Components/PageNotFound"
import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"

import C from "../../Controller"
import { tState } from "../../Model/Model"
import SignUp from "./Signup"

interface ISignUpContainer {
  authenticated: boolean | null
  subscriptionIdState:
    | "expired"
    | "invalid"
    | "valid"
    | "loading"
    | "failed"
    | "ready"
  uiRules: {
    isTrialDisabled?: boolean
    marketingLogoPrimary?: string
    marketingLogoSecondary?: string
    marketingName?: string
  }
  channelId: string
  planId: string
}

interface ISignUpContainerState {
  resize: boolean
  signUpComplete: boolean
  goToLogin: boolean
  recaptchaInstance: any
  lastUserCreated: string
}

interface ISignUpNavParams {
  subscriptionId: string
}

class SignUpContainer extends React.Component<
  ISignUpContainer & RouteComponentProps<ISignUpNavParams>,
  ISignUpContainerState
> {
  public state: ISignUpContainerState = {
    resize: true,
    signUpComplete: false,
    goToLogin: false,
    recaptchaInstance: null,
    lastUserCreated: "",
  }

  recaptchaRef: React.RefObject<unknown> = React.createRef()

  render() {
    const { subscriptionIdState, uiRules } = this.props
    const app = document.getElementById("portal")

    if (!app) {
      return "Missing Content..."
    }

    if (["expired", "invalid", "failed"].includes(subscriptionIdState)) {
      return <PageNotFound />
    }

    if (
      this.props.authenticated === null ||
      subscriptionIdState === "loading"
    ) {
      return (
        <div style={{ height: "100%", paddingTop: 200, textAlign: "center" }}>
          <Spinner size={25} /> Loading Cyber Safety Portal...
        </div>
      )
    }

    if (this.state.signUpComplete) {
      return (
        <Redirect
          to={{
            pathname: "/verify",
            state: {
              email: this.state.lastUserCreated,
              subscriptionId: this.props.match.params.subscriptionId,
              uiRules: uiRules,
            },
          }}
        />
      )
    }
    if (this.props.authenticated || this.state.goToLogin) {
      return (
        <Redirect
          to={
            this.props.match.params.subscriptionId
              ? `/signin/${this.props.match.params.subscriptionId}`
              : "/"
          }
        />
      )
    } else {
      return (
        <SignUp
          height={app.clientHeight}
          width={app.clientWidth}
          onSignUp={this.onSignUp.bind(this)}
          subscriptionId={
            this.props.match.params.subscriptionId || "defaultSecurity"
          }
          redirectToLogin={() => this.setState({ goToLogin: true })}
          recaptchaRef={this.recaptchaRef}
          executeCaptcha={this.executeCaptcha.bind(this)}
          verifyToken={this.verifyToken.bind(this)}
          resetCaptcha={this.resetCaptcha.bind(this)}
          uiRules={uiRules}
        />
      )
    }
  }

  onSignUp(
    subscriptionId: string,
    username: string,
    password: string,
    callback: any,
  ) {
    this.createUser(subscriptionId, username, password, callback)
  }

  executeCaptcha() {
    const current: any = this.recaptchaRef.current
    current.execute()
  }

  resetCaptcha() {
    const current: any = this.recaptchaRef.current
    current.reset()
  }

  verifyToken(token: any, callback: any) {
    C.Recaptcha.verifyToken(token, callback)
  }

  createUser(
    subscriptionId: string,
    username: string,
    password: string,
    callback: any,
  ) {
    this.setState({ lastUserCreated: "" })
    C.heapIdentify(username)
    C.Cognito.CreateNewUser(
      subscriptionId,
      username,
      password,
      this.props.channelId,
      this.props.planId,
      (ret: any) => {
        callback && callback(ret)
        if (ret.success) {
          C.partnerStackIdentify(username, () =>
            this.setState({ lastUserCreated: username, signUpComplete: true }),
          )
        } else {
          C.heapResetIdentity()
          console.log(`signup error - ${JSON.stringify(ret.error)}`)
          this.resetCaptcha()
        }
      },
    )
  }

  resize() {
    this.setState({ resize: true })
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.resize())
    C.Recaptcha.loadScriptv2() //Load Recaptcha v2
    C.Portal.checkSession("SignUpContainer")
  }

  UNSAFE_componentWillMount() {
    C.initEnvironment()
  }
}

interface ISignUpContainerProps {
  authenticated: boolean | null
  subscriptionIdState:
    | "expired"
    | "invalid"
    | "valid"
    | "loading"
    | "failed"
    | "ready"
  uiRules: {
    isTrialDisabled?: boolean
    marketingLogoPrimary?: string
    marketingLogoSecondary?: string
    marketingName?: string
  }
  channelId: string
  planId: string
}

const mapStateToProps = (store: tState): ISignUpContainerProps => {
  return {
    authenticated: store.portal.authenticated,
    subscriptionIdState: store.subscriptionId.subscriptionIdState,
    uiRules: store.subscriptionId.uiRules,
    channelId: store.subscriptionId.channelId,
    planId: store.subscriptionId.planId,
  }
}

export default withRouter(connect(mapStateToProps)(SignUpContainer))
