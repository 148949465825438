import ZegPanel from "@src/Components/Common/ZegPanel"
import { ContentArea } from "@src/Components/View"
import C from "@src/Controller"
import * as T from "@src/types"
import * as React from "react"
import { useHistory } from "react-router"

interface ISettingsContainer {
  children: JSX.Element
}

export const settingsTabs = [
  { id: "you", text: "Your Details", shortText: "Your Details" },
  {
    id: "organization",
    text: "Organization Details",
    shortText: "Organization Details",
  },
  {
    id: "admins",
    text: "Manage Cyber Safety Admins",
    shortText: "Manage Admins",
  },
]

const SettingsContainer = ({ children }: ISettingsContainer) => {
  const history = useHistory()
  const paths = history.location.pathname.split("/")
  const tabPath = paths[2]

  React.useEffect(() => {
    C.Portal.getUserList()
    C.heapTrack("Viewed Settings")
  }, [])

  return (
    <ContentArea>
      <ZegPanel
        tabs={settingsTabs}
        onTabChange={(id) => history.push(`${T.ViewTypes.settings}/${id}`)}
        selectedTabId={tabPath}
      >
        {children}
      </ZegPanel>
    </ContentArea>
  )
}

export default SettingsContainer
