import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialAccountsState } from "@src/Model/Model"

export const AccountsSlice = createSlice({
  name: "account",
  initialState: initialAccountsState,
  reducers: {
    sendResetPasswordMagicLinkRequest({ sendResetPasswordMagicLink }) {
      sendResetPasswordMagicLink.isLoading = true
      sendResetPasswordMagicLink.error = undefined
    },
    sendResetPasswordMagicLinkSuccess(
      { sendResetPasswordMagicLink },
      { payload }: PayloadAction<{ email: string }>,
    ) {
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.error = undefined
      sendResetPasswordMagicLink.success = true
      sendResetPasswordMagicLink.email = payload?.email
    },
    sendResetPasswordMagicLinkFailure(
      { sendResetPasswordMagicLink },
      { payload }: PayloadAction<Error>,
    ) {
      sendResetPasswordMagicLink.error = payload
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.success = false
      sendResetPasswordMagicLink.email = undefined
    },

    sendResetPasswordMagicLinkClear({ sendResetPasswordMagicLink }) {
      sendResetPasswordMagicLink.error = undefined
      sendResetPasswordMagicLink.isLoading = false
      sendResetPasswordMagicLink.success = false
      sendResetPasswordMagicLink.email = undefined
    },
    checkResetPasswordMagicLinkRequest({ checkResetPasswordMagicLink }) {
      checkResetPasswordMagicLink.isLoading = true
      checkResetPasswordMagicLink.error = undefined
    },
    checkResetPasswordMagicLinkSuccess(
      { checkResetPasswordMagicLink },
      { payload }: PayloadAction<{ isValidLink: boolean }>,
    ) {
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.success = true
      checkResetPasswordMagicLink.error = undefined
      checkResetPasswordMagicLink.isValidLink = payload?.isValidLink
    },
    checkResetPasswordMagicLinkFailure(
      { checkResetPasswordMagicLink },
      { payload }: PayloadAction<Error>,
    ) {
      checkResetPasswordMagicLink.error = payload
      checkResetPasswordMagicLink.success = false
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.isValidLink = false
    },
    checkResetPasswordMagicLinkClear({ checkResetPasswordMagicLink }) {
      checkResetPasswordMagicLink.error = undefined
      checkResetPasswordMagicLink.success = undefined
      checkResetPasswordMagicLink.isLoading = false
      checkResetPasswordMagicLink.isValidLink = undefined
    },
    resetPasswordRequest({ resetPassword }) {
      resetPassword.isLoading = true
      resetPassword.error = undefined
    },
    resetPasswordSuccess({ resetPassword }) {
      resetPassword.isLoading = false
      resetPassword.error = undefined
      resetPassword.success = true
    },
    resetPasswordFailure({ resetPassword }, { payload }: PayloadAction<Error>) {
      resetPassword.error = payload
      resetPassword.isLoading = false
      resetPassword.success = false
    },
    resetPasswordClear({ resetPassword }) {
      resetPassword.error = undefined
      resetPassword.success = undefined
      resetPassword.isLoading = false
    },
  },
})

export const actions = AccountsSlice.actions

export default AccountsSlice.reducer
