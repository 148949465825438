import { colors, media, shadows } from "@src/theme"
import styled from "styled-components"

interface IFaIcon {
  large?: boolean
}

export const Container = styled.div`
  display: flex;
  @media ${media.medium} {
    flex-direction: column;
  }
`
export const Title = styled.h1`
  font-size: 32px;
  margin: 0 0 20px 0;
  @media ${media.medium} {
    font-size: 21px;
  }
`
export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`
export const CardTextHead = styled.p`
  margin: 0;
  font-size: 18px;
  color: ${colors.black50};
  line-height: 25px;
`
export const CardTextSub = styled.p`
  margin: 0;
  font-size: 15px;
  color: ${colors.gray50};
  line-height: 24px;
`
export const UserIcon = styled.i<any>`
  font-size: 32px;
  margin-right: 11px;
  padding-left: 20px;
  color: ${colors.darkBlue};
`
export const FaIcon = styled.i<IFaIcon>`
  font-size: ${({ large }) => (large ? "64" : "26")}px;
  margin-right: ${({ large }) => (large ? "36" : "12")}px;
  color: ${colors.darkBlue};
  width: ${({ large }) => large && "100%"};
  max-width: ${({ large }) => large && "62px"};
`

export const AddUserIcon = styled.i`
  font-size: 18px;
  color: ${colors.white};
  padding-right: 9px;
`
export const AddUserIconNoUsers = styled.i`
  font-size: 32px;
  color: ${colors.link};
  padding-right: 15px;
`
export const InviteUserContainerNoUsers = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: ${colors.link};
  margin-bottom: 20px;
  cursor: pointer;
  background: rgba(0, 98, 216, 0.05);
  border: 1px solid rgba(0, 98, 216, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
`

export const CardAdmin = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-radius: 6px;
  box-shadow: ${shadows.panel};
  border: solid 1px #979797;
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 9px;
  .tooltip-content-custom {
    box-shadow: ${shadows.panel};
    border-radius: 6px;
    opacity: 1 !important;
    background: ${colors.white};
    border: 1px solid ${colors.gray30};
    padding: 10px 10px;
    font-weight: normal;
    text-align: center;
    height: 32px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      color: ${colors.black50};
      font-size: 16px;
      line-height: 19px;
      height: auto;
    }

    &:after {
      border-top-color: white !important;
    }

    &:before {
      border-top: 8px solid ${colors.gray30} !important;
    }
  }
`

export const CardsContainer = styled.div`
  flex: 1;
  max-width: 580px;
  @media ${media.medium} {
    max-width: 100%;
  }
`

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
`

export const Form = styled.form`
  width: 100%;
  max-width: 500px;
  text-align: right;
  margin: 20px auto 0;
  padding: 0 20px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`
