import { Spinner } from "@blueprintjs/core"
import * as T from "@src/types"
import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"

import * as PortalAction from "../../Actions/PortalActions"
import C from "../../Controller"
import { tState } from "../../Model/Model"
import VerifyEmail from "./VerifyEmail"

interface IVerifyEmailContainer {
  authenticated: boolean | null
  setLoginPageView: (mode: string) => void
}

interface IVerifyEmailContainerState {
  resize: boolean
  goToLogin: boolean
  goToSignUp: boolean
}

interface IState {
  email: string
  subscriptionId?: string
  uiRules?: T.tChannelUiRules
}

class VerifyEmailContainer extends React.Component<
  IVerifyEmailContainer & RouteComponentProps<object, object, IState>,
  IVerifyEmailContainerState
> {
  public state: IVerifyEmailContainerState = {
    resize: true,
    goToLogin: false,
    goToSignUp: false,
  }

  render() {
    const app = document.getElementById("portal")

    if (!app) {
      return "Missing Content..."
    }

    if (this.props.authenticated === null) {
      return (
        <div style={{ height: "100%", paddingTop: 200, textAlign: "center" }}>
          <Spinner size={25} /> Loading Cyber Safety Portal...
        </div>
      )
    }
    if (
      this.props.authenticated ||
      this.state.goToLogin ||
      this.state.goToSignUp
    ) {
      return this.state.goToSignUp ? (
        <Redirect
          to={
            this.props.location?.state?.subscriptionId
              ? `/signup/${this.props.location?.state?.subscriptionId}`
              : "/signup"
          }
        />
      ) : (
        <Redirect
          to={
            this.props.location?.state?.subscriptionId
              ? `/signin/${this.props.location?.state?.subscriptionId}`
              : "/"
          }
        />
      )
    } else {
      const email = this.props.location?.state?.email
      return (
        <VerifyEmail
          height={app.clientHeight}
          width={app.clientWidth}
          onVerifyEmail={this.requestCodeAgain.bind(this)}
          showSupportModal={() => {
            C.showSupportModal()
          }}
          redirectToLogin={() => this.setState({ goToLogin: true })}
          redirectToSignup={() => this.setState({ goToSignUp: true })}
          emailToVerify={email}
          marketingLogoPrimary={
            this.props.location?.state?.uiRules?.marketingLogoPrimary
          }
          marketingLogoSecondary={
            this.props.location?.state?.uiRules?.marketingLogoSecondary
          }
          marketingLogoPrimaryHeight={
            this.props.location?.state?.uiRules?.marketingLogoPrimaryHeight
          }
          marketingLogoSecondaryHeight={
            this.props.location?.state?.uiRules?.marketingLogoSecondaryHeight
          }
        />
      )
    }
  }

  requestCodeAgain(username: string) {
    C.Cognito.resendConfirmationCode(username, (res: any) => {
      if (res.success) {
        C.toastNotification(
          "Verification email has been sent. Please check your email.",
          "success",
        )
      } else {
        C.serverLog({
          level: "error",
          message: "Resending verification code Failed",
          endpoint: "cognitoUser.resendConfirmationCode",
          filename: __filename,
          stack: res.error || "",
        })
        C.toastNotification(
          "Unable to resend verification email. Please try again later.",
          "danger",
        )
      }
    })
  }

  resize() {
    this.setState({ resize: true })
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.resize())
    C.Portal.checkSession("VerifyEmailContainer")
  }

  UNSAFE_componentWillMount() {
    C.initEnvironment()
  }
}

interface IVerifyEmailContainerProps {
  authenticated: boolean | null
}

const mapDispatchToProps = (dispatch: any) => {
  const setLoginPageView = (mode: string) => {
    dispatch(PortalAction.setAccountsPageMode(mode))
  }

  return {
    setLoginPageView,
  }
}

const mapStateToProps = (store: tState): IVerifyEmailContainerProps => {
  return { authenticated: store.portal.authenticated }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmailContainer),
)
