import { ButtonSmall } from "@src/Components/Button"
import {
  Content,
  Divider,
  FormElement,
  Heading,
  LinkWrapper,
  Panel,
  PanelContainer,
  SubHeading,
} from "@src/Components/Common"
import FloatingLabelInput from "@src/Components/FloatingLabelInput"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import { Section } from "@src/Components/StyledUtils"
import C from "@src/Controller"
import MessageContainer from "@src/Portal/MessageContainer"
import { media } from "@src/theme"
import * as T from "@src/types"
import * as _ from "lodash"
import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import styled from "styled-components"

export type tOnSubmit = (data: any) => void

interface ISignUp {
  height: number
  width: number
  subscriptionId: string
  recaptchaRef: any
  onSignUp: (
    subscriptionId: string,
    username: string,
    password: string,
    callback: any,
  ) => void
  redirectToLogin: () => void
  executeCaptcha: () => void
  verifyToken: (token: any, callback: any) => void
  resetCaptcha: () => void
  uiRules?: T.tChannelUiRules
}

interface ISignUpState {
  email: string
  password: string
  passwordValid: boolean
  cognitoUser: any
  confirmPassword: string
  confirmPasswordValid: boolean
}

class SignUp extends React.Component<ISignUp, ISignUpState> {
  public readonly state: ISignUpState = {
    email: "",
    password: "",
    passwordValid: false,
    cognitoUser: "",
    confirmPassword: "",
    confirmPasswordValid: false,
  }

  clearState() {
    this.setState({
      email: "",
      password: "",
      cognitoUser: "",
    })
  }

  recaptchaInstance = null

  submitSignUp = () => {
    const { email, password } = this.state
    const { onSignUp } = this.props
    const subscriptionId = this.props.subscriptionId
    if (email.trim().length > 0 && password.trim().length > 0) {
      onSignUp(
        subscriptionId,
        _.toLower(email.trim()),
        password.trim(),
        (ret: any) => {
          if (ret.success) {
            this.setState({ cognitoUser: ret.result.user })
          } else {
            if (ret.error.code === "UsernameExistsException") {
              C.toastNotification(
                <div>
                  Looks like you already have an account.{" "}
                  <span
                    style={{
                      color: "#fffff",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.goToLoginScreen()
                    }}
                  >
                    Sign in now
                  </span>
                </div>,
                "danger",
                20000,
              )
            } else {
              C.toastNotification(ret.error.message || ret.error, "danger")
            }
          }
        },
      )
    } else {
      C.toastNotification(
        "Form Incomplete. Please fill empty fields.",
        "danger",
      )
      this.props.resetCaptcha()
    }
  }

  onEmailChange(event: any) {
    const value = event.target.value
    this.setState({ email: value })
  }

  onPasswordChange(event: any) {
    const value = event.target.value
    this.setState({
      password: value,
      confirmPassword: "",
      confirmPasswordValid: false,
    })
  }

  onConfirmPasswordChange(event: any) {
    const value = event.target.value
    this.setState({ confirmPassword: value })
  }

  onChange(value: any) {
    if (value !== null) {
      this.props.verifyToken(value, (response: any) =>
        response.success
          ? this.submitSignUp()
          : C.toastNotification("You smell like a robot.", "danger"),
      )
    } else {
      console.log("CAPTCHA ERROR")
    }
  }

  goToLoginScreen = () => {
    this.clearState()
    this.props.redirectToLogin()
  }

  render() {
    const pContent = {
      height: this.props.height,
    }

    return (
      <Content {...pContent}>
        <MessageContainer />
        <PanelContainer height={this.props.height}>
          <Panel>
            <Section flex="center center column">
              <Heading align="center" margin="20px 0px">
                Create your free account.
              </Heading>
            </Section>
            <Section flex="center center column">
              <SubHeading>
                Simplify monitoring, training, and compliance for your
                <br />
                organization with Cyber Safety.
              </SubHeading>
              {this.props.uiRules?.isTrialDisabled ? null : (
                <FormElement flexDirection="row" marginBottom="10px">
                  <FooterIcon margin={true} text="Free 30 day trial" />
                  <FooterIcon margin={true} text="No credit card" />
                  <FooterIcon text="Cancel any time" />
                </FormElement>
              )}
              <FormElement>
                <FloatingLabelInput
                  id="signup-email"
                  label="Your work email address"
                  type="text"
                  value={this.state.email}
                  onChange={this.onEmailChange.bind(this)}
                />
                <PasswordInput
                  id="signup-password"
                  label="Create password"
                  value={this.state.password}
                  onChange={this.onPasswordChange.bind(this)}
                  isValid={(valid: boolean) => {
                    this.setState({ passwordValid: valid })
                  }}
                />
                <PasswordInput
                  id="signup-confirm-password"
                  label="Confirm password"
                  passwordToMatch={this.state.password}
                  value={this.state.confirmPassword}
                  onChange={this.onConfirmPasswordChange.bind(this)}
                  isValid={(valid: boolean) => {
                    this.setState({ confirmPasswordValid: valid })
                  }}
                  noValidation={true}
                />
                <Section flex="center center column" w="100%">
                  <ReCAPTCHA
                    ref={this.props.recaptchaRef}
                    sitekey="6Lfi57UUAAAAACcMby1mAPrHTNZVGG_B2t_7KDDN"
                    size="invisible"
                    onChange={(value) => this.onChange(value)}
                  />
                </Section>
                <Section w="100%" margin="30 0 0 0">
                  <ButtonSmall
                    onClick={() => {
                      this.setState({
                        email: this.state.email.trim(),
                      })
                      this.props.executeCaptcha()
                    }}
                    id="CyberSafety_Trial_Lead_Button"
                    disabled={
                      !(
                        this.state.password &&
                        this.state.passwordValid &&
                        this.state.confirmPasswordValid
                      )
                    }
                  >
                    {this.props.uiRules?.isTrialDisabled
                      ? "Create Account"
                      : "Start Free Trial"}
                  </ButtonSmall>
                </Section>
                <LinkWrapper>
                  <p>
                    Already have an account? <br />
                    <Link
                      onClick={() => {
                        this.goToLoginScreen()
                      }}
                    >
                      Sign in
                    </Link>
                  </p>
                </LinkWrapper>
                <Divider />
                <LinkWrapper fontWeight="400">
                  <p>
                    By continuing, you agree to Cyber Safety&nbsp;
                    <Link
                      isUnderline
                      onClick={() => {
                        window.open("https://www.zeguro.com/terms-of-service")
                      }}
                    >
                      Terms&nbsp;of&nbsp;Service
                    </Link>
                    &nbsp;and
                    <br />
                    <Link
                      isUnderline
                      onClick={() => {
                        window.open("https://www.zeguro.com/privacy-policy")
                      }}
                    >
                      Privacy&nbsp;Policy
                    </Link>
                    .
                  </p>
                </LinkWrapper>
              </FormElement>
            </Section>
          </Panel>
        </PanelContainer>
      </Content>
    )
  }
}

interface FooterProps {
  text: string
  margin?: boolean
}

const FooterIcon: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <CheckDiv margin={props.margin}>
      <TickIcon className="fas fa-check" /> {props.text}
    </CheckDiv>
  )
}

const TickIcon = styled.i`
  font-size: 18px;
  color: #1c7f30;
  width: 25px;
  height: 18px;
`

const CheckDiv = styled.div<any>`
  height: 19px;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #212528;
  text-align: left;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: ${(props: any) => (props.margin ? "8px" : undefined)};
  @media ${media.medium} {
    display: none;
  }
`

export default SignUp
