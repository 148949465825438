import { Checkbox } from "@blueprintjs/core"
import { ButtonCancel, ButtonSmall } from "@src/Components/Button"
import ProgressWizard from "@src/Components/ProgressWizard"
import { FormikProps } from "formik"
import * as React from "react"

import { getUrl, IAddWebAppFormFields } from ".."
import { AppUrl, ConfirmUrlBox, Row } from "../styles"

interface IAddAppStepTwo {
  formik: FormikProps<IAddWebAppFormFields>
  onBack: () => void
  onAgreeTerms: () => void
}

const AddAppStepTwo = ({ formik, onBack, onAgreeTerms }: IAddAppStepTwo) => {
  return (
    <div>
      <div className="bp5-dialog-body">
        <Row>
          <ProgressWizard
            steps={["Specify Website", "Confirm URL", "Schedule Scan"]}
            stepsCompleted={1}
          />
        </Row>
        <Row>
          You authorize Zeguro Cyber Safety to perform Web Vulnerability Scans
          (as defined below) on the following URL.
          <br /> <br />
          <ConfirmUrlBox>
            <AppUrl>
              {getUrl(formik.values.protocol, formik.values.webAppUrl)}
            </AppUrl>
            <span>
              <Checkbox
                id="add-web-app-consent-checkbox"
                checked={formik.values.urlConfirmed}
                onChange={() =>
                  formik.setFieldValue(
                    "urlConfirmed",
                    !formik.values.urlConfirmed,
                  )
                }
              >
                I have permission to scan this website
              </Checkbox>
            </span>
          </ConfirmUrlBox>
          <br />
          Cyber Safety Website Scans are conducted to ascertain the compliance
          of network devices and applications with certain published security
          standards and to disclose security vulnerabilities that may be
          present. Security audits may be performed with the assistance of Cyber
          Safety employees or its appointed contractors, and may from time to
          time include additional probing and validation beyond the scope of
          Cyber Safety’s automated scanning system. In certain cases, the
          exploitation of a vulnerability and/or minimal extraction of data from
          the target server may be conducted to support Cyber Safety’s security
          audit findings, or to illustrate a vulnerability to you. Such data
          will be held in complete confidence and will be destroyed on request.
          Security audits typically include, but are not limited to, port
          scanning and port connections, evaluating services by checking
          versions and responses to certain requests, and crawling websites to
          perform testing of forms, form submissions, application responses, or
          to confirm the existence of certain files or services used.
        </Row>
      </div>
      <div
        style={{
          textAlign: "right",
        }}
        className="bp5-dialog-footer"
      >
        <ButtonCancel type="button" width={100} onClick={onBack}>
          Back
        </ButtonCancel>
        <ButtonSmall
          id="add-web-app-consent-button"
          type="button"
          width={100}
          onClick={onAgreeTerms}
          disabled={!formik.values.urlConfirmed}
        >
          I Agree
        </ButtonSmall>
      </div>
    </div>
  )
}

export default AddAppStepTwo
