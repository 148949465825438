import { ButtonSecondary } from "@src/Components/Button"
import { Link } from "@src/Components/Link"
import PasswordInput from "@src/Components/PasswordInput"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import { useChangePasswordValidation } from "@src/Portal/Settings/validation"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { SpinnerStyled } from "../../styles"
import { Form } from "../styles"

interface IPersonalDetailsForm {
  newPassword: string
  confirmNewPassword: string
}

const formInitial: IPersonalDetailsForm = {
  newPassword: "",
  confirmNewPassword: "",
}
interface IPasswordChangePage {
  page: string
  setPage: (page: string) => void
  cognitoUser: any
  oldPassword: string
}

export const PasswordChangePage: React.FC<IPasswordChangePage> = ({
  page,
  setPage,
  cognitoUser,
  oldPassword,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const isZeguroServiceSession = useSelector(
    (state: tState) => state.portal.isZeguroServiceSession,
  )
  const PasswordChangeScheme = useChangePasswordValidation()

  const formik = useFormik<IPersonalDetailsForm>({
    initialValues: formInitial,
    enableReinitialize: true,
    validationSchema: PasswordChangeScheme,
    onSubmit: async ({ confirmNewPassword, newPassword }) => {
      try {
        setIsLoading(true)
        if (newPassword !== confirmNewPassword) {
          C.toastNotification("Passwords don't match.", "danger", 5000)
          setIsLoading(false)
          return
        }
        await C.Cognito.changePassword(cognitoUser, oldPassword, newPassword)
        setIsLoading(false)
        C.toastNotification("Password successfully updated.", "success", 5000)
        formik.resetForm()
        setPage("1")
      } catch (error) {
        C.serverLog({
          level: "error",
          message: "Change Password Failed",
          filename: __filename,
          stack: error || "",
        })
        setIsLoading(false)
        C.toastNotification(
          <span>
            Password could not be updated. Try again. If this problem persists,
            contact{" "}
            <Link href="mailto:support@zeguro.com">support@zeguro.com.</Link>”
            as a persistent toast notification.
          </span>,
          "danger",
        )
      }
    },
  })

  return (
    <>
      {page == "2" ? (
        <Form onSubmit={formik.handleSubmit}>
          <PasswordInput
            id="passwordNew"
            label="New password"
            value={formik.values.newPassword}
            onChange={(e) => {
              formik.handleChange(e)
              formik.setFieldValue("confirmNewPassword", "")
            }}
            name="newPassword"
            margin="0 0 24px 0"
            disabled={isZeguroServiceSession}
          />
          <PasswordInput
            id="ConfirmPasswordNew"
            label="Confirm new password"
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            name="confirmNewPassword"
            margin="0 0 24px 0"
            disabled={isZeguroServiceSession}
            noValidation={true}
            passwordToMatch={formik.values.newPassword}
          />
          <ButtonSecondary
            id="save-details-button"
            width={226}
            margin="10 0"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isLoading ? <SpinnerStyled size={25} /> : "Change Password"}
          </ButtonSecondary>
        </Form>
      ) : null}
    </>
  )
}
