import C from "@src/Controller"
import * as _ from "lodash"
import * as moment from "moment"
import { Store } from "redux"

import * as PLANS from "../Actions/PlansActions"
import { actions as SUBSCRIPTIONID } from "../Actions/Reducers/SubscriptionIdReducer"
import * as T from "../types"
import { serverLog } from "./Logging"
import * as Middletier from "./Middletier"

class Plans {
  private store: Store
  public window: T.tExtWindow & Window = window

  constructor(store: Store) {
    this.store = store
  }

  getFeatureUsageProfile(
    callback?: (success: boolean, error?: string) => void,
  ) {
    this.store.dispatch(PLANS.setSaasPlansStatus("LOADING"))
    Middletier.fetchJson(`/plans/featureUsageProfile`, (success, data) => {
      if (success) {
        if (data.success) {
          let plans = data.data.plans

          const trialExpired =
            moment(Date.now())
              .startOf("day")
              .isAfter(Date.parse(plans.expirationDate), "day") &&
            plans.subscriptionStatus === "INTRIAL"
          const modules = {
            compliance: plans.securityPolicies,
            ratings: plans.bitsight,
            cybermaturity: plans.cybermaturity,
            training: plans.training,
            monitoring: plans.probely,
            insurance: plans.insurance,
            dashboard:
              plans.cybermaturity === "ACTIVE" ||
              plans.training === "ACTIVE" ||
              plans.probely === "ACTIVE" ||
              plans.securityPolicies === "ACTIVE"
                ? "ACTIVE"
                : "NOT_AVAILABLE",
            marketplace:
              plans.training === "ACTIVE" ||
              plans.probely === "ACTIVE" ||
              plans.securityPolicies === "ACTIVE"
                ? "ACTIVE"
                : "NOT_AVAILABLE",
          }
          if (trialExpired) {
            modules.compliance === "ACTIVE"
              ? (modules.compliance = "INACTIVE")
              : null
            modules.training === "ACTIVE"
              ? (modules.training = "INACTIVE")
              : null
            modules.monitoring === "ACTIVE"
              ? (modules.monitoring = "INACTIVE")
              : null
            modules.cybermaturity === "ACTIVE"
              ? (modules.cybermaturity = "INACTIVE")
              : null
          }

          const isHighestPlan =
            _.lowerCase(
              plans.channelObject.eligiblePlans[
                plans.channelObject.eligiblePlans.length - 1
              ]?.planPointer?.appId?.planId,
            ) === _.lowerCase(plans.subscribedPlanPointer?.appId?.planId)

          plans = { ...plans, modules, isHighestPlan }
          this.store.dispatch(PLANS.setSaasPlans(plans))
          this.store.dispatch(PLANS.setSaasPlansStatus("SUCCESS"))
          callback && callback(true)
        } else {
          this.store.dispatch(PLANS.setSaasPlansStatus("FAILED"))
          callback && callback(false)
        }
      } else {
        this.store.dispatch(PLANS.setSaasPlansStatus("FAILED"))
        callback && callback(false, "Cannot fetch saas plans")
        serverLog({
          level: "error",
          message: "Cannot fetch saas plans",
          filename: __filename,
        })
      }
    })
  }

  getEligiblePlans() {
    this.store.dispatch(PLANS.setEligiblePlansStatus("LOADING"))
    try {
      Middletier.fetchJson(`/plans/getEligiblePlans`, (success, data) => {
        if (success) {
          if (data.success) {
            const eligiblePlans = data.data.eligiblePlans
            const hasChargebeeSubscription = data.data.hasSubscription
            this.store.dispatch(PLANS.setEligiblePlans(eligiblePlans))
            this.store.dispatch(
              PLANS.setChargebeeSubscriptionStatus(hasChargebeeSubscription),
            )
            this.store.dispatch(PLANS.setEligiblePlansStatus("SUCCESS"))
          } else {
            throw Error("Unable to fetch eligible plans")
          }
        } else {
          throw Error("Unable to fetch eligible plans")
        }
      })
    } catch (e) {
      this.store.dispatch(PLANS.setEligiblePlansStatus("FAILED"))
      serverLog({
        level: "error",
        message: "Unable to fetch eligible plans",
        filename: __filename,
      })
    }
  }

  subscribePlan(planId?: string, callback?: any) {
    const state = this.store.getState()
    Middletier.xhrPost(
      "/plans/subscribePlan",
      JSON.stringify({
        planId,
      }),
      (response: any) => {
        callback && callback({ success: true })
        if (response.success) {
          C.toastNotification("Plan is successfully upgraded", "success")
          C.Portal.sendMarketingAnalytics(
            {
              email: state.portal.userProfile && state.portal.userProfile.email,
              plan: planId,
              security_subscription_status: "ACTIVE",
            },
            window.location.href,
            "Cybersafty Subscription Change",
          )
          this.getFeatureUsageProfile()
          this.getEligiblePlans()
        } else {
          const error = response.error
          C.toastNotification("Upgrade plan failed", "danger")
          serverLog({
            level: "error",
            message: "Errror in setting subscription",
            filename: __filename,
            stack: error,
          })
        }
      },
    )
  }

  isValidSubscriptionId(
    subscriptionId?: string,
    callback?: (
      state: "expired" | "invalid" | "valid" | "failed" | "ready",
      uiRules: {
        channelDiscountTitle?: string
        channelDiscountDescription?: string
        freeTrialNomenclature?: string
        isTrialDisabled?: boolean
        marketingLogoPrimary?: string
        marketingLogoSecondary?: string
        marketingName?: string
      },
      channelId: string,
      planId: string,
    ) => void,
  ) {
    try {
      SUBSCRIPTIONID.updateSubscriptionId({
        subscriptionIdState: "loading",
        uiRules: {},
        channelId: "",
        planId: "",
      })

      Middletier.fetchJson(
        `/plans/isValidSubscriptionId/${subscriptionId}`,
        (success, response) => {
          if (success) {
            const status = response.data.subscriptionIdStatus
            const uiRules = response.data.uiRules || {}
            const channelId = response.data.channelId
            const planId = response.data.planId
            callback && callback(status, uiRules, channelId, planId)
            this.store.dispatch(
              SUBSCRIPTIONID.updateSubscriptionId({
                subscriptionIdState: status,
                uiRules,
                channelId,
                planId,
              }),
            )
          } else {
            this.store.dispatch(
              SUBSCRIPTIONID.updateSubscriptionId({
                subscriptionIdState: "failed",
                uiRules: {},
                channelId: "",
                planId: "",
              }),
            )
            throw Error("Cannot validate subscription Id")
          }
        },
      )
    } catch (e) {
      callback && callback("failed", {}, "", "")
      this.store.dispatch(
        SUBSCRIPTIONID.updateSubscriptionId({
          subscriptionIdState: "failed",
          uiRules: {},
          channelId: "",
          planId: "",
        }),
      )
      serverLog({
        level: "error",
        message: "Cannot validate subscription Id",
        filename: __filename,
      })
    }
  }

  updatePlan(planId: string) {
    const state = this.store.getState()
    this.store.dispatch(PLANS.setUpdatePlanStatus("LOADING"))
    Middletier.xhrPost(
      "/plans/updatePlan",
      JSON.stringify({
        planId,
      }),
      (response: any) => {
        if (response.success) {
          C.Portal.sendMarketingAnalytics(
            {
              email: state.portal.userProfile && state.portal.userProfile.email,
              plan: planId,
              security_subscription_status: "ACTIVE",
            },
            window.location.href,
            "Cybersafty Subscription Change",
          )
          this.getFeatureUsageProfile()
          this.getEligiblePlans()
          this.store.dispatch(PLANS.setUpdatePlanStatus("SUCCESS"))
        } else {
          const error = response.error
          this.store.dispatch(PLANS.setUpdatePlanStatus("FAILED"))
          serverLog({
            level: "error",
            message: "Errror in setting subscription",
            filename: __filename,
            stack: error,
          })
        }
      },
    )
  }
}

export default Plans
