import * as React from "react"

import { Link } from "./styles"

export const supportMassage = (text: string) => (
  <>
    <span>{text}</span>&nbsp;
    <span>Try again. If this problem persists, contact</span>&nbsp;
    <Link href="mailto:support@zeguro.com">support@zeguro.com</Link>.
  </>
)
