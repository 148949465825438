import { ButtonSmall } from "@src/Components/Button"
import { usePortalSize } from "@src/Components/Common/usePortalSize"
import { Select } from "@src/Components/Form/Select"
import TextField from "@src/Components/Form/TextField"
import C from "@src/Controller"
import { tState } from "@src/Model/Model"
import {
  industries,
  noOfEmployees,
} from "@src/Portal/OnBoarding/OnBoardingData"
import { useCompanyDetailsValidation } from "@src/Portal/Settings/validation"
import * as T from "@src/types"
import { useFormik } from "formik"
import * as React from "react"
import { useSelector } from "react-redux"

import { SpinnerStyled } from "../styles"
import { Container, FaIcon, FieldsContainer, Hint, Link, Title } from "./styles"

interface ICompanyDetailsForm {
  companyName: string
  industry: string
  numberOfEmployees: ""
  companyUrl: string
}

const formInitial: Partial<ICompanyDetailsForm> = {
  companyName: "",
  industry: "",
  numberOfEmployees: "",
  companyUrl: "",
}

const OrganizationsDetails = () => {
  const { isDesktop } = usePortalSize()
  const [isLoading, setIsLoading] = React.useState(false)
  const companyProfile = useSelector(
    (state: tState) => state.portal.companyProfile,
  )
  const orgId = useSelector((state: tState) => state.portal.orgId || "")

  const industriesOptions = industries.map((ind) => ({
    value: ind[1],
    label: ind[0],
  }))

  const noOfEmployeesOptions = noOfEmployees.map((num) => ({
    value: num[0],
    label: num[0],
  }))

  const companyDetailsScheme = useCompanyDetailsValidation()

  const formik = useFormik<Partial<T.tCompanyProfile>>({
    initialValues: companyProfile || formInitial,
    enableReinitialize: true,
    validationSchema: companyDetailsScheme,
    onSubmit: (values) => {
      setIsLoading(true)
      C.Portal.updateCompany({ ...values }, orgId || "", () => {
        setIsLoading(false)
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Title>
        {!isDesktop && <FaIcon className="fas fa-building" />}
        Organization Details
      </Title>
      <Container>
        {isDesktop && <FaIcon large className="fas fa-building" />}
        <FieldsContainer>
          <TextField
            label="Organization Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values?.companyName || ""}
            name="companyName"
            error={Boolean(
              formik.errors?.companyName && formik.touched?.companyName,
            )}
            errorMsg={formik.errors?.companyName}
            margin="0 0 10px"
            disabled
          />
          <Hint>
            If you need to update your organization’s name, contact us at &nbsp;
            <Link href="mailto:support@zeguro.com" target="_blank">
              support@zeguro.com
            </Link>
          </Hint>
          <Select
            name="industry"
            label="Industry"
            placeholder="Select Payment Method"
            options={industriesOptions}
            onChange={formik.setFieldValue}
            defaultValue={industriesOptions[0]}
            value={industriesOptions.find(
              (el) => el.value === formik.values?.industry,
            )}
            margin="0 0 60px"
            isSearchable={false}
          />
          <Select
            name="numberOfEmployees"
            label="Number of Team Members"
            placeholder={"Select Payment Method"}
            options={noOfEmployeesOptions}
            onChange={formik.setFieldValue}
            defaultValue={noOfEmployeesOptions[0]}
            value={noOfEmployeesOptions.find(
              (el) => el.value === formik.values?.numberOfEmployees,
            )}
            margin="0 0 10px"
            maxWidth="320px"
            isSearchable={false}
          />
          <Hint>
            Include all full-time and part-time employees, contractors, and
            consultants.
          </Hint>
          <TextField
            label="Primary Website Address (URL)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyUrl || ""}
            name="companyUrl"
            error={Boolean(
              formik.errors.companyUrl && formik.touched.companyUrl,
            )}
            errorMsg={formik.errors.companyUrl}
            margin="0 0 48px"
          />
          <ButtonSmall
            id="save-details-button"
            width={258}
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isLoading ? (
              <SpinnerStyled size={25} />
            ) : (
              "Update Organization Details"
            )}
          </ButtonSmall>
        </FieldsContainer>
      </Container>
    </form>
  )
}

export default OrganizationsDetails
