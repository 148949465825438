import { tState } from "@src/Model/Model"
import * as _ from "lodash"
import * as React from "react"
import { useSelector } from "react-redux"

const app = document.getElementById("portal")

export const usePortalSize = () => {
  const plans = useSelector((state: tState) => state.plans)

  const companyProfile = useSelector(
    (state: tState) => state.portal.companyProfile,
  )
  const onBoarding = _.isEmpty(companyProfile)

  const isDashboardViewed = useSelector(
    (store: tState) => store.customerPortalData?.data?.introViewed?.dashboard,
  )

  const isFirstTimeLogin = _.isUndefined(isDashboardViewed)
    ? true
    : !isDashboardViewed

  const width = () =>
    app && Number(window.getComputedStyle(app).width.replace("px", ""))

  const [clientWidth, setClientWidth] = React.useState(width() || 0)
  const [clientHeight, setClientHeight] = React.useState(app?.clientHeight || 0)
  const [isDesktop, setIsDesktop] = React.useState(true)
  const [isLargeScreen, setIsLargeScreen] = React.useState(false)

  const isMenuHidden =
    onBoarding ||
    isFirstTimeLogin ||
    (plans.modules.compliance === "NOT_AVAILABLE" &&
      plans.modules.monitoring === "NOT_AVAILABLE" &&
      plans.modules.training === "NOT_AVAILABLE")

  React.useEffect(() => {
    const resizeListener = () => {
      setClientWidth(width() || 0)
      setClientHeight(app?.clientHeight || 0)
      setIsDesktop((width() || 0) > 900)
      setIsLargeScreen((width() || 0) >= 1499)
    }
    resizeListener()
    window.addEventListener("resize", resizeListener)

    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  return {
    clientWidth,
    clientHeight,
    isDesktop,
    isLargeScreen,
    isMenuHidden,
    onBoarding,
    isFirstTimeLogin,
  }
}
