import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import ConfirmationModal from "@src/Components/ConfirmationModal"
import { tState } from "@src/Model/Model"
import { getQuoteFormUrl } from "@src/Utils"
import * as React from "react"
import { useSelector } from "react-redux"

import { ConfirmContent, ConfirmText, ConfirmTextBold } from "./styles"

interface IProps {
  title?: string
  secondary?: boolean
  onClick?: () => void
}

const emailText = `
  We will send you an email once our engine is done crunching the numbers.
  If you have any questions contact us at
`

const DownloadDetailedReport: React.FC<IProps> = ({ title, secondary }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const Button = secondary ? ButtonSecondary : ButtonSmall

  const onOpenModal = () => {
    setIsOpen(true)
  }

  const onCloseCancel = () => {
    setIsOpen(false)
  }

  const onCloseConfirm = () => {
    setIsOpen(false)
  }

  const environmentName = useSelector(
    (state: tState) => state.portal.config.environmentName,
  )

  return (
    <>
      <Button onClick={onOpenModal} padding="5 15" height="auto">
        {title || "Download Report"}
      </Button>

      <ConfirmationModal
        infoOnly
        isOpen={isOpen}
        title="Your detailed report is on its way!"
        confirmButton="OK"
        onClose={onCloseCancel}
        onConfirm={onCloseConfirm}
      >
        <ConfirmContent>
          <ConfirmText>
            <ConfirmTextBold>{"What to expect."}</ConfirmTextBold>
            {emailText}
            <a href="mailto:support@zeguro.com">support@zeguro.com.</a>
          </ConfirmText>
          <ConfirmText>
            <ConfirmTextBold>{"Best course of action."}</ConfirmTextBold>
            {` Get your cyber liability insurance `}
            <a
              href={getQuoteFormUrl(environmentName)}
              target="_blank"
              rel="noreferrer"
            >
              {"quote now"}
            </a>
            {` to protect yourself from the unexpected losses related to cyber attacks.`}
          </ConfirmText>
        </ConfirmContent>
      </ConfirmationModal>
    </>
  )
}

export default DownloadDetailedReport
