import {
  Collapse,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core"
import { ButtonSecondary } from "@src/Components/Button"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { colors } from "@src/theme"
import * as T from "@src/types"
import * as moment from "moment"
import * as React from "react"

import { Box, DateHolder, PageBreak, SubHeading } from "../styles"
import {
  BoxBody,
  CollapseArrow,
  DownloadHeadWrapper,
  DownloadRow,
  Issue,
  IssueContainer,
  IssueCount,
  IssueSeverity,
  IssueSeverityContainer,
  IssuesText,
  Oval,
  PopoverContent,
  PopoverDownloadContainer,
  ScanInfoContainer,
  ScanInfoContent,
  ScanInfoElement,
  ScanInfoElementText,
} from "./styles"

interface IScanReportCard {
  scan: T.IProbelyScan
  clientWidth: number
  targetName: string
  getScanReport: (
    targetName: string,
    targetId: string,
    scanId: string,
    reportType: string,
  ) => void
}

export const ScanReportCard = ({
  scan,
  getScanReport,
  clientWidth,
  targetName,
}: IScanReportCard) => {
  const [showMore, setShowMore] = React.useState(false)
  return React.useMemo(() => {
    const startTime = moment(scan.startTime).format("h:mm A")
    const completeTime = moment(scan.completeTime).format("h:mm A")
    const duration = moment.duration(
      moment(scan.completeTime).diff(moment(scan.startTime)),
    )
    const ranTime = Math.round(duration.asMinutes())
    if (scan.status === "canceled" || scan.status === "failed") {
      return (
        <Section flex="center normal column" key={scan.scanId}>
          <Box
            hasBorder
            borderColor={
              scan.status === "canceled" ? colors.pink : colors.error
            }
          >
            <BoxBody>
              <SubHeading>
                <DateHolder>
                  {moment(scan.completeTime).format("MMM D, YYYY")}
                </DateHolder>
              </SubHeading>
              <ScanInfoContent>
                <ScanInfoElement>
                  <FaIcon
                    fontSize="24px"
                    className="fas fa-bolt"
                    color={colors.unitedNationsBlue}
                  />
                  <ScanInfoElementText>
                    {scan.scanProfile} Scan
                  </ScanInfoElementText>
                </ScanInfoElement>
                <ScanInfoElement>
                  <FaIcon
                    fontSize="24px"
                    className="fas fa-arrow-from-left"
                    color={colors.unitedNationsBlue}
                  />
                  <ScanInfoElementText>
                    Started <strong>{startTime}</strong>
                  </ScanInfoElementText>
                </ScanInfoElement>
                <ScanInfoElement>
                  <FaIcon
                    fontSize="24px"
                    className="fas fa-stopwatch"
                    color={colors.unitedNationsBlue}
                  />
                  <ScanInfoElementText>
                    Ran{" "}
                    <strong>
                      {ranTime < 2 ? `${ranTime} minute` : `${ranTime} minutes`}
                    </strong>
                  </ScanInfoElementText>
                </ScanInfoElement>
                <ScanInfoElement>
                  {scan.status === "canceled" ? (
                    <>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-hand-paper"
                        color={colors.pink}
                      />
                      <ScanInfoElementText>
                        Manually canceled <strong>{completeTime}</strong>
                      </ScanInfoElementText>
                    </>
                  ) : (
                    <>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-frown"
                        color={colors.error}
                      />
                      <ScanInfoElementText color={colors.error}>
                        <FaIcon
                          className="fas fa-exclamation-triangle"
                          fontSize="16px"
                          color={colors.error}
                        />{" "}
                        Failed <strong>{completeTime}</strong>
                      </ScanInfoElementText>
                    </>
                  )}
                </ScanInfoElement>
              </ScanInfoContent>
            </BoxBody>
          </Box>
          <PageBreak />
        </Section>
      )
    } else {
      return (
        <Section flex="center normal column" key={scan.scanId}>
          <Box hasBorder>
            <BoxBody>
              <DownloadHeadWrapper>
                <SubHeading>
                  <DateHolder>
                    {moment(scan.completeTime).format("MMM D, YYYY")}
                  </DateHolder>
                </SubHeading>
                {clientWidth > 624 && (
                  <DownloadRow>
                    <Popover
                      content={
                        <PopoverDownloadContainer>
                          <Link
                            onClick={() => {
                              getScanReport(
                                targetName,
                                scan.targetId,
                                scan.scanId,
                                "owasp",
                              )
                            }}
                          >
                            Standard Version (OWASP)
                          </Link>
                          <Link
                            onClick={() => {
                              getScanReport(
                                targetName,
                                scan.targetId,
                                scan.scanId,
                                "pci",
                              )
                            }}
                          >
                            PCI Version
                          </Link>
                        </PopoverDownloadContainer>
                      }
                      interactionKind={PopoverInteractionKind.CLICK}
                      minimal
                      position={Position.BOTTOM_LEFT}
                    >
                      <ButtonSecondary
                        width={187}
                        id="scan-tables-download-report-btn"
                      >
                        <FaIcon className="fas fa-file-pdf" fontSize="16px" />
                        Download Report
                      </ButtonSecondary>
                    </Popover>
                    &nbsp;&nbsp;
                    <Popover
                      popoverClassName="bp5-dark"
                      content={
                        <PopoverContent>
                          <span
                            style={{
                              fontSize: "18px",
                              lineHeight: "24px",
                              fontWeight: "bold",
                            }}
                          >
                            Which report is best for me?
                          </span>
                          <br /> <br />
                          Use <b>Standard</b> for compliance requirements other
                          than PCI (e.g. SOC2 and HIPAA).
                          <br /> <br />
                          Use <b>PCI</b> if your business transmits, processes,
                          or stores credit card information.
                          <br /> <br />
                          <Link
                            href="https://help.zeguro.com/hc/en-us/articles/360038743493"
                            id="learn-more-scan-types"
                            margin="0px"
                            isUnderline
                            color={colors.white}
                            withIcon
                          >
                            Learn More
                          </Link>
                        </PopoverContent>
                      }
                      interactionKind={PopoverInteractionKind.HOVER}
                      position={Position.TOP}
                    >
                      <FaIcon
                        className="fa fa-question-circle"
                        fontSize="16px"
                        color={colors.gray40}
                        cursor="pointer"
                      />
                    </Popover>
                  </DownloadRow>
                )}
              </DownloadHeadWrapper>
              <IssueContainer>
                <IssuesText>Issues</IssuesText>
                <IssueSeverityContainer>
                  <Issue opacity={scan.highs > 0 ? 1 : 0.5}>
                    <IssueCount>{scan.highs}</IssueCount>
                    <IssueSeverity>
                      <Oval color={colors.red} borderColor={colors.error} />
                      High Severity
                    </IssueSeverity>
                  </Issue>
                  <Issue opacity={scan.mediums > 0 ? 1 : 0.5}>
                    <IssueCount>{scan.mediums}</IssueCount>
                    <IssueSeverity>
                      <Oval
                        color={colors.orange90}
                        borderColor={colors.crimson}
                      />
                      Medium Severity
                    </IssueSeverity>
                  </Issue>
                  <Issue opacity={scan.lows > 0 ? 1 : 0.5}>
                    <IssueCount>{scan.lows} </IssueCount>
                    <IssueSeverity>
                      <Oval
                        color={colors.warning}
                        borderColor={colors.orangeLight}
                      />{" "}
                      Low Severity
                    </IssueSeverity>
                  </Issue>
                </IssueSeverityContainer>
              </IssueContainer>
              {clientWidth < 625 && (
                <DownloadRow justifyContent="center">
                  <Popover
                    content={
                      <PopoverDownloadContainer>
                        <Link
                          onClick={() => {
                            getScanReport(
                              targetName,
                              scan.targetId,
                              scan.scanId,
                              "owasp",
                            )
                          }}
                        >
                          Standard Version (OWASP)
                        </Link>
                        <Link
                          onClick={() => {
                            getScanReport(
                              targetName,
                              scan.targetId,
                              scan.scanId,
                              "pci",
                            )
                          }}
                        >
                          PCI Version
                        </Link>
                      </PopoverDownloadContainer>
                    }
                    interactionKind={PopoverInteractionKind.CLICK}
                    minimal
                    position={Position.BOTTOM_LEFT}
                  >
                    <ButtonSecondary
                      width={187}
                      id="scan-tables-download-report-btn"
                    >
                      Download Report
                    </ButtonSecondary>
                  </Popover>
                </DownloadRow>
              )}
              <ScanInfoContainer>
                <Link
                  onClick={() => setShowMore(!showMore)}
                  color={colors.darkBlue}
                >
                  {showMore ? "Hide details" : "View details"}{" "}
                  <CollapseArrow
                    className="fas fa-caret-down"
                    showMore={showMore}
                  />
                </Link>
                <Collapse isOpen={showMore}>
                  <ScanInfoContent>
                    <ScanInfoElement>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-bolt"
                        color={colors.unitedNationsBlue}
                      />
                      <ScanInfoElementText>
                        {scan.scanProfile} Scan
                      </ScanInfoElementText>
                    </ScanInfoElement>
                    <ScanInfoElement>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-arrow-from-left"
                        color={colors.unitedNationsBlue}
                      />
                      <ScanInfoElementText>
                        Started <strong>{startTime}</strong>
                      </ScanInfoElementText>
                    </ScanInfoElement>
                    <ScanInfoElement>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-stopwatch"
                        color={colors.unitedNationsBlue}
                      />
                      <ScanInfoElementText>
                        Ran{" "}
                        <strong>
                          {ranTime < 2
                            ? `${ranTime} minute`
                            : `${ranTime} minutes`}
                        </strong>
                      </ScanInfoElementText>
                    </ScanInfoElement>
                    <ScanInfoElement>
                      <FaIcon
                        fontSize="24px"
                        className="fas fa-check"
                        color={colors.success}
                      />
                      <ScanInfoElementText>
                        Finished <strong>{completeTime}</strong>
                      </ScanInfoElementText>
                    </ScanInfoElement>
                  </ScanInfoContent>
                </Collapse>
              </ScanInfoContainer>
            </BoxBody>
          </Box>
          <PageBreak />
        </Section>
      )
    }
  }, [scan.scanId, scan.status, showMore])
}
