import ExternalLink from "@src/Components/ExternalLink"
import { FaIcon } from "@src/Components/FaIcon"
import * as React from "react"

import { LearnMoreLink } from "./styles"
const LearnMoreSection = () => {
  return (
    <LearnMoreLink>
      <FaIcon className="fas fa-book" />
      <ExternalLink
        text="Learn more about Cyber Concierge"
        url="https://help.zeguro.com/hc/en-us/articles/19379702177175-Cyber-Concierge-Overview"
      />
    </LearnMoreLink>
  )
}

export default LearnMoreSection
