import { Dialog } from "@blueprintjs/core"
import * as React from "react"

import { ButtonCancel, ButtonSmall } from "./Button"
import { ErrorContainer } from "./Common"

interface IZegDialog {
  isOpen: boolean
  onClose: () => void
  onCancel?: () => void
  onAction?: () => void
  closeText?: string
  actionText?: string
  text?: string | JSX.Element
  title?: string
  body?: JSX.Element
  content?: JSX.Element
  error?: string | JSX.Element
  className?: string
  children?: JSX.Element
  showOnlyPrimaryButton?: boolean
  closeButtonId?: string
  actionButtonId?: string
  [x: string]: any
}

const ZegDialog = (props: IZegDialog) => {
  const {
    isOpen,
    onClose,
    onCancel,
    onAction,
    closeText,
    actionText,
    text,
    title,
    body,
    content,
    error,
    className,
    children,
    showOnlyPrimaryButton,
    closeButtonId,
    actionButtonId,
    ...rest
  } = props

  const standardBody = (
    <div>
      <div className="bp5-dialog-body">
        <div>{text}</div>
        {error ? <ErrorContainer> {error} </ErrorContainer> : null}
      </div>
    </div>
  )

  const footer = (
    <div className="bp5-dialog-footer" style={{ textAlign: "right" }}>
      {showOnlyPrimaryButton ? null : (
        <ButtonCancel
          onClick={onCancel || onClose}
          width={100}
          id={closeButtonId || ""}
        >
          {closeText || "Cancel"}
        </ButtonCancel>
      )}
      &nbsp;
      <ButtonSmall
        hidden={!onAction}
        onClick={onAction}
        width={100}
        id={actionButtonId || ""}
      >
        {actionText || "Confirm"}
      </ButtonSmall>
    </div>
  )

  const customBody = body ? <div className="bp5-dialog-body">{body}</div> : null

  return (
    <Dialog
      icon={title ? false : undefined}
      className={className}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      {content || children || customBody || standardBody}
      {content || children ? "" : footer}
    </Dialog>
  )
}

export default ZegDialog
