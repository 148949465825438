import { actions as GLOBAL_MODALS, GLOBAL_MODALS_KEYS } from "@src/Actions/Reducers/GlobalModalsReducer"
import { ButtonSecondary, ButtonSmall } from "@src/Components/Button"
import { FaIcon } from "@src/Components/FaIcon"
import { Link } from "@src/Components/Link"
import { Section } from "@src/Components/StyledUtils"
import { VIEW_PATHS } from "@src/Routes/constants"
import { colors } from "@src/theme"
import * as React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  ActionsBlock,
  ActionsBlockHeader,
  ActionsSection,
  ConciergeIcon,
  Divider,
  ModalHeader,
  StyledDialog,
  StyledLi,
  SubTitle,
  Title,
} from "./styles"

interface IContactCyberConciergeModal {
  isOpen: boolean
}

const ContactCyberConciergeModal = ({
  isOpen,
}: IContactCyberConciergeModal) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const conciergeEmail = "concierge@zeguro.com"
  const conciergeScheduleCallLink = "https://concierge-scheduling.zeguro.com"

  const onClose = () => {
    dispatch(
      GLOBAL_MODALS.toggleGlobalModal(
        GLOBAL_MODALS_KEYS.isContactCyberConcierge,
      ),
    )
  }

  return (
    <StyledDialog
      className="zeg-intro"
      isOpen={isOpen}
      onClose={onClose}
      content={
        <>
          <ModalHeader>
            Contact Cyber Concierge
            <FaIcon
              className="far fa-times"
              cursor="pointer"
              onClick={onClose}
            />
          </ModalHeader>
          <Section flex="center center column" padding="24 32 0">
            <Section flex="flex-start flex-start" w="100%">
              <ConciergeIcon src="/images/concierge_color.svg" />
              <Section fontColor={colors.darkBlue} fontFamily="montserrat">
                <Title>
                  Talk with an expert about your cybersecurity needs.
                </Title>
                <SubTitle>Get live help from a trusted advisor.</SubTitle>
              </Section>
            </Section>
            <Section fontSize="16px" w="100%">
              <ul>
                <StyledLi>
                  Get help resolving <strong>security vulnerabilities</strong>{" "}
                  affecting your organization.
                </StyledLi>
                <StyledLi>
                  Discuss your organization&apos;s{" "}
                  <strong>cybersecurity risks</strong> and{" "}
                  <strong>customized improvement plan</strong>.
                </StyledLi>
                <StyledLi>
                  Ask about <strong>any other cybersecurity topics</strong> and
                  how you can better protect your organization.
                </StyledLi>
              </ul>
            </Section>
            <ActionsBlockHeader>
              Included with your subscription
            </ActionsBlockHeader>
            <ActionsBlock>
              <ActionsSection>
                <FaIcon
                  className="fas fa-user-headset"
                  fontSize="64px"
                  color={colors.darkBlue}
                  margin="18px"
                />
                <ButtonSmall
                  width="185"
                  margin="0 0 34"
                  onClick={() => {
                    window.open(conciergeScheduleCallLink, "_blank")
                    onClose()
                  }}
                >
                  <FaIcon className="fas fa-phone" margin="0 8px 0" />
                  Schedule a Call
                </ButtonSmall>
              </ActionsSection>
              <Divider />
              <ActionsSection>
                <FaIcon
                  className="fas fa-envelope"
                  fontSize="64px"
                  color={colors.darkBlue}
                  margin="18px"
                />
                <ButtonSecondary
                  width="185"
                  margin="0 0 14"
                  onClick={() => {
                    window.open(`mailto:${conciergeEmail}`)
                    onClose()
                  }}
                >
                  <FaIcon className="fas fa-envelope" margin="0 8px 0" />
                  Email Concierge
                </ButtonSecondary>
                <Link
                  isUnderline
                  fontSize="16px"
                  onClick={() => {
                    window.open(`mailto:${conciergeEmail}`)
                    onClose()
                  }}
                >
                  {conciergeEmail}
                </Link>
              </ActionsSection>
            </ActionsBlock>
            <Link
              fontSize="16px"
              onClick={() => {
                history.push(`${VIEW_PATHS.cyberconcierge}/about`)
                onClose()
              }}
            >
              <FaIcon className="fas fa-bell" />
              &nbsp;About Cyber Concierge
            </Link>
          </Section>
        </>
      }
    />
  )
}

export default ContactCyberConciergeModal
